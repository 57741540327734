import axios from "axios";
import { React, useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import loading1 from "../img/loading1.gif";
import loading2 from "../img/loading2.gif";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClose,
  faL,
  faPrint,
  faSearchMinus,
  faSearchPlus,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import logo from "../img/logo.png";

function LeaveRequest() {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const tab = useSelector((state) => state.auth.tab);
  const empId = useSelector((state) => state.auth.empId);
  const fullName = useSelector((state) => state.auth.fullName);
  const department = useSelector((state) => state.auth.dep);
  const position = useSelector((state) => state.auth.position);
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);
  const [leaveType, setLeaveType] = useState("");
  const [leaveYear, setLeaveYear] = useState("");
  const [remainingDay, setRemainingDay] = useState("");
  const [leaveAmount, setLeaveAmount] = useState("");
  const [outDate, setOutDate] = useState("");
  const [inDate, setInDate] = useState("");
  const navigate = useNavigate();

  const printRef = useRef();
  const [openPrint, setOpenPrint] = useState(false);

  useEffect(() => {
    document.getElementById("windowTitle").textContent = "/የሰው ሃይል /ፈቃድ ለመጠየቅ";

    empId != "" &&
      axios
        .post("https://www.bdc.et/bdc-api/get_employee_leave.php", {
          empId: empId,
        })
        .then((res) => {
          setData(res.data);
        });

    empId != "" &&
      axios
        .post("https://www.bdc.et/bdc-api/get_leave_request.php", {
          empId: empId,
        })
        .then((res) => {
          setData2(res.data);
        });
  }, []);

  const RegiBtn = () => {
    let errMsg = 0;
    document.getElementById("successMsg").style.display = "none";

    if (leaveType == "") {
      document.getElementById("leaveTypeErr").style.display = "block";
      document.getElementById("leaveType").style.borderColor = "red";
      errMsg = 1;
    } else {
      document.getElementById("leaveTypeErr").style.display = "none";
      document.getElementById("leaveType").style.borderColor = "lightgray";
    }
    if (leaveAmount == "") {
      document.getElementById("leaveAmountErr").style.display = "block";
      document.getElementById("leaveAmount").style.borderColor = "red";
      errMsg = 1;
    } else {
      document.getElementById("leaveAmountErr").style.display = "none";
      document.getElementById("leaveAmount").style.borderColor = "lightgray";
    }

    if (parseFloat(leaveAmount) > parseFloat(remainingDay)) {
      document.getElementById("leaveAmountErr2").style.display = "block";
      document.getElementById("leaveAmount").style.borderColor = "red";
      errMsg = 1;
    } else {
      document.getElementById("leaveAmountErr2").style.display = "none";
      document.getElementById("leaveAmount").style.borderColor = "lightgray";
    }
    if (outDate == "") {
      document.getElementById("outDateErr").style.display = "block";
      document.getElementById("outDate").style.borderColor = "red";
      errMsg = 1;
    } else {
      document.getElementById("outDateErr").style.display = "none";
      document.getElementById("outDate").style.borderColor = "lightgray";
    }
    if (inDate == "") {
      document.getElementById("inDateErr").style.display = "block";
      document.getElementById("inDate").style.borderColor = "red";
      errMsg = 1;
    } else {
      document.getElementById("inDateErr").style.display = "none";
      document.getElementById("inDate").style.borderColor = "lightgray";
    }

    if (errMsg == 0) {
      axios
        .post("https://www.bdc.et/bdc-api/post_leave_request.php", {
          empId: empId,
          fullName: fullName,
          leaveType: leaveType,
          leaveYear: leaveYear,
          leaveAmount: leaveAmount,
          remainingDay: remainingDay,
          outDate: outDate,
          inDate: inDate,
        })
        .then((res) => {
          setData2(res.data);
          setLeaveType("");
          setLeaveYear("");
          setLeaveAmount("");
          setOutDate("");
          setInDate("");
          document.getElementById("leaveType").value = "";
          document.getElementById("leaveYear").value = "";
          document.getElementById("leaveAmount").value = "";
          document.getElementById("outDate").value = "";
          document.getElementById("inDate").value = "";
          document.getElementById("successMsg").style.display = "block";
          document.getElementById("successMsg").focus();

          axios
            .post("https://www.bdc.et/bdc-api/get_employee_leave.php", {
              empId: empId,
            })
            .then((res) => {
              setData(res.data);
            });
        });
    }
  };

  const HandlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: "Employee leave request",
    onPrintError: (err) => alert(err),
  });

  const HandleOpenPrint = (id) => {
    setIsLoading2(true);
    setOpenPrint(true);

    axios
      .post("https://www.bdc.et/bdc-api/get_leave_request_id.php", {
        id: id,
      })
      .then((res) => {
        setData3(res.data[0]);
      })
      .catch((err) => alert(err))
      .finally(() => setIsLoading2(false));
  };

  return (
    <>
      {openPrint ? (
        <div
          className="cont-all"
          style={{
            width: "100%",
            minWidth: "600px",
            height: "97%",
            overflow: "auto",
            backgroundColor: "rgb(200,200,200)",
            textAlign: "center",
            scrollbarWidth: "thin",
          }}
        >
          <FontAwesomeIcon
            style={{
              position: "sticky",
              top: 0,
              float: "left",
              margin: "auto",
              backgroundColor: "white",
              fontSize: "40px",
              padding: "10px",
              borderRadius: "5px",
              color: "green",
              cursor: "pointer",
            }}
            icon={faPrint}
            onClick={HandlePrint}
            className="print-btn"
          />

          <FontAwesomeIcon
            style={{
              position: "sticky",
              top: 0,
              float: "right",
              margin: "auto",
              backgroundColor: "white",
              fontSize: "40px",
              padding: "10px",
              borderRadius: "5px",
              color: "green",
              cursor: "pointer",
            }}
            icon={faClose}
            className="print-btn"
            onClick={() => setOpenPrint(false)}
          />
          <div
            style={{
              margin: "20px auto",
              width: "850px",
              height: "1100px",
              backgroundColor: "white",
              boxShadow: "0px 5px 15px gray",
              overflow: "auto",
              scrollbarWidth: "thin",
            }}
          >
            <div ref={printRef} style={{ width: "95%", marginLeft: "20px" }}>
              <div style={{ width: "100%", height: "520px", margin: 0 }}>
                <div
                  className="brdr-btm"
                  style={{
                    width: "100%",
                    height: "80px",
                    display: "inline-flex",
                    backgroundColor: "white",
                  }}
                >
                  <div
                    style={{
                      width: "20%",
                      marginLeft: "20px",
                      textAlign: "center",
                    }}
                  >
                    <img src={logo} style={{ width: "80px" }} alt="logo" />
                    <p
                      style={{
                        fontSize: "10px",
                        color: "green",
                        marginTop: "-30px",
                      }}
                    >
                      ቢዲሲ ኮንስትራክሽን
                    </p>
                  </div>
                  <div>
                    <h3
                      style={{
                        width: "80%",
                        textAlign: "left",
                        paddingLeft: "132px",
                        marginTop: "30px",
                      }}
                    >
                      የሰራተኛ ፈቃድ መጠየቂያ ቅፅ
                    </h3>
                    <h4
                      style={{
                        width: "80%",
                        textAlign: "left",
                        paddingLeft: "140px",
                        marginTop: "-20px",
                        fontWeight: "normal",
                      }}
                    >
                      Employee Leave Request Form
                    </h4>
                  </div>
                </div>

                <div
                  className="brdr-btm"
                  style={{
                    textAlign: "left",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "inline-flex",
                      padding: "0px 0px 5px 20px",
                    }}
                  >
                    <h5
                      style={{
                        width: "100px",
                        margin: 0,
                        padding: 0,
                        color: "gray",
                      }}
                    >
                      ቀን
                    </h5>
                    <p
                      className="brdr-btm"
                      style={{ fontSize: "14px", margin: 0, padding: 0 }}
                    >
                      {data3 && moment(data3[8]).format("DD-MM-YYYY")}
                    </p>
                  </div>
                  <br />
                  <div
                    style={{
                      display: "inline-flex",
                      padding: "0px 0px 5px 20px",
                    }}
                  >
                    <h5
                      style={{
                        width: "100px",
                        margin: 0,
                        padding: 0,
                        color: "gray",
                      }}
                    >
                      የሰራተኛው ስም
                    </h5>
                    <p
                      className="brdr-btm"
                      style={{ fontSize: "14px", margin: 0, padding: 0 }}
                    >
                      {fullName}
                    </p>
                  </div>
                  <br />
                  <div style={{ display: "inline-flex" }}>
                    <div
                      style={{
                        display: "inline-flex",
                        padding: "0px 0px 5px 20px",
                      }}
                    >
                      <h5
                        style={{
                          width: "100px",
                          margin: 0,
                          padding: 0,
                          color: "gray",
                        }}
                      >
                        መምሪያ
                      </h5>
                      <p
                        className="brdr-btm"
                        style={{ fontSize: "14px", margin: 0, padding: 0 }}
                      >
                        {department}
                      </p>
                    </div>

                    <div
                      style={{
                        display: "inline-flex",
                        padding: "0px 0px 5px 20px",
                      }}
                    >
                      <h5
                        style={{
                          width: "100px",
                          margin: "0 -15px 0 10px",
                          padding: 0,
                          color: "gray",
                        }}
                      >
                        የስራ መደብ
                      </h5>
                      <p
                        className="brdr-btm"
                        style={{ fontSize: "14px", margin: 0, padding: 0 }}
                      >
                        {position}
                      </p>
                    </div>
                  </div>
                  <br />
                  <div
                    style={{
                      display: "inline-flex",
                      padding: "0px 0px 5px 20px",
                    }}
                  >
                    <h5
                      style={{
                        width: "100px",
                        margin: 0,
                        padding: 0,
                        color: "gray",
                      }}
                    >
                      የፈቃዱ አይነት
                    </h5>
                    <p
                      className="brdr-btm"
                      style={{ fontSize: "14px", margin: 0, padding: 0 }}
                    >
                      {data3 && data3[3]}
                    </p>
                  </div>
                  <br />
                  <div
                    style={{
                      display: "inline-flex",
                      margin: "0px 20px",
                      fontSize: "14px",
                    }}
                  >
                    <p>ከ </p>
                    <p
                      style={{
                        marginLeft: "10px",
                        marginRight: "10px",
                        textDecoration: "underline",
                      }}
                    >
                      {" "}
                      {data3 && data3[4]}{" "}
                    </p>
                    <p> እ.ኤ.አ በጀት ዓመት ፈቃዴ ከ </p>
                    <p
                      style={{
                        marginLeft: "10px",
                        marginRight: "10px",
                        textDecoration: "underline",
                      }}
                    >
                      {data3 && moment(data3[6]).format("DD-MM-YYYY")}
                    </p>
                    <p>እስከ </p>
                    <p
                      style={{
                        marginLeft: "10px",
                        marginRight: "10px",
                        textDecoration: "underline",
                      }}
                    >
                      {data3 && moment(data3[7]).format("DD-MM-YYYY")}
                    </p>
                    <p>የ</p>
                    <p
                      style={{
                        marginLeft: "10px",
                        marginRight: "10px",
                        textDecoration: "underline",
                      }}
                    >
                      {data3 && data3[5]}
                    </p>
                    <p>ቀን ፈቃድ እንዲሰጠኝ እጠይቃለሁ፡፡</p>
                  </div>
                  <br />
                  <p style={{ fontSize: "14px", margin: "0px 20px" }}>
                    የጠያቂው ፊርማ _____________________
                  </p>
                  <br />
                  <div
                    style={{
                      display: "inline-flex",
                      margin: "0px 20px",
                      fontSize: "14px",
                    }}
                  >
                    <p>
                      የቅርብ ኃላፊ ውሳኔ
                      _______________________________________________________________________________
                      ______________________________________________________________________________________________
                    </p>
                    <p></p>
                  </div>
                  <br />
                  <p style={{ fontSize: "14px", margin: "0px 20px" }}>
                    የቅርብ ኃላፊ ስም _____________________ ፊርማ _____________________
                    ቀን _____________________
                  </p>
                  <br />
                  <p
                    style={{
                      margin: "0px 20px",
                      fontSize: "12px",
                      fontWeight: "bold",
                      textDecoration: "underline",
                    }}
                  >
                    የሰው/ሃ/አስ ማረጋገጫ
                  </p>
                  <br />
                  <p style={{ fontSize: "14px", margin: "0px 20px" }}>
                    በላይ በቀረበው ጥያቄና የቅርብ ኃላፊው ውሳኔ መሰረት ከ _____________ እ.ኤ.አ በጀት
                    ዓመት ከነበራቸው ጠቅላላ የዓመት ፈቃድ ውስጥ የ _______ ቀን ፈቃድ ተሰጥቷቸዋል፡፡ ያላቸው
                    ቀሪ የፈቃድ ቀናት _____________ ሲሆን ወደ ስራቸው የሚመለሱበት
                    ቀን_____________________ መሆኑን እናረጋግጣለን፡፡
                  </p>

                  <br />
                  <p style={{ fontSize: "14px", margin: "0px 20px" }}>
                    የሰ/ሃ/አስ ስም _____________________ ፊርማ _____________________
                    ቀን _____________________
                  </p>
                  <br />
                  <p style={{ fontSize: "14px", margin: "0px 20px" }}>
                    ማሳሰቢያ፡ ቅጹ በ2 ኮፒ ተሞልቶ አንደኛው ኮፒ ለሰራተኛው ሁለተኛው ኮፒ ከሰራተኛው ፋይል ጋር
                    ይያያዛል፡፡
                  </p>
                </div>
                <br />
              </div>
              <div style={{ width: "100%", height: "520px", margin: 0 }}>
                <div
                  className="brdr-btm"
                  style={{
                    width: "100%",
                    height: "80px",
                    display: "inline-flex",
                    backgroundColor: "white",
                  }}
                >
                  <div
                    style={{
                      width: "20%",
                      marginLeft: "20px",
                      textAlign: "center",
                      marginTop: 0,
                    }}
                  >
                    <img
                      src={logo}
                      style={{ width: "80px", margin: "0" }}
                      alt="logo"
                    />
                    <p
                      style={{
                        fontSize: "10px",
                        color: "green",
                        marginTop: "-30px",
                      }}
                    >
                      ቢዲሲ ኮንስትራክሽን
                    </p>
                  </div>
                  <div>
                    <h3
                      style={{
                        width: "80%",
                        textAlign: "left",
                        paddingLeft: "132px",
                        marginTop: "30px",
                      }}
                    >
                      የሰራተኛ ፈቃድ መጠየቂያ ቅፅ
                    </h3>
                    <h4
                      style={{
                        width: "80%",
                        textAlign: "left",
                        paddingLeft: "140px",
                        marginTop: "-20px",
                        fontWeight: "normal",
                      }}
                    >
                      Employee Leave Request Form
                    </h4>
                  </div>
                </div>

                <div
                  className="brdr-btm"
                  style={{
                    textAlign: "left",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "inline-flex",
                      padding: "0px 0px 5px 20px",
                    }}
                  >
                    <h5
                      style={{
                        width: "100px",
                        margin: 0,
                        padding: 0,
                        color: "gray",
                      }}
                    >
                      ቀን
                    </h5>
                    <p
                      className="brdr-btm"
                      style={{ fontSize: "14px", margin: 0, padding: 0 }}
                    >
                      {data3 && moment(data3[8]).format("DD-MM-YYYY")}
                    </p>
                  </div>
                  <br />
                  <div
                    style={{
                      display: "inline-flex",
                      padding: "0px 0px 5px 20px",
                    }}
                  >
                    <h5
                      style={{
                        width: "100px",
                        margin: 0,
                        padding: 0,
                        color: "gray",
                      }}
                    >
                      የሰራተኛው ስም
                    </h5>
                    <p
                      className="brdr-btm"
                      style={{ fontSize: "14px", margin: 0, padding: 0 }}
                    >
                      {fullName}
                    </p>
                  </div>
                  <br />
                  <div style={{ display: "inline-flex" }}>
                    <div
                      style={{
                        display: "inline-flex",
                        padding: "0px 0px 5px 20px",
                      }}
                    >
                      <h5
                        style={{
                          width: "100px",
                          margin: 0,
                          padding: 0,
                          color: "gray",
                        }}
                      >
                        መምሪያ
                      </h5>
                      <p
                        className="brdr-btm"
                        style={{ fontSize: "14px", margin: 0, padding: 0 }}
                      >
                        {department}
                      </p>
                    </div>

                    <div
                      style={{
                        display: "inline-flex",
                        padding: "0px 0px 5px 20px",
                      }}
                    >
                      <h5
                        style={{
                          width: "100px",
                          margin: "0 -15px 0 10px",
                          padding: 0,
                          color: "gray",
                        }}
                      >
                        የስራ መደብ
                      </h5>
                      <p
                        className="brdr-btm"
                        style={{ fontSize: "14px", margin: 0, padding: 0 }}
                      >
                        {position}
                      </p>
                    </div>
                  </div>
                  <br />
                  <div
                    style={{
                      display: "inline-flex",
                      padding: "0px 0px 5px 20px",
                    }}
                  >
                    <h5
                      style={{
                        width: "100px",
                        margin: 0,
                        padding: 0,
                        color: "gray",
                      }}
                    >
                      የፈቃዱ አይነት
                    </h5>
                    <p
                      className="brdr-btm"
                      style={{ fontSize: "14px", margin: 0, padding: 0 }}
                    >
                      {data3 && data3[3]}
                    </p>
                  </div>
                  <br />
                  <div
                    style={{
                      display: "inline-flex",
                      margin: "0px 20px",
                      fontSize: "14px",
                    }}
                  >
                    <p>ከ </p>
                    <p
                      style={{
                        marginLeft: "10px",
                        marginRight: "10px",
                        textDecoration: "underline",
                      }}
                    >
                      {" "}
                      {data3 && data3[4]}{" "}
                    </p>
                    <p> እ.ኤ.አ በጀት ዓመት ፈቃዴ ከ </p>
                    <p
                      style={{
                        marginLeft: "10px",
                        marginRight: "10px",
                        textDecoration: "underline",
                      }}
                    >
                      {data3 && moment(data3[6]).format("DD-MM-YYYY")}
                    </p>
                    <p>እስከ </p>
                    <p
                      style={{
                        marginLeft: "10px",
                        marginRight: "10px",
                        textDecoration: "underline",
                      }}
                    >
                      {data3 && moment(data3[7]).format("DD-MM-YYYY")}
                    </p>
                    <p>የ</p>
                    <p
                      style={{
                        marginLeft: "10px",
                        marginRight: "10px",
                        textDecoration: "underline",
                      }}
                    >
                      {data3 && data3[5]}
                    </p>
                    <p>ቀን ፈቃድ እንዲሰጠኝ እጠይቃለሁ፡፡</p>
                  </div>
                  <br />
                  <p style={{ fontSize: "14px", margin: "0px 20px" }}>
                    የጠያቂው ፊርማ _____________________
                  </p>
                  <br />
                  <div
                    style={{
                      display: "inline-flex",
                      margin: "0px 20px",
                      fontSize: "14px",
                    }}
                  >
                    <p>
                      የቅርብ ኃላፊ ውሳኔ
                      _______________________________________________________________________________
                      ______________________________________________________________________________________________
                    </p>
                    <p></p>
                  </div>
                  <br />
                  <p style={{ fontSize: "14px", margin: "0px 20px" }}>
                    የቅርብ ኃላፊ ስም _____________________ ፊርማ _____________________
                    ቀን _____________________
                  </p>
                  <br />
                  <p
                    style={{
                      margin: "0px 20px",
                      fontSize: "12px",
                      fontWeight: "bold",
                      textDecoration: "underline",
                    }}
                  >
                    የሰው/ሃ/አስ ማረጋገጫ
                  </p>
                  <br />
                  <p style={{ fontSize: "14px", margin: "0px 20px" }}>
                    በላይ በቀረበው ጥያቄና የቅርብ ኃላፊው ውሳኔ መሰረት ከ _____________ እ.ኤ.አ በጀት
                    ዓመት ከነበራቸው ጠቅላላ የዓመት ፈቃድ ውስጥ የ _______ ቀን ፈቃድ ተሰጥቷቸዋል፡፡ ያላቸው
                    ቀሪ የፈቃድ ቀናት _____________ ሲሆን ወደ ስራቸው የሚመለሱበት
                    ቀን_____________________ መሆኑን እናረጋግጣለን፡፡
                  </p>

                  <br />
                  <p style={{ fontSize: "14px", margin: "0px 20px" }}>
                    የሰ/ሃ/አስ ስም _____________________ ፊርማ _____________________
                    ቀን _____________________
                  </p>
                  <br />
                  <p style={{ fontSize: "14px", margin: "0px 20px" }}>
                    ማሳሰቢያ፡ ቅጹ በ2 ኮፒ ተሞልቶ አንደኛው ኮፒ ለሰራተኛው ሁለተኛው ኮፒ ከሰራተኛው ፋይል ጋር
                    ይያያዛል፡፡
                  </p>
                </div>
                <br />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="cont-all"
          style={{
            width: "80%",
            height: "95%",
            padding: "0",
            overflow: "auto",
            scrollbarWidth: "thin",
          }}
        >
          <div
            style={{
              width: "100%",
              backgroundColor: "rgb(240,240,240)",
              margin: "0",
              display: "inline-flex",
              position: "sticky",
              top: 0,
            }}
          >
            <h5
              style={{
                margin: "0",
                color: "black",
                borderColor: "gray",
                borderRight: "solid",
                borderWidth: "1px",
                padding: "10px 20px",
              }}
            >
              የሰራተኛ ፈቃድ መጠየቂያ ገፅ
            </h5>
          </div>

          <div style={{ display: "inline-flex", flexWrap: "wrap", width:"100%" }}>
            <div
              style={{
                width: "40%",
                minWidth: "300px",
                margin: "0 20px",
              }}
            >
              <p className="reg-err" id="leaveTypeErr">
                *ይህ መረጃ አስፈላጊ ነው!
              </p>
              <select
                name="leaveType"
                id="leaveType"
                onChange={(e) => setLeaveType(e.target.value)}
                className="txt-input"
                style={{ width: "98%" }}
              >
                <option style={{ color: "gray" }} value="">
                  የፈቃድ አይነት
                </option>
                <option value="Vacation/ Annual">Vacation/ Annual </option>
                <option value="Sick">Sick</option>
                <option value="Maternity">Maternity</option>
                <option value="Paternity">Paternity</option>
                <option value="Bereavement">Bereavement</option>
                <option value="Unpaid">Unpaid</option>
              </select>
              <p className="reg-err" id="leaveYearErr">
                *ይህ መረጃ አስፈላጊ ነው!
              </p>
              <select
                name="leaveYear"
                id="leaveYear"
                onChange={(e) => {
                  if (e.target.value != "") {
                    document.getElementById("leaveYearErr").style.display =
                      "none";
                    document.getElementById("leaveYear").style.borderColor =
                      "lightgray";
                    setLeaveYear(e.target.value.split(",")[0]);
                    setRemainingDay(e.target.value.split(",")[1]);
                  } else {
                    setLeaveYear("");
                    setRemainingDay("");
                  }
                }}
                className="txt-input"
                style={{ width: "98%" }}
              >
                <option style={{ color: "gray" }} value="">
                  የበጀት አመት
                </option>
                {data &&
                  data.map((item) => (
                    <option value={[item[3], item[5]]}>{item[3]}</option>
                  ))}
              </select>
              <br />
              <p className="reg-err" id="leaveAmountErr">
                *ይህ መረጃ አስፈላጊ ነው!
              </p>
              <p className="reg-err" id="leaveAmountErr2">
                *ከበጀት አመቱ ቀሪ የፈቃድ መጠን በላይ ነው!
              </p>
              <input
                className="txt-input"
                id="leaveAmount"
                type="number"
                onChange={(e) => {
                  if (leaveYear == "") {
                    document.getElementById("leaveYearErr").style.display =
                      "block";
                    document.getElementById("leaveYear").style.borderColor =
                      "red";
                    setLeaveAmount("");
                    e.target.value = "";
                  } else {
                    if (parseFloat(e.target.value) > parseFloat(remainingDay)) {
                      document.getElementById("leaveAmountErr2").style.display =
                        "block";
                      document.getElementById("leaveAmount").style.borderColor =
                        "red";
                      setLeaveAmount("");
                      e.target.value = "";
                    } else {
                      document.getElementById("leaveYearErr").style.display =
                        "none";
                      document.getElementById("leaveAmountErr2").style.display =
                        "none";
                      document.getElementById("leaveYear").style.borderColor =
                        "lightgray";
                      document.getElementById("leaveAmount").style.borderColor =
                        "lightgray";
                      setLeaveAmount(e.target.value);
                    }
                  }
                }}
                onBlur={(e) => {
                  if (parseFloat(leaveAmount) <= 0) {
                    setLeaveAmount("");
                    e.target.value = "";
                  }
                }}
                placeholder="የፈቃድ መጠን (ቀን)"
                required
              />
              <br />
              <p className="reg-err" id="outDateErr">
                *ይህ መረጃ አስፈላጊ ነው!
              </p>
              <label style={{ fontSize: "12px" }}>የመውጫ ቀን</label>
              <br />
              <input
                className="txt-input"
                id="outDate"
                type="date"
                onChange={(e) => setOutDate(e.target.value)}
                required
              />
              <br />
              <p className="reg-err" id="inDateErr">
                *ይህ መረጃ አስፈላጊ ነው!
              </p>
              <label style={{ fontSize: "12px" }}>የመመለሻ ቀን</label>
              <br />
              <input
                className="txt-input"
                id="inDate"
                type="date"
                onChange={(e) => {
                  if (moment(e.target.value).diff(outDate, "days", true) < 0) {
                    setInDate("");
                    e.target.value = "";
                  } else {
                    setInDate(e.target.value);
                  }
                }}
                required
              />
              <br />
              <div style={{ display: "inline-flex", marginBottom: "5px" }}>
                <input
                  type="button"
                  value={"ጠይቅ"}
                  className="btn1"
                  style={{ width: "150px" }}
                  onClick={RegiBtn}
                />
              </div>
            </div>
            <div
              style={{
                width: "40%",
                minWidth: "300px",
              }}
            >
              <h5 style={{ color: "gray", marginLeft: "20px" }}>
                ያለዎት የፈቃድ መጠን
              </h5>
              <table
                style={{
                  width: "100%",
                  minWidth: "300px",
                }}
              >
                <tr
                  style={{
                    width: "100%",
                    textAlign: "left",
                    color: "gray",
                  }}
                >
                  <th>የበጀት አመት</th>
                  <th>ጠቅላላ ፈቃድ (ቀን)</th>
                  <th>ቀሪ ፈቃድ (ቀን)</th>
                  <th>ምርመራ</th>
                </tr>
                {isLoading ? (
                  <div style={{ width: "100%", textAlign: "center" }}>
                    <img
                      src={loading2}
                      alt="loading..."
                      style={{ width: "50px", opacity: "0.3" }}
                    />
                  </div>
                ) : (
                  <>
                    {data != [] &&
                      data.map((list) => (
                        <tr key={list[0]}>
                          <td> {list[3]}</td>
                          <td> {parseFloat(list[4]).toLocaleString()}</td>
                          <td> {parseFloat(list[5]).toLocaleString()}</td>
                          <td> </td>
                        </tr>
                      ))}
                    <tr>
                      <td
                        style={{
                          fontWeight: "bold",
                          fontSize: "12px",
                          textAlign: "right",
                        }}
                      >
                        ድምር
                      </td>
                      <td style={{ fontWeight: "bold" }}>
                        {data
                          .reduce((totAmt, item) => {
                            return totAmt + parseFloat(item[4]);
                          }, 0)
                          .toLocaleString()}
                      </td>
                      <td style={{ fontWeight: "bold" }}>
                        {data
                          .reduce((totAmt, item) => {
                            return totAmt + parseFloat(item[5]);
                          }, 0)
                          .toLocaleString()}
                      </td>
                    </tr>
                  </>
                )}
              </table>
            </div>
          </div>
          <div>
            <h5 style={{ color: "gray", marginLeft: "20px" }}>
              የጠየቁት ፈቃድ ዝርዝር
            </h5>
            <p
              className="reg-err"
              id="successMsg"
              style={{
                color: "green",
                display: "none",
                marginTop: "10px",
                width: "100%",
                textAlign: "center",
              }}
            >
              ጥያቄው በተሳካ ሁኔታ ተልኳል!
            </p>
            <table
              style={{
                width: "100%",
                minWidth: "600px",
              }}
            >
              <tr
                style={{
                  width: "100%",
                  textAlign: "left",
                  color: "gray",
                  position: "sticky",
                  top: 45,
                }}
              >
                <th>የፈቃድ አይነት</th>
                <th>የፈቃድ መጠን (ቀን)</th>
                <th>የመውጫ ቀን</th>
                <th>የመመለሻ ቀን</th>
                <th>የተጠየቀበት ቀን</th>
                <th>ያለበት ሁኔታ</th>
                <th></th>
              </tr>
              {isLoading ? (
                <div style={{ width: "100%", textAlign: "center" }}>
                  <img
                    src={loading2}
                    alt="loading..."
                    style={{ width: "50px", opacity: "0.3" }}
                  />
                </div>
              ) : (
                <>
                  {data2 != [] &&
                    data2.map((list) => (
                      <tr key={list[0]}>
                        <td> {list[3]}</td>
                        <td> {list[5]}</td>
                        <td> {moment(list[6]).format("DD-MM-YYYY")}</td>
                        <td> {moment(list[7]).format("DD-MM-YYYY")}</td>
                        <td> {moment(list[8]).format("DD-MM-YYYY")}</td>
                        <td>
                          {list[9] == 0 ? (
                            <p style={{ fontSize: "12px", color: "red" }}>
                              ያልተረጋገጠ
                            </p>
                          ) : moment().diff(list[7], "days", true) > 0 ? (
                            <p style={{ fontSize: "12px", color: "gray" }}>
                              ተጠናቋል
                            </p>
                          ) : (
                            <p style={{ fontSize: "12px", color: "green" }}>
                              ተረጋግጧል
                            </p>
                          )}
                        </td>
                        <td>
                          <FontAwesomeIcon
                            icon={faPrint}
                            style={{ cursor: "pointer" }}
                            onClick={() => HandleOpenPrint(list[0])}
                          />
                        </td>
                      </tr>
                    ))}
                </>
              )}
            </table>
          </div>
        </div>
      )}
    </>
  );
}

export default LeaveRequest;
