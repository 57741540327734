import axios from "axios";
import { React, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import loading1 from "../img/loading1.gif";
import loading2 from "../img/loading2.gif";
import moment from "moment";

function EmpExperience() {
  const [isLoading, setIsLoading] = useState(false);
  const tab = useSelector((state) => state.auth.tab);
  const location = useLocation();
  const empId = location.state.id;
  const idNumber = location.state.idNumber;
  const fullName = location.state.fullName;
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [leaveYear, setLeaveYear] = useState("");
  const [leaveAmount, setLeaveAmount] = useState("");
  const navigate = useNavigate();
  let errMsg = 0;

  useEffect(() => {
    document.getElementById("windowTitle").textContent = "/የሰው ሃይል /የፈቃድ ምዝገባ";

    empId != "" &&
      axios
        .post("https://www.bdc.et/bdc-api/get_employee_id.php", {
          empId: empId,
        })
        .then((res) => {
          setData(res.data[0]);
        });

    empId != "" &&
      axios
        .post("https://www.bdc.et/bdc-api/get_employee_leave.php", {
          empId: empId,
        })
        .then((res) => {
          setData2(res.data);
        });
  }, []);

  const RegiBtn = () => {
    if (empId == "") {
      document.getElementById("empIdErr").style.display = "block";
      errMsg = 1;
    } else {
      document.getElementById("empIdErr").style.display = "none";
      errMsg = 0;
    }
    if (leaveYear == "") {
      document.getElementById("leaveYearErr").style.display = "block";
      document.getElementById("leaveYear").style.borderColor = "red";
      errMsg = 1;
    } else {
      document.getElementById("leaveYearErr").style.display = "none";
      document.getElementById("leaveYear").style.borderColor = "lightgray";
      errMsg = 0;
    }
    if (leaveAmount == "") {
      document.getElementById("leaveAmountErr").style.display = "block";
      document.getElementById("leaveAmount").style.borderColor = "red";
      errMsg = 1;
    } else {
      document.getElementById("leaveAmountErr").style.display = "none";
      document.getElementById("leaveAmount").style.borderColor = "lightgray";
      errMsg = 0;
    }

    if (errMsg == 0) {
      axios
        .post("https://www.bdc.et/bdc-api/post_employee_leave.php", {
          empId: empId,
          fullName: fullName,
          leaveYear: leaveYear,
          leaveAmount: leaveAmount,
        })
        .then((res) => {
          setData2(res.data);
          setLeaveYear("");
          setLeaveAmount("");
          document.getElementById("leaveYear").value = "";
          document.getElementById("leaveAmount").value = "";
          document.getElementById("successMsg").style.display = "block";
        });
    }
  };

  return (
    <>
      {tab.includes("tab11") && (
        <div
          className="cont-all"
          style={{
            width: "80%",
            height: "95%",
            padding: "0",
            overflow: "auto",
            scrollbarWidth: "thin",
          }}
        >
          <div
            style={{
              width: "100%",
              backgroundColor: "rgb(240,240,240)",
              margin: "0",
              display: "inline-flex",
              position: "sticky",
              top: 0,
            }}
          >
            <h5
              style={{
                margin: "0",
                color: "black",
                borderColor: "gray",
                borderRight: "solid",
                borderWidth: "1px",
                padding: "10px 20px",
              }}
            >
              የሰራተኛ የአመት ፈቃድ መመዝገቢያ ገፅ
            </h5>
          </div>
          <div>
            <div
              className="brdr-btm"
              style={{ width: "100%", marginLeft: "20px" }}
            >
              <p className="reg-err" id="empIdErr">
                *ይህ መረጃ አስፈላጊ ነው!
              </p>
              {data[0] && (
                <div style={{ display: "inline-flex" }}>
                  <img
                    src={
                      "https://www.bdc.et/bdc-api/uploads/profile-pictures/" +
                      data[17]
                    }
                    style={{
                      width: "50px",
                      height: "50px",
                      margin: "10px",
                      borderRadius: "50%",
                    }}
                    alt="hr-icon"
                  />
                  <div>
                    <p style={{ fontSize: "14px" }}>
                      {data[1] + " " + data[2] + " " + data[3]}
                    </p>
                    <p
                      style={{
                        fontSize: "14px",
                        color: "gray",
                        fontWeight: "bold",
                      }}
                    >
                      {data[11]}
                    </p>
                  </div>
                </div>
              )}
              <br />
              <input
                type="button"
                value={empId == "" ? "ሰራተኛ ለመምረጥ" : "ሰራተኛ ለመቀየር"}
                style={{ margin: "10px 0" }}
                onClick={() =>
                  navigate("/employee_list", {
                    state: {
                      fromPage: "employee_leave",
                    },
                  })
                }
              />
            </div>
            <br />
            <input
              type="button"
              id="showBtn"
              value={"+ ለመመዝገብ"}
              className="btn1"
              style={{ width: "150px", margin: "10px 20px" }}
              onClick={() => {
                document.getElementById("registerCont").style.display = "block";
                document.getElementById("showBtn").style.display = "none";
              }}
            />
            <div
              id="registerCont"
              style={{
                display: "none",
                width: "40%",
                minWidth: "300px",
                marginLeft: "20px",
              }}
            >
              <p
                className="reg-err"
                id="successMsg"
                style={{
                  color: "green",
                  display: "none",
                  marginTop: "10px",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                በተሳካ ሁኔታ ተመዝግቧል!
              </p>
              <p className="reg-err" id="leaveYearErr">
                *ይህ መረጃ አስፈላጊ ነው!
              </p>
              <p className="reg-err" id="minYearErr">
                *ያስገቡት አመት ከሚፈቀደው የበጀት አመት በታች ነው!
              </p>
              <p className="reg-err" id="maxYearErr">
                *ያስገቡት አመት ከሚፈቀደው የበጀት አመት በላይ ነው!
              </p>
              <input
                className="txt-input"
                id="leaveYear"
                type="number"
                min={2015}
                max={2050}
                onChange={(e) => {
                  setLeaveYear(e.target.value);
                }}
                onBlur={(e) => {
                  if (e.target.value < 2015) {
                    document.getElementById("maxYearErr").style.display =
                      "none";
                    document.getElementById("minYearErr").style.display =
                      "block";
                    document.getElementById("leaveYear").value = "";
                    setLeaveYear("");
                  } else if (e.target.value > 2050) {
                    document.getElementById("minYearErr").style.display =
                      "none";
                    document.getElementById("maxYearErr").style.display =
                      "block";
                    document.getElementById("leaveYear").value = "";
                    setLeaveYear("");
                  } else {
                    document.getElementById("minYearErr").style.display =
                      "none";
                    document.getElementById("maxYearErr").style.display =
                      "none";
                  }
                }}
                placeholder="የበጀት አመት"
                required
              />
              <br />
              <p className="reg-err" id="leaveAmountErr">
                *ይህ መረጃ አስፈላጊ ነው!
              </p>
              <input
                className="txt-input"
                id="leaveAmount"
                type="number"
                onChange={(e) => {
                  setLeaveAmount(e.target.value);
                }}
                placeholder="የፈቃድ መጠን (ቀን)"
                required
              />
              <br />
              <div style={{ display: "inline-flex", marginBottom: "5px" }}>
                <input
                  type="button"
                  value={"መዝግብ"}
                  className="btn1"
                  style={{ width: "150px" }}
                  onClick={RegiBtn}
                />
                <input
                  type="button"
                  value={"Cancel"}
                  className="btn2"
                  style={{ width: "150px", marginLeft: "20px" }}
                  onClick={() => {
                    document.getElementById("registerCont").style.display =
                      "none";
                    document.getElementById("showBtn").style.display = "block";
                  }}
                />
              </div>
            </div>

            <table
              style={{
                width: "60%",
                minWidth: "300px",
              }}
            >
              <tr
                style={{
                  width: "100%",
                  textAlign: "left",
                  color: "gray",
                  position: "sticky",
                  top: 45,
                }}
              >
                <th>የበጀት አመት</th>
                <th>ጠቅላላ ፈቃድ (ቀን)</th>
                <th>ቀሪ ፈቃድ (ቀን)</th>
                <th>ምርመራ</th>
                <th></th>
              </tr>
              {isLoading ? (
                <div style={{ width: "100%", textAlign: "center" }}>
                  <img
                    src={loading2}
                    alt="loading..."
                    style={{ width: "50px", opacity: "0.3" }}
                  />
                </div>
              ) : (
                <>
                  {data2 != [] &&
                    data2.map((list) => (
                      <tr key={list[0]}>
                        <td> {list[3]}</td>
                        <td> {parseFloat(list[4]).toLocaleString()}</td>
                        <td> {parseFloat(list[5]).toLocaleString()}</td>
                        <td> {list[7]}</td>
                        <td></td>
                      </tr>
                    ))}
                  <tr>
                    <td style={{ fontWeight: "bold", fontSize: "12px" }}>
                      ድምር
                    </td>
                    <td style={{ fontWeight: "bold" }}>
                      {data2
                        .reduce((totAmt, item) => {
                          return totAmt + parseFloat(item[4]);
                        }, 0)
                        .toLocaleString()}
                    </td>
                    <td style={{ fontWeight: "bold" }}>
                      {data2
                        .reduce((totAmt, item) => {
                          return totAmt + parseFloat(item[5]);
                        }, 0)
                        .toLocaleString()}
                    </td>
                  </tr>
                </>
              )}
            </table>
          </div>
        </div>
      )}
    </>
  );
}

export default EmpExperience;
