import axios from "axios";
import { React, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import loading1 from "../img/loading1.gif";
import loading2 from "../img/loading2.gif";

function NewResource() {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);

  const tab = useSelector((state) => state.auth.tab);
  const loggedId = useSelector((state) => state.auth.empId);
  const dep = useSelector((state) => state.auth.dep);
  const [name, setName] = useState("");
  const [category, setCategory] = useState("Civil");
  const [type, setType] = useState("Material");
  const [unit, setUnit] = useState("");
  const [resCategory, setResCategory] = useState(
    dep == "Construction Supervision" ? "Civil" : ""
  );
  const [resType, setResType] = useState("Material");
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [dialog2, setDialog2] = useState([]);
  let errMsg = 0;

  useEffect(() => {
    document.getElementById("windowTitle").textContent = "/ግብአት /ግብአት ለመመዝገብ";

    setIsLoading2(true);
    axios
      .post("https://www.bdc.et/bdc-api/get_resource_list.php", {
        resType: resType,
        resCategory: resCategory,
      })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => alert(err))
      .finally(() => setIsLoading2(false));
  }, []);

  const RegiBtn = () => {
    if (name == "") {
      document.getElementById("nameErr").style.display = "block";
      document.getElementById("name").style.borderColor = "red";
      errMsg = 1;
    } else {
      document.getElementById("nameErr").style.display = "none";
      document.getElementById("name").style.borderColor = "lightgray";
      errMsg = 0;
    }

    if (document.getElementById("unit").disabled == false && unit == "") {
      document.getElementById("unitErr").style.display = "block";
      document.getElementById("unit").style.borderColor = "red";
      errMsg = 1;
    } else {
      document.getElementById("unitErr").style.display = "none";
      document.getElementById("unit").style.borderColor = "lightgray";
      errMsg = 0;
    }

    if (errMsg == 0) {
      setIsLoading(true);
      setIsLoading2(true);
      axios
        .post("https://www.bdc.et/bdc-api/post_new_resource.php", {
          name: name,
          category: category,
          type: type,
          unit: unit,
          resType: resType,
          resCategory: resCategory,
          regBy: loggedId,
          dep: dep,
        })
        .then((res) => {
          setData(res.data);
          setName("");
          setType("Material");
          setCategory("Civil");
          document.getElementById("name").value = "";
          document.getElementById("successMsg").style.display = "block";
        })
        .catch((err) => alert(err))
        .finally(() => {
          setIsLoading(false);
          setIsLoading2(false);
        });
    }
  };

  return (
    <>
      {tab.includes("tab31") && (
        <>
          <div
            style={{
              display: "inline-flex",
              width: "80%",
              minWidth: "500px",
              height: "97%",
            }}
          >
            <div className="cont-all" style={{ width: "50%" }}>
              <h2
                className="pa-h"
                style={{
                  backgroundColor: "gray",
                  color: "white",
                  margin: 0,
                  padding: "5px 0px",
                  width: "100%",
                }}
              >
                የግብአት መግለጫ
              </h2>
              <p
                className="reg-err"
                id="successMsg"
                style={{
                  color: "green",
                  display: "none",
                  marginTop: "10px",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                በተሳካ ሁኔታ ተመዝግቧል!
              </p>
              <div style={{ width: "100%" }} id="regCont">
                <div className="cont2">
                  <p className="reg-err" id="nameErr">
                    *ይህ መረጃ አስፈላጊ ነው!
                  </p>
                  <div>
                    <input
                      className="txt-input"
                      id="name"
                      type="text"
                      onChange={(e) => {
                        setName(e.target.value);
                        if (e.target.value != "" && e.target.value != " ") {
                          axios
                            .post(
                              "https://www.bdc.et/bdc-api/get_search_resource.php",
                              {
                                searchTerm: e.target.value,
                              }
                            )
                            .then((res) => {
                              if (res.data != "") {
                                document.getElementById(
                                  "resSugg"
                                ).style.display = "block";
                                setDialog2(res.data);
                              } else {
                                document.getElementById(
                                  "resSugg"
                                ).style.display = "none";
                              }
                            });
                        } else {
                          document.getElementById("resSugg").style.display =
                            "none";
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.key == "Enter") {
                          document.getElementById("type").focus();
                        }
                      }}
                      placeholder="የግብአት ስም"
                      required
                    />
                    <div
                      id="resSugg"
                      style={{
                        maxHeight: "300px",
                        width: "100%",
                        overflowY: "auto",
                        overflowX: "clip",
                        scrollbarWidth: "thin",
                        boxShadow: "0px 5px 5px lightgray",
                        borderRadius: "5px",
                        display: "none",
                      }}
                    >
                      <p
                        style={{
                          color: "rgb(150,150,150)",
                          fontSize: "12px",
                        }}
                      >
                        የተመዘገቡ ግብአቶች{" "}
                      </p>
                      {dialog2.map((list) => (
                        <>
                          <p
                            className="resource-suggestion"
                            id={"suggestion" + list[1]}
                            style={{
                              width: "100%",
                              cursor: "pointer",
                              padding: "2px 5px",
                              fontSize: "14px",
                            }}
                            onClick={() => {
                              document.getElementById("name").value =
                                document.getElementById(
                                  "suggestion" + list[1]
                                ).textContent;
                              document.getElementById("name").focus();
                              document.getElementById("resSugg").style.display =
                                "none";
                            }}
                          >
                            {list[1]}
                          </p>
                        </>
                      ))}
                    </div>
                  </div>
                  <br />
                  <select
                    name="type"
                    id="type"
                    style={{ width: "50%" }}
                    onChange={(e) => {
                      setType(e.target.value);
                      if (
                        e.target.value == "Labor" ||
                        e.target.value == "Others"
                      ) {
                        setUnit("");
                        document.getElementById("unit").disabled = true;
                      } else {
                        document.getElementById("unit").disabled = false;
                      }
                    }}
                    onFocus={() =>
                      (document.getElementById("resSugg").style.display =
                        "none")
                    }
                    onKeyDown={(e) => {
                      if (e.key == "Enter") {
                        document.getElementById("category").focus();
                      }
                    }}
                    className="txt-input"
                  >
                    <option value="Material">Material</option>
                    <option value="Labor">Labor</option>
                    <option value="Equipment">Equipment</option>
                    <option value="Others">Others</option>
                  </select>
                  <br />
                  {dep == "Construction Supervision" && (
                    <>
                      <select
                        name="type"
                        id="category"
                        onChange={(e) => setCategory(e.target.value)}
                        className="txt-input"
                        style={{ width: "50%" }}
                        onFocus={() =>
                          (document.getElementById("resSugg").style.display =
                            "none")
                        }
                        onKeyDown={(e) => {
                          if (e.key == "Enter") {
                            document.getElementById("unit").focus();
                          }
                        }}
                      >
                        <option value="Civil">Civil</option>
                        <option value="Finishing">Finishing</option>
                        <option value="Electrical">Electrical</option>
                        <option value="Sanitary">Sanitary</option>
                        <option value="Others">Others</option>
                      </select>
                      <br />
                    </>
                  )}
                  <p className="reg-err" id="unitErr">
                    *ይህ መረጃ አስፈላጊ ነው!
                  </p>
                  <select
                    name="type"
                    id="unit"
                    onChange={(e) => setUnit(e.target.value)}
                    className="txt-input"
                    style={{ width: "50%" }}
                    onFocus={() =>
                      (document.getElementById("resSugg").style.display =
                        "none")
                    }
                    onKeyDown={(e) => {
                      if (e.key == "Enter") {
                        document.getElementById("register").focus();
                      }
                    }}
                  >
                    <option style={{ color: "gray" }} value="">
                      መለክያ ለመምረጥ
                    </option>
                    {type == "Material" && (
                      <>
                        <option value="m3">Cubic meter (m3)</option>
                        <option value="m2">Square meter (m2)</option>
                        <option value="ml">Meter Linear (ml)</option>
                        <option value="qtl">Quintal (qtl)</option>
                        <option value="kg">Kilogram (kg)</option>
                        <option value="pcs">Pieces (pcs)</option>
                        <option value="pack">Pack (pack)</option>
                        <option value="lit">Litter (lit)</option>
                        <option value="gal">Gallon (gal)</option>
                        <option value="ls">Lumpsum (ls)</option>
                      </>
                    )}
                    {type == "Equipment" && (
                      <>
                        <option value="pcs">Pieces (pcs)</option>
                        <option value="hr">Hour (hr)</option>
                      </>
                    )}
                  </select>
                  <br />
                  {isLoading ? (
                    <div style={{ width: "100%", textAlign: "center" }}>
                      <img
                        src={loading1}
                        alt="login..."
                        style={{ width: "100px" }}
                      />
                    </div>
                  ) : (
                    <input
                      type="button"
                      id="register"
                      value="መዝግብ"
                      className="btn1"
                      style={{ margin: "20px 0", width: "50%" }}
                      onClick={RegiBtn}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default NewResource;
