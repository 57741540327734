import React from "react";
import { Routes, Route } from "react-router-dom";
import EmpRegistration from "./emp_registration";
import EmpConfirm from "./empConfirm";
import EmpDetail from "./empDetail";
import EmpDashboard from "./empDashboard";
import NewProject from "./projectNew";
import NewResource from "./resourceNew";
import ResourceList from "./resourceList";
import ExpenseRequest from "./expenseRequest";
import ResourceRequest from "./resourceRequest";
import ResourceRequestList from "./resourceRequestList";
import ResourceRequestDetail from "./resourceRequestDetail";
import ExpenseSettle from "./expenseSettle";
import ExpenseRequestList from "./expenseRequestList";
import ExpenseSettleReport from "./expenseSettleReport";
import ProjectDetail from "./projectDetail";
import ResourceDetail from "./resourceDetail";
import StaffList from "./empList";
import ProjectList from "./projectList";
import ExpenseRequestDetail from "./expenseRequestDetail";
import ExpenseSettleDetail from "./expenseSettleDetail";
import ExpenseSettleList from "./expenseSettleList";
import HumanResource from "./humanResource";
import EmpExperience from "./empExperience";
import EmpPayment from "./empPayment";
import EmpLeave from "./empLeave";
import LeaveRequest from "./leaveRequest";
import EmpRole from "./empRole";
import ContractAdministration from "./contractAdministration";
import ConstructionSupervision from "./constructionSupervision";
import Home from "./home";
import PurchaseLogistics from "./purchaseLogistics";
import MachineryEquipment from "./machineryEquipment";
import Finance from "./finance";
import DailyReportSend from "./dailyReportSend";
import MonthPhysicalReportSend from "./monthPhysicalReportSend";
import MonthFinancialReportSend from "./monthFinancialReportSend";
import DailyReportShow from "./dailyReportShow";
import MonthPhysicalReportShow from "./monthPhysicalReportShow";
import MonthFinancialReportShow from "./monthFinancialReportShow";
import PaymentAdvance from "./paymentAdvance";

function MainContCont() {
  return (
    <div className="main-cont-cont">
      <Routes>
        <Route exact path="/" element={<EmpDashboard />} />
        <Route
          exact
          path="/employee_registration"
          element={<EmpRegistration />}
        />
        <Route exact path="/employee_confirmation" element={<EmpConfirm />} />
        <Route exact path="/employee_list" element={<StaffList />} />
        <Route exact path="/employee_detail" element={<EmpDetail />} />
        <Route exact path="/employee_dashboard" element={<EmpDashboard />} />
        <Route exact path="/employee_role" element={<EmpRole />} />
        <Route exact path="/new_project" element={<NewProject />} />
        <Route exact path="/project_list" element={<ProjectList />} />
        <Route exact path="/new_resource" element={<NewResource />} />
        <Route exact path="/resource_list" element={<ResourceList />} />
        <Route exact path="/request_for_expense" element={<ExpenseRequest />} />
        <Route exact path="/resource_request" element={<ResourceRequest />} />
        <Route
          exact
          path="/resource_request_detail"
          element={<ResourceRequestDetail />}
        />
        <Route
          exact
          path="/requested_resource_list"
          element={<ResourceRequestList />}
        />
        <Route
          exact
          path="/expense_request_detail"
          element={<ExpenseRequestDetail />}
        />
        <Route
          exact
          path="/requested_expense_list"
          element={<ExpenseRequestList />}
        />
        <Route exact path="/settle_expense" element={<ExpenseSettle />} />
        <Route
          exact
          path="/expense_settle_detail"
          element={<ExpenseSettleDetail />}
        />
        <Route
          exact
          path="/settled_expense_list"
          element={<ExpenseSettleList />}
        />
        <Route
          exact
          path="/settled_expense_report"
          element={<ExpenseSettleReport />}
        />
        <Route exact path="/project_detail" element={<ProjectDetail />} />
        <Route exact path="/resource_detail" element={<ResourceDetail />} />
        <Route exact path="/human_resource" element={<HumanResource />} />
        <Route exact path="/employee_experience" element={<EmpExperience />} />
        <Route exact path="/employee_payment" element={<EmpPayment />} />
        <Route exact path="/employee_leave" element={<EmpLeave />} />
        <Route exact path="/leave_request" element={<LeaveRequest />} />
        <Route
          exact
          path="/contract_administration"
          element={<ContractAdministration />}
        />
        <Route
          exact
          path="/construction_supervision"
          element={<ConstructionSupervision />}
        />
        <Route
          exact
          path="/purchase_and_logistics"
          element={<PurchaseLogistics />}
        />
        <Route
          exact
          path="/machinery_and_vehicle"
          element={<MachineryEquipment />}
        />
        <Route exact path="/finance" element={<Finance />} />
        <Route exact path="/send_daily_report" element={<DailyReportSend />} />
        <Route exact path="/send_month_physical_report" element={<MonthPhysicalReportSend />} />
        <Route exact path="/send_month_financial_report" element={<MonthFinancialReportSend />} />
        <Route exact path="/daily_report_show" element={<DailyReportShow />} />
        <Route exact path="/month_physical_report_show" element={<MonthPhysicalReportShow />} />
        <Route exact path="/month_financial_report_show" element={<MonthFinancialReportShow />} />
        <Route exact path="/advance_payment" element={<PaymentAdvance />} />
      </Routes>
    </div>
  );
}

export default MainContCont;
