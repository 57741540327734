import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDashboard, faHome } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import hrIcon from "../img/hr-icon.png";
import caIcon from "../img/ca-icon.png";
import csIcon from "../img/cs-icon.png";
import plIcon from "../img/pl-icon.png";
import meIcon from "../img/me-icon.png";
import fIcon from "../img/f-icon.png";
import { useNavigate } from "react-router-dom";

function Sidemenu() {
  const navigate = useNavigate();
  const tab = useSelector((state) => state.auth.tab);

  return (
    <div className="sideBar">
      <div
        style={{
          width: "180px",
          margin: 0,
          backgroundColor: "rgb(91, 91, 91)",
          height: "100%",
          overflowY: "auto",
          paddingLeft: "20px",
          scrollbarWidth: "thin",
        }}
      >
        <>
          <Link
            style={{
              marginLeft: "-20px",
              width: "200px",
              marginBottom: "10px",
              backgroundColor:"black",
            }}
            className="side-title"
            to="/employee_dashboard"
          >
            <FontAwesomeIcon
              className="menu-icon"
              style={{ color: "rgb(190,190,190)", marginLeft: "20px" }}
              icon={faHome}
            />
            <div className="side-title-cont">Home</div>
          </Link>
          <br />
        </>
        {(tab.includes("tab11") || tab.includes("tab12")) && (
          <div
            className="dep-icon-cont"
            onClick={() => navigate("/human_resource")}
          >
            <img src={hrIcon} className="dep-icon-img" alt="hr-icon" />
            <br />
            <div style={{ fontSize: "12px" }}>የሰው ሃይል</div>
            <br />
          </div>
        )}
        {(tab.includes("tab11") || tab.includes("tab12")) && (
          <div
            className="dep-icon-cont"
            onClick={() => navigate("/contract_administration")}
          >
            <img src={caIcon} className="dep-icon-img" alt="ca-icon" />
            <br />
            <div style={{ fontSize: "12px" }}>ኮንትራት አስተዳድር</div>
            <br />
          </div>
        )}
        {(tab.includes("tab11") || tab.includes("tab12")) && (
          <div
            className="dep-icon-cont"
            onClick={() => navigate("/construction_supervision")}
          >
            <img src={csIcon} className="dep-icon-img" alt="cs-icon" />
            <br />
            <div style={{ fontSize: "12px" }}>ግንባታ ክትትል</div>
            <br />
          </div>
        )}
        {(tab.includes("tab11") || tab.includes("tab12")) && (
          <div
            className="dep-icon-cont"
            onClick={() => navigate("/purchase_and_logistics")}
          >
            <img src={plIcon} className="dep-icon-img" alt="pl-icon" />
            <br />
            <div style={{ fontSize: "12px" }}>ግዥና አቅርቦት</div>
            <br />
          </div>
        )}
        {(tab.includes("tab11") || tab.includes("tab12")) && (
          <div
            className="dep-icon-cont"
            onClick={() => navigate("/machinery_and_vehicle")}
          >
            <img src={meIcon} className="dep-icon-img" alt="me-icon" />
            <br />
            <div style={{ fontSize: "12px" }}>ማሽነሪና ተሸከርካሪ</div>
            <br />
          </div>
        )}
        {(tab.includes("tab11") || tab.includes("tab12")) && (
          <div className="dep-icon-cont" onClick={() => navigate("/finance")}>
            <img src={fIcon} className="dep-icon-img" alt="f-icon" />
            <br />
            <div style={{ fontSize: "12px" }}>ፋይናንስ</div>
            <br />
          </div>
        )}
      </div>
    </div>
  );
}

export default Sidemenu;
