import React, { useState, useEffect } from "react";
import logo from "../img/logo2.png";

import { useDispatch } from "react-redux";
import { authAction } from "./state/auth_slice";
import { useSelector } from "react-redux";
import axios from "axios";
import loading2 from "../img/loading2.gif";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOut } from "@fortawesome/free-solid-svg-icons";

function Navbar() {
  const [windowTitle, setWindowTitle] = useState("");
  const department = useSelector((state) => state.auth.dep);
  const logout = () => {
    dispatch(authAction.logout());
  };
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const fullName = useSelector((state) => state.auth.fullName);
  const empId = useSelector((state) => state.auth.empId);

  useEffect(() => {
    setIsLoading(true);
    axios
      .post("https://www.bdc.et/bdc-api/get_employee_id.php", {
        empId: empId,
      })
      .then((res) => setData(res.data[0]))
      .catch((err) => alert(err))
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <div className="nav-cont" style={{ display: "inline-flex" }}>
      <img src={logo} className="logo-pic" alt="logo" />
      <div style={{ display: "block" }}>
        <h1
          style={{
            color: "white",
            margin: "0",
            padding: "0",
            fontSize: "18px",
          }}
        >
          ቢዲሲ ኮንስትራክሽን
        </h1>
        <div
          style={{
            margin: 0,
            padding: 0,
            display: "inline-flex",
            width: "100%",
          }}
        >
          <p
            style={{
              fontSize: "12px",
              margin: "0 5px",
              padding: "0 10px",
              color: "white",
              borderStyle: "solid",
              borderColor: "white",
              borderWidth: "1px",
              borderRadius: "3px",
            }}
          >
            {department == "Construction Supervision" && "የግንባታ ክትትል መምርያ"}
            {department == "Contract Administration" && "ኮንትራት አስተዳድር መምርያ"}
            {department == "Purchase and Logistics" && "የግዥና አቅርቦት መምርያ"}
            {department == "Human Resource" && "የሰው ሃይል መምርያ"}
            {department == "Machinery and Equipment" && "የማሽነሪና ተሽከርካሪ መምርያ"}
            {department == "Finance" && "የፋይናንስ መምርያ"}
          </p>

          <p
            id="windowTitle"
            style={{
              fontSize: "12px",
              margin: 0,
              padding: 0,
              color: "white",
              color: "rgb(150,150,150)",
            }}
            onChange={(e) => {
              setWindowTitle(e.target.value);
            }}
          >
            {windowTitle}
          </p>
        </div>
      </div>
      <div
        style={{
          position: "absolute",
          right: "0",
        }}
      >
        <div
          style={{
            width: "100%",
            marginLeft: "-20px",
            textAlign: "center",
          }}
        >
          {isLoading ? (
            <div style={{ width: "100%", textAlign: "center" }}>
              <img
                src={loading2}
                alt="loading..."
                style={{ width: "40px", opacity: "0.3" }}
              />
            </div>
          ) : (
            <div style={{ display: "inline-flex" }}>
              <p style={{ color: "white", margin:0, fontSize:"14px" }}>{fullName}</p>
              <img
                style={{ width: "40px", height: "40px", borderRadius: "50%" }}
                src={
                  "https://www.bdc.et/bdc-api/uploads/profile-pictures/" +
                  data[17]
                }
              />
              <p
                style={{
                  color: "rgb(200,200,200)",
                  fontSize: "14px",
                  margin:0
                }}
              >
                {data && data[11]}
              </p>
              <Link className="side-title" onClick={logout}>
                <FontAwesomeIcon
                  className="menu-icon"
                  style={{ color: "rgb(190,190,190)", marginBottom: "10px" }}
                  icon={faSignOut}
                />
                <div className="side-title-cont" style={{fontSize:"14px"}}>ለመውጣት</div>
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Navbar;
