import axios from "axios";
import { React, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import loading1 from "../img/loading1.gif";
import loading2 from "../img/loading2.gif";
import moment from "moment";

function EmpExperience() {
  const [isLoading, setIsLoading] = useState(false);
  const tab = useSelector((state) => state.auth.tab);
  const location = useLocation();
  const empId = location.state.id;
  const idNumber = location.state.idNumber;
  const fullName = location.state.fullName;
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [company, setCompany] = useState("");
  const [companyLocation, setCompanyLocation] = useState("");
  const [workPosition, setPosition] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [dateDiff, setDateDiff] = useState("");
  const navigate = useNavigate();
  let errMsg = 0;

  useEffect(() => {
    document.getElementById("windowTitle").textContent = "/የሰው ሃይል /የስራ ልምድ";

    empId != "" &&
      axios
        .post("https://www.bdc.et/bdc-api/get_employee_id.php", {
          empId: empId,
        })
        .then((res) => {
          setData(res.data[0]);
        });

    empId != "" &&
      axios
        .post("https://www.bdc.et/bdc-api/get_employee_experience.php", {
          empId: empId,
        })
        .then((res) => {
          setData2(res.data);
        });
  }, []);

  const RegiBtn = () => {
    if (empId == "") {
      document.getElementById("empIdErr").style.display = "block";
      errMsg = 1;
    } else {
      document.getElementById("empIdErr").style.display = "none";
    }
    if (company == "") {
      document.getElementById("companyErr").style.display = "block";
      document.getElementById("company").style.borderColor = "red";
      errMsg = 1;
    } else {
      document.getElementById("companyErr").style.display = "none";
      document.getElementById("company").style.borderColor = "lightgray";
    }
    if (companyLocation == "") {
      document.getElementById("companyLocationErr").style.display = "block";
      document.getElementById("companyLocation").style.borderColor = "red";
      errMsg = 1;
    } else {
      document.getElementById("companyLocationErr").style.display = "none";
      document.getElementById("companyLocation").style.borderColor =
        "lightgray";
    }
    if (workPosition == "") {
      document.getElementById("workPositionErr").style.display = "block";
      document.getElementById("workPosition").style.borderColor = "red";
      errMsg = 1;
    } else {
      document.getElementById("workPositionErr").style.display = "none";
      document.getElementById("workPosition").style.borderColor = "lightgray";
    }
    if (startDate == "") {
      document.getElementById("startDateErr").style.display = "block";
      document.getElementById("startDate").style.borderColor = "red";
      errMsg = 1;
    } else {
      document.getElementById("startDateErr").style.display = "none";
      document.getElementById("startDate").style.borderColor = "lightgray";
    }

    if (errMsg == 0) {
      axios
        .post("https://www.bdc.et/bdc-api/post_employee_experience.php", {
          empId: empId,
          fullName: fullName,
          company: company,
          companyLocation: companyLocation,
          workPosition: workPosition,
          startDate: startDate,
          endDate: endDate,
        })
        .then((res) => {
          setData2(res.data);
          setCompany("");
          setCompanyLocation("");
          setPosition("");
          setStartDate("");
          setEndDate("");
          setDateDiff("0");
          document.getElementById("company").value = "";
          document.getElementById("companyLocation").value = "";
          document.getElementById("workPosition").value = "";
          document.getElementById("startDate").value = "";
          document.getElementById("endDate").value = "";
          document.getElementById("successMsg").style.display = "block";
        });
    }
  };

  return (
    <>
      {tab.includes("tab11") && (
        <div
          className="cont-all"
          style={{
            width: "80%",
            height: "95%",
            padding: "0",
            overflow: "auto",
            scrollbarWidth: "thin",
          }}
        >
          <div
            style={{
              width: "100%",
              backgroundColor: "rgb(240,240,240)",
              margin: "0",
              display: "inline-flex",
              position: "sticky",
              top: 0,
            }}
          >
            <h5
              style={{
                margin: "0",
                color: "black",
                borderColor: "gray",
                borderRight: "solid",
                borderWidth: "1px",
                padding: "10px 20px",
              }}
            >
              የሰራተኛ የስራ ልምድ መመዝገቢያ ገፅ
            </h5>
            <p style={{ color: "white", marginLeft: "20px" }}>{fullName}</p>
          </div>
          <div>
            <div
              className="brdr-btm"
              style={{ width: "100%", paddingLeft: "20px" }}
            >
              <p className="reg-err" id="empIdErr">
                *ይህ መረጃ አስፈላጊ ነው!
              </p>
              {data[0] && (
                <div style={{ display: "inline-flex" }}>
                  <img
                    src={
                      "https://www.bdc.et/bdc-api/uploads/profile-pictures/" +
                      data[17]
                    }
                    style={{
                      width: "50px",
                      height: "50px",
                      margin: "10px",
                      borderRadius: "50%",
                    }}
                    alt="hr-icon"
                  />
                  <div>
                    <p style={{ fontSize: "14px" }}>
                      {data[1] + " " + data[2] + " " + data[3]}
                    </p>
                    <p
                      style={{
                        fontSize: "14px",
                        color: "gray",
                        fontWeight: "bold",
                      }}
                    >
                      {data[11]}
                    </p>
                  </div>
                </div>
              )}
              <br />
              <input
                type="button"
                value={empId == "" ? "ሰራተኛ ለመምረጥ" : "ሰራተኛ ለመቀየር"}
                style={{ margin: "10px 0" }}
                onClick={() =>
                  navigate("/employee_list", {
                    state: {
                      fromPage: "employee_experience",
                    },
                  })
                }
              />
            </div>
            <br />
            <input
              type="button"
              id="showBtn"
              value={"+ ለመመዝገብ"}
              className="btn1"
              style={{
                width: "150px",
                marginBottom: "10px",
                marginLeft: "20px",
              }}
              onClick={() => {
                document.getElementById("registerCont").style.display = "block";
                document.getElementById("showBtn").style.display = "none";
              }}
            />
            <div
              id="registerCont"
              style={{
                display: "none",
                width: "40%",
                minWidth: "300px",
                marginLeft: "20px",
              }}
            >
              <p
                className="reg-err"
                id="successMsg"
                style={{
                  color: "green",
                  display: "none",
                  marginTop: "10px",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                በተሳካ ሁኔታ ተመዝግቧል!
              </p>
              <p className="reg-err" id="companyErr">
                *ይህ መረጃ አስፈላጊ ነው!
              </p>
              <input
                className="txt-input"
                id="company"
                type="text"
                onChange={(e) => {
                  setCompany(e.target.value);
                }}
                placeholder="የሰሩበት ተቋም"
                required
              />
              <br />
              <p className="reg-err" id="workPositionErr">
                *ይህ መረጃ አስፈላጊ ነው!
              </p>
              <input
                className="txt-input"
                id="workPosition"
                type="text"
                onChange={(e) => {
                  setPosition(e.target.value);
                }}
                placeholder="የስራ መደብ"
                required
              />
              <br />
              <p className="reg-err" id="companyLocationErr">
                *ይህ መረጃ አስፈላጊ ነው!
              </p>
              <input
                className="txt-input"
                id="companyLocation"
                type="text"
                onChange={(e) => {
                  setCompanyLocation(e.target.value);
                }}
                placeholder="የስራ ቦታ"
                required
              />
              <br />

              <p className="reg-err" id="startDateErr">
                *ይህ መረጃ አስፈላጊ ነው!
              </p>
              <p className="reg-err" id="startDateErr">
                *ይህ መረጃ አስፈላጊ ነው!
              </p>
              <label style={{ fontSize: "12px" }}>የጀመሩበት ቀን</label>
              <br />
              <input
                className="txt-input"
                id="startDate"
                type="date"
                onChange={(e) => {
                  setStartDate(e.target.value);
                  endDate
                    ? setDateDiff(
                        moment(endDate).diff(startDate, "years", true)
                      )
                    : setDateDiff(moment().diff(startDate, "years", true));
                }}
                required
              />
              <br />
              <label style={{ fontSize: "12px" }}>ያጠናቀቁበት ቀን</label>
              <br />
              <input
                className="txt-input"
                id="endDate"
                type="date"
                onChange={(e) => {
                  setEndDate(e.target.value);
                  setDateDiff(moment(endDate).diff(startDate, "years", true));
                }}
                required
              />
              <label style={{ fontSize: "12px" }}>የስራ ልምድ </label>
              <br />
              <input
                className="txt-input"
                type="text"
                value={
                  dateDiff ? parseFloat(dateDiff).toFixed(2) + " አመት" : "0 አመት"
                }
                disabled
              />
              <br />
              <div style={{ display: "inline-flex", marginBottom: "5px" }}>
                <input
                  type="button"
                  value={"መዝግብ"}
                  className="btn1"
                  style={{ width: "150px" }}
                  onClick={RegiBtn}
                />
                <input
                  type="button"
                  value={"Cancel"}
                  className="btn2"
                  style={{ width: "150px", marginLeft: "20px" }}
                  onClick={() => {
                    document.getElementById("registerCont").style.display =
                      "none";
                    document.getElementById("showBtn").style.display = "block";
                  }}
                />
              </div>
            </div>

            <table
              style={{
                width: "100%",
                minWidth: "600px",
              }}
            >
              <tr
                style={{
                  width: "100%",
                  textAlign: "left",
                  color: "gray",
                  position: "sticky",
                  top: 45,
                }}
              >
                <th>የሰሩበት ተቋም</th>
                <th>የስራ መደብ</th>
                <th>የስራ ቦታ</th>
                <th>የጀመሩበት ቀን</th>
                <th>ያጠናቀቁበት ቀን</th>
                <th>የስራ ልምድ (አመት)</th>
              </tr>
              {isLoading ? (
                <div style={{ width: "100%", textAlign: "center" }}>
                  <img
                    src={loading2}
                    alt="loading..."
                    style={{ width: "50px", opacity: "0.3" }}
                  />
                </div>
              ) : (
                <>
                  {data2 != [] &&
                    data2.map((list) => (
                      <tr key={list[0]}>
                        <td> {list[3]}</td>
                        <td> {list[5]}</td>
                        <td> {list[4]}</td>
                        <td> {list[6]}</td>
                        <td>
                          {" "}
                          {list[7] == "0000-00-00" ? (
                            <p style={{ fontSize: "12px" }}>እስካሁን</p>
                          ) : (
                            list[7]
                          )}
                        </td>
                        <td>
                          {" "}
                          {parseFloat(
                            list[7] == "0000-00-00"
                              ? moment().diff(list[6], "years", true)
                              : moment(list[7]).diff(list[6], "years", true)
                          ).toFixed(2)}
                        </td>
                      </tr>
                    ))}
                  <tr>
                    <td style={{ borderStyle: "none" }}></td>
                    <td style={{ borderStyle: "none" }}></td>
                    <td style={{ borderStyle: "none" }}></td>
                    <td style={{ borderStyle: "none" }}> </td>
                    <td style={{ fontWeight: "bold", fontSize: "12px" }}>
                      ድምር
                    </td>
                    <td style={{ fontWeight: "bold" }}>
                      {data2
                        .reduce((totAmt, item) => {
                          return (
                            totAmt +
                            parseFloat(
                              item[7] == "0000-00-00"
                                ? moment().diff(item[6], "years", true)
                                : moment(item[7]).diff(item[6], "years", true)
                            )
                          );
                        }, 0)
                        .toFixed(2)}
                    </td>
                  </tr>
                </>
              )}
            </table>
          </div>
        </div>
      )}
    </>
  );
}

export default EmpExperience;
