import axios from "axios";
import { React, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import loading2 from "../img/loading2.gif";

function MonthFinancialReportSend() {
  const [isLoading, setIsLoading] = useState(false);
  const tab = useSelector((state) => state.auth.tab);
  const empId = useSelector((state) => state.auth.empId);
  const [reportMonth, setReportMonth] = useState("");
  const [project, setProject] = useState("");
  const [projectId, setProjectId] = useState("");
  const [prevPlan, setPrevPlan] = useState(0);
  const [thisPlan, setThisPlan] = useState(0);
  const [toThisPlan, setToThisPlan] = useState("");
  const [prevExe, setPrevExe] = useState(0);
  const [thisExe, setThisExe] = useState(0);
  const [toThisExe, setToThisExe] = useState("");
  let errMsg = 0;

  const [openDialog, setOpenDialog] = useState(false);
  const [dialog, setDialog] = useState([]);
  const [isLoading2, setIsLoading2] = useState(false);
  const [isLoading3, setIsLoading3] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    document.getElementById("windowTitle").textContent =
      "/የግንባታ ክትትል /የወር ፋይናንሻል ሪፖርት ምዝገባ";
  }, []);

  const OpenDialog = () => {
    setOpenDialog(true);
    setIsLoading2(true);
    axios
      .post("https://www.bdc.et/bdc-api/get_project_list.php", {})
      .then((res) => {
        setDialog(res.data);
      })
      .catch((err) => alert(err))
      .finally(() => setIsLoading2(false));
  };

  const RegiBtn = () => {
    if (reportMonth == "") {
      document.getElementById("reportMonthErr").style.display = "block";
      document.getElementById("reportMonth").style.borderColor = "red";
      errMsg = 1;
    } else {
      document.getElementById("reportMonthErr").style.display = "none";
      document.getElementById("reportMonth").style.borderColor = "lightgray";
    }

    if (projectId == "") {
      document.getElementById("projectErr").style.display = "block";
      errMsg = 1;
    } else {
      document.getElementById("projectErr").style.display = "none";
    }

    if (errMsg == 0) {
      axios
        .post("https://www.bdc.et/bdc-api/post_month_financial_report.php", {
          empId: empId,
          reportMonth: reportMonth,
          projectId: projectId,
          project: project,
          prevPlan: prevPlan,
          thisPlan: thisPlan,
          toThisPlan: toThisPlan,
          prevExe: prevExe,
          thisExe: thisExe,
          toThisExe: toThisExe,
        })
        .then((res) => {
          setData(res.data);
          setProjectId("");
          setProject("");
          setPrevPlan("");
          setThisPlan("");
          setToThisPlan("");
          setPrevExe("");
          setThisExe("");
          setToThisExe("");
          document.getElementById("performance1").value = "";
          document.getElementById("performance2").value = "";
          document.getElementById("performance3").value = "";
          document.getElementById("successMsg").style.display = "block";
        });
    }
  };

  return (
    <>
      {tab.includes("tab11") && (
        <>
          {openDialog ? (
            <>
              <div className="dialog-ext"></div>
              <div style={{ width: "70%", zIndex: 2, position: "absolute" }}>
                <div
                  className="dialog-cont"
                  style={{
                    maxHeight: "600px",
                    overflowY: "auto",
                    scrollbarWidth: "thin",
                    width: "550px",
                  }}
                >
                  <div
                    className="brdr-btm"
                    style={{ display: "inline-flex", width: "100%" }}
                  >
                    <h2 style={{ marginLeft: "20px", color: "gray" }}>
                      የፕሮጀክት ዝርዝር
                    </h2>
                    <input
                      type="button"
                      id="register"
                      value="Cancel"
                      className="btn1"
                      style={{
                        margin: "auto",
                        width: "70px",
                        float: "right",
                      }}
                      onClick={() => setOpenDialog(false)}
                    />
                  </div>
                  {isLoading2 ? (
                    <div style={{ width: "100%", textAlign: "center" }}>
                      <img
                        src={loading2}
                        alt="loading..."
                        style={{ width: "50px", opacity: "0.3" }}
                      />
                    </div>
                  ) : (
                    <>
                      {dialog != "" ? (
                        dialog.map((diag) => (
                          <div
                            className="brdr-btm list-hover"
                            key={diag[0]}
                            style={{
                              cursor: "pointer",
                              width: "100%",
                            }}
                          >
                            <div
                              style={{
                                padding: "10px 20px",
                                display: "inline-flex",
                              }}
                            >
                              <h3
                                onClick={() => {
                                  setProject(diag[1] + ", " + diag[2]);
                                  setProjectId(diag[0]);
                                  setOpenDialog(false);
                                }}
                                style={{
                                  width: "100%",
                                  margin: 0,
                                  color: "black",
                                  fontWeight: "normal",
                                  marginLeft: "5px",
                                }}
                              >
                                {diag[1] + ", " + diag[2]}
                              </h3>
                            </div>
                          </div>
                        ))
                      ) : (
                        <p style={{ marginLeft: "40px" }}>No record</p>
                      )}
                    </>
                  )}
                </div>
              </div>
            </>
          ) : (
            ""
          )}

          <div
            className="cont-all"
            style={{
              width: "90%",
              height: "95%",
              padding: "0",
              overflow: "auto",
              scrollbarWidth: "thin",
            }}
          >
            <div
              style={{
                width: "100%",
                backgroundColor: "rgb(240,240,240)",
                margin: "0",
                display: "inline-flex",
                position: "sticky",
                top: 0,
              }}
            >
              <h5
                style={{
                  margin: "0",
                  color: "black",
                  borderColor: "gray",
                  borderRight: "solid",
                  borderWidth: "1px",
                  padding: "10px 20px",
                }}
              >
                የወርሃዊ ፋይናንሻል ሪፖርት መመዝገቢያ ገፅ
              </h5>
            </div>
            <div
              style={{
                minWidth: "400px",
                width: "100%",
                display: "inline-flex",
                flexWrap: "wrap",
              }}
            >
              <div
                className="brdr-btm"
                style={{
                  width: "100%",
                  minWidth: "300px",
                }}
              >
                <div
                  style={{
                    marginLeft: "20px",
                    width: "80%",
                    minWidth: "250px",
                  }}
                >
                  <p
                    className="reg-err"
                    id="successMsg"
                    style={{
                      color: "green",
                      display: "none",
                      marginTop: "10px",
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    በተሳካ ሁኔታ ተመዝግቧል!
                  </p>
                  <p className="reg-err" id="reportMonthErr">
                    *ይህ መረጃ አስፈላጊ ነው!
                  </p>
                  <label style={{ fontSize: "12px" }}>የሪፖርት ወር</label>
                  <br />
                  <input
                    style={{ width: "27%", minWidth: "200px" }}
                    className="txt-input"
                    id="reportMonth"
                    type="month"
                    onChange={(e) => {
                      setReportMonth(e.target.value);

                      e.target.value != "" &&
                        axios
                          .post(
                            "https://www.bdc.et/bdc-api/get_month_financial_report.php",
                            {
                              reportMonth: e.target.value,
                            }
                          )
                          .then((res) => {
                            setData(res.data);
                          });
                    }}
                    required
                  />
                  <br />
                  <p className="reg-err" id="projectErr">
                    *ይህ መረጃ አስፈላጊ ነው!
                  </p>
                  <input
                    type="button"
                    id="project"
                    value={project != "" ? "ፕሮጀክት ለመቀየር" : "ፕሮጀክት ለመምረጥ"}
                    style={{ fontSize: "12px", margin: "10px 0" }}
                    onClick={OpenDialog}
                  />
                  <br />
                  <p style={{ fontSize: "13px" }}>{project}</p>
                </div>
              </div>
              <div
                style={{
                  width: "30%",
                  minWidth: "300px",
                }}
              >
                <div
                  style={{
                    marginLeft: "20px",
                    width: "80%",
                    minWidth: "250px",
                  }}
                >
                  <p style={{ fontSize: "12px" }}>እስከ በፊት ወር ሪፖርት (ብር)</p>
                  <input
                    className="txt-input"
                    id="prevPlan"
                    type="number"
                    value={prevPlan ? prevPlan : ""}
                    onChange={(e) => {
                      setPrevPlan(e.target.value);
                      setToThisPlan(
                        parseFloat(e.target.value ? e.target.value : 0) +
                          parseFloat(thisPlan ? thisPlan : 0)
                      );
                      if (e.target.value == "" || prevExe == "") {
                        document.getElementById("performance1").value =
                          "0.00 %";
                      } else {
                        document.getElementById("performance1").value =
                          (
                            (parseFloat(prevExe) / parseFloat(e.target.value)) *
                            100
                          ).toFixed(2) + " %";
                      }
                    }}
                    onBlur={(e) => {
                      if (e.target.value <= 0) {
                        e.target.value = "";
                        setPrevPlan("");
                      }
                    }}
                    placeholder="እስከ በፊት ወር እቅድ (ብር)"
                    required
                  />
                  <input
                    className="txt-input"
                    id="prevPlan"
                    type="number"
                    value={prevExe ? prevExe : ""}
                    onChange={(e) => {
                      setPrevExe(e.target.value);
                      setToThisExe(
                        parseFloat(e.target.value ? e.target.value : 0) +
                          parseFloat(thisExe ? thisExe : 0)
                      );

                      if (e.target.value == "" || prevPlan == "") {
                        document.getElementById("performance1").value =
                          "0.00 %";
                      } else {
                        document.getElementById("performance1").value =
                          (
                            (parseFloat(e.target.value) /
                              parseFloat(prevPlan)) *
                            100
                          ).toFixed(2) + " %";
                      }
                    }}
                    onBlur={(e) => {
                      if (e.target.value <= 0) {
                        e.target.value = "";
                        setPrevExe("");
                      }
                    }}
                    placeholder="እስከ በፊት ወር ክንውን (ብር)"
                    required
                  />
                  <br />
                  <label style={{ fontSize: "12px" }}>አፈፃፀም</label>
                  <br />
                  <input
                    className="txt-input"
                    id="performance1"
                    type="text"
                    placeholder="0 %"
                    onChange={(e) =>
                      e.target.value ? parseFloat(e.target.value) : "0"
                    }
                    disabled
                  />
                  <br />
                </div>
              </div>
              <div
                style={{
                  width: "30%",
                  minWidth: "300px",
                }}
              >
                <div
                  style={{
                    marginLeft: "20px",
                    width: "80%",
                    minWidth: "250px",
                  }}
                >
                  <p style={{ fontSize: "12px" }}>የዚህ ወር ሪፖርት (ብር)</p>
                  <input
                    className="txt-input"
                    id="prevPlan"
                    type="number"
                    value={thisPlan ? thisPlan : ""}
                    onChange={(e) => {
                      setThisPlan(e.target.value);
                      setToThisPlan(
                        parseFloat(e.target.value ? e.target.value : 0) +
                          parseFloat(prevPlan ? prevPlan : 0)
                      );

                      if (e.target.value == "" || thisExe == "") {
                        document.getElementById("performance2").value =
                          "0.00 %";
                      } else {
                        document.getElementById("performance2").value =
                          (
                            (parseFloat(thisExe) / parseFloat(e.target.value)) *
                            100
                          ).toFixed(2) + " %";
                      }
                    }}
                    onBlur={(e) => {
                      if (e.target.value <= 0) {
                        e.target.value = "";
                        setThisPlan("");
                      }
                    }}
                    placeholder="የዚህ ወር እቅድ (ብር)"
                    required
                  />
                  <input
                    className="txt-input"
                    id="prevPlan"
                    type="number"
                    value={thisExe ? thisExe : ""}
                    onChange={(e) => {
                      setThisExe(e.target.value);
                      setToThisExe(
                        parseFloat(e.target.value ? e.target.value : 0) +
                          parseFloat(prevExe ? prevExe : 0)
                      );

                      if (e.target.value == "" || thisPlan == "") {
                        document.getElementById("performance2").value =
                          "0.00 %";
                      } else {
                        document.getElementById("performance2").value =
                          (
                            (parseFloat(e.target.value) /
                              parseFloat(thisPlan)) *
                            100
                          ).toFixed(2) + " %";
                      }
                    }}
                    onBlur={(e) => {
                      if (e.target.value <= 0) {
                        e.target.value = "";
                        setThisExe("");
                      }
                    }}
                    placeholder="የዚህ ወር ክንውን (ብር)"
                    required
                  />
                  <br />
                  <label style={{ fontSize: "12px" }}>አፈፃፀም</label>
                  <br />
                  <input
                    className="txt-input"
                    id="performance2"
                    type="text"
                    placeholder="0 %"
                    onChange={(e) =>
                      e.target.value ? parseFloat(e.target.value) : "0"
                    }
                    disabled
                  />
                  <br />
                </div>
              </div>
              <div
                style={{
                  width: "30%",
                  minWidth: "300px",
                }}
              >
                <div
                  style={{
                    marginLeft: "20px",
                    width: "80%",
                    minWidth: "250px",
                  }}
                >
                  <p style={{ fontSize: "12px" }}>የእስከ አሁን ወር ሪፖርት (ብር)</p>
                  <input
                    className="txt-input"
                    id="toThisPlan"
                    type="number"
                    value={toThisPlan ? toThisPlan : ""}
                    onChange={(e) => {
                      setToThisPlan(e.target.value);

                      if (e.target.value == "" || toThisExe == "") {
                        document.getElementById("performance3").value =
                          "0.00 %";
                      } else {
                        document.getElementById("performance3").value =
                          (
                            (parseFloat(toThisExe) /
                              parseFloat(e.target.value)) *
                            100
                          ).toFixed(2) + " %";
                      }
                    }}
                    onBlur={(e) => {
                      if (e.target.value <= 0) {
                        e.target.value = "";
                        setToThisPlan("");
                      }
                    }}
                    placeholder="የእስከ አሁን ወር እቅድ (ብር)"
                    disabled
                  />
                  <input
                    className="txt-input"
                    id="toThisPlan"
                    type="number"
                    value={toThisExe ? toThisExe : ""}
                    onChange={(e) => {
                      setToThisExe(e.target.value)
                    }}
                    onBlur={(e) => {
                      if (e.target.value <= 0) {
                        e.target.value = "";
                        setToThisExe("");
                      }
                    }}
                    placeholder="የእስከ አሁን ወር ክንውን (ብር)"
                    disabled
                  />
                  <br />
                  <label style={{ fontSize: "12px" }}>አፈፃፀም</label>
                  <br />
                  <input
                    className="txt-input"
                    id="performance3"
                    value={
                      parseFloat(toThisPlan) == 0
                        ? 0 + " %"
                        : ((parseFloat(toThisExe) / parseFloat(toThisPlan)) * 100).toFixed(2) + " %"
                    }
                    onChange={(e) =>
                      e.target.value ? parseFloat(e.target.value) : "0"
                    }
                    type="text"
                    placeholder="0 %"
                    disabled
                  />
                  <br />
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  minWidth: "300px",
                }}
              >
                <div
                  style={{
                    marginLeft: "20px",
                    width: "80%",
                    minWidth: "250px",
                  }}
                >
                  <div style={{ display: "inline-flex", marginBottom: "5px" }}>
                    <input
                      type="button"
                      value={"መዝግብ"}
                      className="btn1"
                      style={{ width: "150px" }}
                      onClick={RegiBtn}
                    />
                  </div>
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  minWidth: "600px",
                }}
              >
                <table
                  style={{
                    width: "100%",
                    marginTop: "20px",
                  }}
                >
                  <tr
                    style={{
                      width: "100%",
                      textAlign: "left",
                      color: "gray",
                    }}
                  >
                    <th>ፕሮጀክት</th>
                    <th>የበፊት እቅድ (ብር) </th>
                    <th>የበፊት ክንውን (ብር) </th>
                    <th>አፈፃፀም(%)</th>
                    <th>የአሁን እቅድ (ብር) </th>
                    <th>የአሁን ክንውን (ብር) </th>
                    <th>አፈፃፀም(%)</th>
                    <th>የእስከ አሁን እቅድ (ብር) </th>
                    <th>የእስከ አሁን ክንውን (ብር) </th>
                    <th>አፈፃፀም(%)</th>
                  </tr>
                  {isLoading3 ? (
                    <div style={{ width: "100%", textAlign: "center" }}>
                      <img
                        src={loading2}
                        alt="loading..."
                        style={{ width: "50px", opacity: "0.3" }}
                      />
                    </div>
                  ) : (
                    <>
                      {data != [] &&
                        data.map((list) => (
                          <tr key={list[0]}>
                            <td>{list[3]}</td>
                            <td> {parseFloat(list[4]).toLocaleString(2)}</td>
                            <td> {parseFloat(list[7]).toLocaleString(2)}</td>
                            <td style={{backgroundColor:"rgb(240,240,240)"}}>
                              {(
                                (parseFloat(list[7]) / parseFloat(list[4])) *
                                100
                              ).toFixed(2)}
                            </td>
                            <td> {parseFloat(list[5]).toLocaleString(2)}</td>
                            <td> {parseFloat(list[8]).toLocaleString(2)}</td>
                            <td style={{backgroundColor:"rgb(240,240,240)"}}>
                              {(
                                (parseFloat(list[8]) / parseFloat(list[5])) *
                                100
                              ).toFixed(2)}
                            </td>
                            <td> {parseFloat(list[6]).toLocaleString(2)}</td>
                            <td> {parseFloat(list[9]).toLocaleString(2)}</td>
                            <td style={{backgroundColor:"rgb(240,240,240)"}}>
                              {(
                                (parseFloat(list[9]) / parseFloat(list[6])) *
                                100
                              ).toFixed(2)}
                            </td>
                          </tr>
                        ))}
                    </>
                  )}
                </table>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default MonthFinancialReportSend;
