import axios from "axios";
import { React, useState, useEffect } from "react";
import loading2 from "../img/loading2.gif";
import moment from "moment";
import { expSettleAction } from "./state/expenseSlice";
import caIcon2 from "../img/ca-icon2.png";
import { useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import hrIcon from "../img/hr-icon.png";
import caIcon from "../img/ca-icon.png";
import csIcon from "../img/cs-icon.png";
import plIcon from "../img/pl-icon.png";
import meIcon from "../img/me-icon.png";
import fIcon from "../img/f-icon.png";

function Home() {
  const navigate = useNavigate();
  const tab = useSelector((state) => state.auth.tab);

  useEffect(() => {
    document.getElementById("windowTitle").textContent = "/ኮንትራት አስተዳድር";
  }, []);

  return (
    <>
      <div
        style={{
          width: "40%",
          height: "97%",
          minWidth: "300px",
          paddingTop: "0",
          overflow: "auto",
          scrollbarWidth: "thin",
        }}
      >
        <div
          style={{
            width: "100%",
            minWidth: "300px",
            paddingTop: "0",
            display: "inline-flex",
            flexWrap: "wrap",
          }}
        >
          <div
            style={{
              width: "50%",
              minWidth: "200px",
              textAlign: "center",
            }}
          >
            {(tab.includes("tab11") || tab.includes("tab12")) && (
              <div
                className="dep-icon-cont"
                onClick={() => navigate("/human_resource")}
              >
                <img src={hrIcon} className="dep-icon-img" alt="hr-icon" />
                <br />
                <div style={{ fontSize: "12px" }}>የሰው ሃይል</div>
                <br />
              </div>
            )}
          </div>
          <div
            style={{
              width: "50%",
              minWidth: "200px",
              textAlign: "center",
            }}
          >
            {(tab.includes("tab11") || tab.includes("tab12")) && (
              <div
                className="dep-icon-cont"
                onClick={() => navigate("/contract_administration")}
              >
                <img src={caIcon} className="dep-icon-img" alt="ca-icon" />
                <br />
                <div style={{ fontSize: "12px" }}>ኮንትራት አስተዳድር</div>
                <br />
              </div>
            )}
          </div>
          <div
            style={{
              width: "50%",
              minWidth: "200px",
              textAlign: "center",
            }}
          >
            {(tab.includes("tab11") || tab.includes("tab12")) && (
              <div
                className="dep-icon-cont"
                onClick={() => navigate("/construction_supervision")}
              >
                <img src={csIcon} className="dep-icon-img" alt="cs-icon" />
                <br />
                <div style={{ fontSize: "12px" }}>ግንባታ ክትትል</div>
                <br />
              </div>
            )}
          </div>
          <div
            style={{
              width: "50%",
              minWidth: "200px",
              textAlign: "center",
            }}
          >
            {(tab.includes("tab11") || tab.includes("tab12")) && (
              <div
                className="dep-icon-cont"
                onClick={() => navigate("/human_resource")}
              >
                <img src={plIcon} className="dep-icon-img" alt="pl-icon" />
                <br />
                <div style={{ fontSize: "12px" }}>ግዥና አቅርቦት</div>
                <br />
              </div>
            )}
          </div>
          <div
            style={{
              width: "50%",
              minWidth: "200px",
              textAlign: "center",
            }}
          >
            {(tab.includes("tab11") || tab.includes("tab12")) && (
              <div
                className="dep-icon-cont"
                onClick={() => navigate("/human_resource")}
              >
                <img src={meIcon} className="dep-icon-img" alt="me-icon" />
                <br />
                <div style={{ fontSize: "12px" }}>ማሽነሪና ተሸከርካሪ</div>
                <br />
              </div>
            )}
          </div>
          <div
            style={{
              width: "50%",
              minWidth: "200px",
              textAlign: "center",
            }}
          >
            {(tab.includes("tab11") || tab.includes("tab12")) && (
              <div
                className="dep-icon-cont"
                onClick={() => navigate("/human_resource")}
              >
                <img src={fIcon} className="dep-icon-img" alt="f-icon" />
                <br />
                <div style={{ fontSize: "12px" }}>ፋይናንስ</div>
                <br />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
