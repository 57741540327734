import axios from "axios";
import { React, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import loading2 from "../img/loading2.gif";

function ProjectList() {
  const [isLoading, setIsLoading] = useState(false);
  const tab = useSelector((state) => state.auth.tab);
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    document.getElementById("windowTitle").textContent =
      "/ፕሮጀክት /የፕሮጀክት ዝርዝር";
    setIsLoading(true);
    axios
      .post("https://www.bdc.et/bdc-api/get_project_list.php", {})
      .then((res) => setData(res.data))
      .catch((err) => alert(err))
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <>
      {tab.includes("tab21") && (
        <>
          <div
            className="cont-all"
            style={{
              width: "100%",
              minWidth: "800px",
              height: "97%",
              paddingTop: "0",
              overflow: "auto",
            }}
          >
            <div
              style={{
                width: "100%",
                backgroundColor: "rgb(240,240,240)",
                margin: "0",
                display: "inline-flex",
                position: "sticky",
                top: 0,
                height: "45px",
              }}
            >
              <h5
                style={{
                  margin: "0",
                  color: "black",
                  padding: "10px 20px",
                }}
              >
                የፕሮጀክት ዝርዝር
              </h5>
            </div>
            <p style={{margin:"0 20px"}}>{"[ "+data.length + " Records ]"}</p>
            <table
              style={{
                width: "100%",
                minWidth: "600px",
              }}
            >
              <tr
                style={{
                  width: "100%",
                  textAlign: "left",
                  color: "gray",
                  position: "sticky",
                  top: 46,
                }}
              >
                <th>የፕሮጀክት ስም</th>
                <th>የፕሮጀክት ቦታ</th>
                <th>ባለቤት</th>
                <th>አማካሪ</th>
                <th>የውል መጠን</th>
                <th>ሁኔታ</th>
                <th></th>
              </tr>
              {isLoading ? (
                <div style={{ width: "100%", textAlign: "center" }}>
                  <img
                    src={loading2}
                    alt="loading..."
                    style={{ width: "50px", opacity: "0.3" }}
                  />
                </div>
              ) : (
                <>
                  {data != [] &&
                    data.map((list) => (
                      <tr key={list[0]}>
                        <td> {list[1]}</td>
                        <td> {list[2]}</td>
                        <td> {list[3]}</td>
                        <td> {list[4]}</td>
                        <td> {parseFloat(list[5]).toLocaleString()}</td>
                        <td> {list[11]}</td>
                        <td>
                          <input
                            style={{ cursor: "pointer",fontSize: "12px" }}
                            type="button"
                            value="ክፈት"
                            onClick={() => {
                              navigate("/project_detail", {
                                state: {
                                  id: list[0],
                                  idNumber: list[8],
                                },
                              });
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                </>
              )}
            </table>
          </div>
        </>
      )}
    </>
  );
}

export default ProjectList;
