import axios from "axios";
import { React, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import loading1 from "../img/loading1.gif";

function EmpRole() {
  const [isLoading, setIsLoading] = useState(false);
  const tab = useSelector((state) => state.auth.tab);
  const location = useLocation();
  const empId = location.state.id;
  const idNumber = location.state.idNumber;
  const [tab11, setTab11] = useState(false);
  const [tab12, setTab12] = useState(false);
  const [tab21, setTab21] = useState(false);
  const [tab31, setTab31] = useState(false);
  const [tab32, setTab32] = useState(false);
  const [tab33, setTab33] = useState(false);
  const [tab41, setTab41] = useState(false);
  const [tab42, setTab42] = useState(false);
  const [tab51, setTab51] = useState(false);
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    document.getElementById("windowTitle").textContent =
      "/የሰው ሃይል /የስራ ድርሻ ምዝገባ";

    empId != "" &&
      axios
        .post("https://www.bdc.et/bdc-api/get_employee_id.php", {
          empId: empId,
        })
        .then((res) => {
          setData(res.data[0]);
        });

    empId != "" &&
      axios
        .post("https://www.bdc.et/bdc-api/get_employee_role.php", {
          id: empId,
        })
        .then((res2) => {
          if (res2.data) {
            setData2(res2.data);
          }
        });
  }, []);

  const AddRole = (tab, subTab) => {
    if (empId == "") {
    } else {
      axios
        .post("https://www.bdc.et/bdc-api/post_add_employee_role.php", {
          empId: empId,
          tab: tab,
          subTab: subTab,
        })
        .then((res2) => {
          if (res2.data) {
            setData2(res2.data);
          }
        });
    }
  };

  const DeleteRole = (subTab) => {
    if (empId == "") {
    } else {
      axios
        .post("https://www.bdc.et/bdc-api/post_delete_employee_role.php", {
          empId: empId,
          subTab: subTab,
        })
        .then((res2) => {
          if (res2.data) {
            setData2(res2.data);
          }
        });
    }
  };

  return (
    <>
      {tab.includes("tab11") && (
        <div
          className="cont-all"
          style={{
            width: "40%",
            minWidth: "300px",
            height: "95%",
            padding: "0",
            overflow: "auto",
            scrollbarWidth: "thin",
          }}
        >
          <div
            style={{
              width: "100%",
              backgroundColor: "rgb(240,240,240)",
              margin: "0",
              display: "inline-flex",
              position: "sticky",
              top: 0,
            }}
          >
            <h5
              style={{
                margin: "0",
                color: "black",
                borderColor: "gray",
                borderRight: "solid",
                borderWidth: "1px",
                padding: "10px 20px",
              }}
            >
              የሰራተኛ የስራ ድርሻ ምዝገባ
            </h5>
          </div>
          <div>
            <div className="brdr-btm" style={{ width: "100%" }}>
              {data[0] && (
                <div style={{ display: "inline-flex" }}>
                  <img
                    src={
                      "https://www.bdc.et/bdc-api/uploads/profile-pictures/" +
                      data[17]
                    }
                    style={{
                      width: "50px",
                      height: "50px",
                      margin: "10px",
                      borderRadius: "50%",
                    }}
                    alt="hr-icon"
                  />
                  <div>
                    <p style={{ fontSize: "14px" }}>
                      {data[1] + " " + data[2] + " " + data[3]}
                    </p>
                    <p
                      style={{
                        fontSize: "14px",
                        color: "gray",
                        fontWeight: "bold",
                      }}
                    >
                      {data[11]}
                    </p>
                  </div>
                </div>
              )}
              <br />
              <input
                type="button"
                value={empId == "" ? "ሰራተኛ ለመምረጥ" : "ሰራተኛ ለመቀየር"}
                style={{ margin: "10px" }}
                onClick={() =>
                  navigate("/employee_list", {
                    state: {
                      fromPage: "employee_role",
                    },
                  })
                }
              />
            </div>

            <h5 style={{ color: "gray", marginLeft: "30px" }}>የሰው ሃይል</h5>
            <div
              style={{
                margin: "0 10px",
                fontSize: "12px",
              }}
            >
              <label>
                <input
                  type="checkbox"
                  name="list"
                  checked={data2.includes("tab11") ? true : false}
                  onClick={(e) => {
                    e.target.checked
                      ? AddRole("tab1", "tab11")
                      : DeleteRole("tab11");
                  }}
                />
                የሰራተኛ ምዝገባ
              </label>
            </div>
            <br />
            <div
              style={{
                margin: "0 10px",
                fontSize: "12px",
              }}
            >
              <label>
                <input
                  type="checkbox"
                  name="list"
                  checked={data2.includes("tab12") ? true : false}
                  onClick={(e) => {
                    e.target.checked
                      ? AddRole("tab1", "tab12")
                      : DeleteRole("tab12");
                  }}
                />
                የሰራተኛ ዝርዝር
              </label>
            </div>
            <br />
            <div
              style={{
                margin: "0 10px",
                fontSize: "12px",
              }}
            >
              <label>
                <input
                  type="checkbox"
                  name="list"
                  checked={data2.includes("tab13") ? true : false}
                  onClick={(e) => {
                    e.target.checked
                      ? AddRole("tab1", "tab13")
                      : DeleteRole("tab13");
                  }}
                />
                የስራ ድርሻ ምዝገባ
              </label>
            </div>
            <br />
            <div
              style={{
                margin: "0 10px",
                fontSize: "12px",
              }}
            >
              <label>
                <input
                  type="checkbox"
                  name="list"
                  checked={data2.includes("tab14") ? true : false}
                  onClick={(e) => {
                    e.target.checked
                      ? AddRole("tab1", "tab14")
                      : DeleteRole("tab14");
                  }}
                />
                የስራ ልምድ ምዝገባ
              </label>
            </div>
            <br />
            <div
              style={{
                margin: "0 10px",
                fontSize: "12px",
              }}
            >
              <label>
                <input
                  type="checkbox"
                  name="list"
                  checked={data2.includes("tab15") ? true : false}
                  onClick={(e) => {
                    e.target.checked
                      ? AddRole("tab1", "tab15")
                      : DeleteRole("tab15");
                  }}
                />
                የክፍያ ሁኔታ
              </label>
            </div>
            <br />
            <div
              style={{
                margin: "0 10px",
                fontSize: "12px",
              }}
            >
              <label>
                <input
                  type="checkbox"
                  name="list"
                  checked={data2.includes("tab16") ? true : false}
                  onClick={(e) => {
                    e.target.checked
                      ? AddRole("tab1", "tab16")
                      : DeleteRole("tab16");
                  }}
                />
                የፍቃድ ምዝገባ
              </label>
            </div>
            <br />
            <div
              style={{
                margin: "0 10px",
                fontSize: "12px",
              }}
            >
              <label>
                <input
                  type="checkbox"
                  name="list"
                  checked={data2.includes("tab17") ? true : false}
                  onClick={(e) => {
                    e.target.checked
                      ? AddRole("tab1", "tab17")
                      : DeleteRole("tab17");
                  }}
                />
                ፍቃድ መጠየቅ
              </label>
            </div>
            <br />
            <h5 style={{ color: "gray", marginLeft: "30px" }}>ኮንትራት አስተዳድር</h5>
            <div
              style={{
                margin: "0 10px",
                fontSize: "12px",
              }}
            >
              <label>
                <input
                  type="checkbox"
                  name="list"
                  checked={data2.includes("tab21") ? true : false}
                  onClick={(e) => {
                    e.target.checked
                      ? AddRole("tab2", "tab21")
                      : DeleteRole("tab21");
                  }}
                />
                ፕሮጀክት ምዝገባ
              </label>
            </div>
            <br />
            <div
              style={{
                margin: "0 10px",
                fontSize: "12px",
              }}
            >
              <label>
                <input
                  type="checkbox"
                  name="list"
                  checked={data2.includes("tab22") ? true : false}
                  onClick={(e) => {
                    e.target.checked
                      ? AddRole("tab2", "tab22")
                      : DeleteRole("tab22");
                  }}
                />
                የፕሮጀክት ዝርዝር
              </label>
            </div>
            <br />
            <div
              style={{
                margin: "0 10px",
                fontSize: "12px",
              }}
            >
              <label>
                <input
                  type="checkbox"
                  name="list"
                  checked={data2.includes("tab23") ? true : false}
                  onClick={(e) => {
                    e.target.checked
                      ? AddRole("tab2", "tab23")
                      : DeleteRole("tab23");
                  }}
                />
                ግብአት ማረጋገጥ
              </label>
            </div>
            <br />
            <h5 style={{ color: "gray", marginLeft: "30px" }}>ግንባታ ክትትል</h5>
            <div
              style={{
                margin: "0 10px",
                fontSize: "12px",
              }}
            >
              <label>
                <input
                  type="checkbox"
                  name="list"
                  checked={data2.includes("tab31") ? true : false}
                  onClick={(e) => {
                    e.target.checked
                      ? AddRole("tab3", "tab31")
                      : DeleteRole("tab31");
                  }}
                />
                ግብአት መጠየቅ
              </label>
            </div>
            <br />
            <div
              style={{
                margin: "0 10px",
                fontSize: "12px",
              }}
            >
              <label>
                <input
                  type="checkbox"
                  name="list"
                  checked={data2.includes("tab32") ? true : false}
                  onClick={(e) => {
                    e.target.checked
                      ? AddRole("tab3", "tab32")
                      : DeleteRole("tab32");
                  }}
                />
                የተጠየቁ ግብአቶች ዝርዝር
              </label>
            </div>
            <br />
            <h5 style={{ color: "gray", marginLeft: "30px" }}>ግዥና አቅርቦት</h5>
            <div
              style={{
                margin: "0 10px",
                fontSize: "12px",
              }}
            >
              <label>
                <input
                  type="checkbox"
                  name="list"
                  checked={data2.includes("tab41") ? true : false}
                  onClick={(e) => {
                    e.target.checked
                      ? AddRole("tab4", "tab41")
                      : DeleteRole("tab41");
                  }}
                />
                የተጠየቁ ግብአቶች ዝርዝር
              </label>
            </div>
            <br />
            <div
              style={{
                margin: "0 10px",
                fontSize: "12px",
              }}
            >
              <label>
                <input
                  type="checkbox"
                  name="list"
                  checked={data2.includes("tab42") ? true : false}
                  onClick={(e) => {
                    e.target.checked
                      ? AddRole("tab4", "tab42")
                      : DeleteRole("tab42");
                  }}
                />
                ገንዘብ መጠየቅ
              </label>
            </div>
            <br />
            <div
              style={{
                margin: "0 10px",
                fontSize: "12px",
              }}
            >
              <label>
                <input
                  type="checkbox"
                  name="list"
                  checked={data2.includes("tab43") ? true : false}
                  onClick={(e) => {
                    e.target.checked
                      ? AddRole("tab4", "tab43")
                      : DeleteRole("tab43");
                  }}
                />
                የተጠየቁ ገንዘቦች ዝርዝር
              </label>
            </div>
            <br />
            <h5 style={{ color: "gray", marginLeft: "30px" }}>ማሽነሪና ተሸከርካሪ</h5>
            <div
              style={{
                margin: "0 10px",
                fontSize: "12px",
              }}
            >
              <label>
                <input
                  type="checkbox"
                  name="list"
                  checked={data2.includes("tab51") ? true : false}
                  onClick={(e) => {
                    e.target.checked
                      ? AddRole("tab5", "tab51")
                      : DeleteRole("tab51");
                  }}
                />
                ግብአት መጠየቅ
              </label>
            </div>
            <br />
            <div
              style={{
                margin: "0 10px",
                fontSize: "12px",
              }}
            >
              <label>
                <input
                  type="checkbox"
                  name="list"
                  checked={data2.includes("tab52") ? true : false}
                  onClick={(e) => {
                    e.target.checked
                      ? AddRole("tab5", "tab52")
                      : DeleteRole("tab52");
                  }}
                />
                የተጠየቁ ግብአቶች ዝርዝር
              </label>
            </div>
            <br />
            <h5 style={{ color: "gray", marginLeft: "30px" }}>ፋይናንስ</h5>
            <div
              style={{
                margin: "0 10px",
                fontSize: "12px",
              }}
            >
              <label>
                <input
                  type="checkbox"
                  name="list"
                  checked={data2.includes("tab61") ? true : false}
                  onClick={(e) => {
                    e.target.checked
                      ? AddRole("tab6", "tab61")
                      : DeleteRole("tab61");
                  }}
                />
               የተጠየቁ ገንዘቦች ዝርዝር
              </label>
            </div>
            <br />
            <div
              style={{
                margin: "0 10px",
                fontSize: "12px",
              }}
            >
              <label>
                <input
                  type="checkbox"
                  name="list"
                  checked={data2.includes("tab62") ? true : false}
                  onClick={(e) => {
                    e.target.checked
                      ? AddRole("tab6", "tab62")
                      : DeleteRole("tab62");
                  }}
                />
                ገንዘብ መላክ
              </label>
            </div>
            <br />
            <div
              style={{
                margin: "0 10px",
                fontSize: "12px",
              }}
            >
              <label>
                <input
                  type="checkbox"
                  name="list"
                  checked={data2.includes("tab63") ? true : false}
                  onClick={(e) => {
                    e.target.checked
                      ? AddRole("tab6", "tab63")
                      : DeleteRole("tab63");
                  }}
                />
                የተላኩ ገንዘቦች ዝርዝር
              </label>
            </div>
            <br />
            <div
              style={{
                margin: "0 10px",
                fontSize: "12px",
              }}
            >
              <label>
                <input
                  type="checkbox"
                  name="list"
                  checked={data2.includes("tab64") ? true : false}
                  onClick={(e) => {
                    e.target.checked
                      ? AddRole("tab6", "tab64")
                      : DeleteRole("tab64");
                  }}
                />
                የወጭ ሪፖርት
              </label>
            </div>
            <br />
          </div>
        </div>
      )}
    </>
  );
}

export default EmpRole;
