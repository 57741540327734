import axios from "axios";
import { React, useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import loading2 from "../img/loading2.gif";
import moment from "moment";
import { parse } from "@fortawesome/fontawesome-svg-core";

function PaymentAdvance() {
  const tab = useSelector((state) => state.auth.tab);
  const empId = useSelector((state) => state.auth.empId);
  const [requestDate, setRequestDate] = useState("");
  const [project, setProject] = useState("");
  const [projectId, setProjectId] = useState("");
  const [contractAmt, setContractAmt] = useState(0);
  const [requestPercent, setRequestPercent] = useState(0);
  const [payDate, setPayDate] = useState("");
  const [payAmt, setPayAmt] = useState(0);
  let errMsg = 0;

  const [openDialog, setOpenDialog] = useState(false);
  const [dialog, setDialog] = useState([]);
  const [isLoading2, setIsLoading2] = useState(false);
  const [isLoading3, setIsLoading3] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    document.getElementById("windowTitle").textContent =
      "/የኮንትራት አስተዳድር /አድቫንስ ክፍያ";
  }, []);

  const OpenDialog = () => {
    setOpenDialog(true);
    setIsLoading2(true);
    axios
      .post("https://www.bdc.et/bdc-api/get_project_list.php", {})
      .then((res) => {
        setDialog(res.data);
      })
      .catch((err) => alert(err))
      .finally(() => setIsLoading2(false));
  };

  const RegiBtn = () => {
    if (requestDate == "") {
      document.getElementById("requestDateErr").style.display = "block";
      document.getElementById("requestDate").style.borderColor = "red";
      errMsg = 1;
    } else {
      document.getElementById("requestDateErr").style.display = "none";
      document.getElementById("requestDate").style.borderColor = "lightgray";
    }

    if (projectId == "") {
      document.getElementById("projectErr").style.display = "block";
      errMsg = 1;
    } else {
      document.getElementById("projectErr").style.display = "none";
    }

    if (contractAmt == "") {
      document.getElementById("contractAmtErr").style.display = "block";
      document.getElementById("contractAmt").style.borderColor = "red";
      errMsg = 1;
    } else {
      document.getElementById("contractAmtErr").style.display = "none";
      document.getElementById("contractAmt").style.borderColor = "lightgray";
    }

    if (requestPercent == "") {
      document.getElementById("requestPercentErr").style.display = "block";
      document.getElementById("requestPercent").style.borderColor = "red";
      errMsg = 1;
    } else {
      document.getElementById("requestPercentErr").style.display = "none";
      document.getElementById("requestPercent").style.borderColor = "lightgray";
    }

    if (payDate == "") {
      document.getElementById("payDateErr").style.display = "block";
      document.getElementById("payDate").style.borderColor = "red";
      errMsg = 1;
    } else {
      document.getElementById("payDateErr").style.display = "none";
      document.getElementById("payDate").style.borderColor = "lightgray";
    }

    if (payAmt == "") {
      document.getElementById("payAmtErr").style.display = "block";
      document.getElementById("payAmt").style.borderColor = "red";
      errMsg = 1;
    } else {
      document.getElementById("payAmtErr").style.display = "none";
      document.getElementById("payAmt").style.borderColor = "lightgray";
    }
    if (errMsg == 0) {
      axios
        .post("https://www.bdc.et/bdc-api/post_payment_advance.php", {
          reqDate: requestDate,
          proId: projectId,
          proName: project,
          contractAmt: contractAmt,
          reqPercent: requestPercent,
          payDate: payDate,
          payAmt: payAmt,
          reqBy: empId,
        })
        .then((res) => {
          setData(res.data);
          setRequestDate("");
          setProjectId("");
          setProject("");
          setContractAmt(0);
          setRequestPercent(0);
          setPayDate("");
          setPayAmt(0);

          document.getElementById("requestAmt").value = "0.00";
          document.getElementById("successMsg").style.display = "block";
        });
    }
  };

  return (
    <>
      {tab.includes("tab11") && (
        <>
          {openDialog ? (
            <>
              <div className="dialog-ext"></div>
              <div style={{ width: "70%", zIndex: 2, position: "absolute" }}>
                <div
                  className="dialog-cont"
                  style={{
                    maxHeight: "600px",
                    overflowY: "auto",
                    scrollbarWidth: "thin",
                    width: "550px",
                  }}
                >
                  <div
                    className="brdr-btm"
                    style={{ display: "inline-flex", width: "100%" }}
                  >
                    <h2 style={{ marginLeft: "20px", color: "gray" }}>
                      የፕሮጀክት ዝርዝር
                    </h2>
                    <input
                      type="button"
                      id="register"
                      value="Cancel"
                      className="btn1"
                      style={{
                        margin: "auto",
                        width: "70px",
                        float: "right",
                      }}
                      onClick={() => setOpenDialog(false)}
                    />
                  </div>
                  {isLoading2 ? (
                    <div style={{ width: "100%", textAlign: "center" }}>
                      <img
                        src={loading2}
                        alt="loading..."
                        style={{ width: "50px", opacity: "0.3" }}
                      />
                    </div>
                  ) : (
                    <>
                      {dialog != "" ? (
                        dialog.map((diag) => (
                          <div
                            className="brdr-btm list-hover"
                            key={diag[0]}
                            style={{
                              cursor: "pointer",
                              width: "100%",
                            }}
                          >
                            <div
                              style={{
                                padding: "10px 20px",
                                display: "inline-flex",
                              }}
                            >
                              <h3
                                onClick={() => {
                                  setProject(diag[1] + ", " + diag[2]);
                                  setProjectId(diag[0]);
                                  setOpenDialog(false);

                                  axios
                                    .post(
                                      "https://www.bdc.et/bdc-api/get_payment_advance.php",
                                      {
                                        proId: diag[0],
                                      }
                                    )
                                    .then((res) => setData(res.data))
                                    .catch((err) => alert(err));
                                }}
                                style={{
                                  width: "100%",
                                  margin: 0,
                                  color: "black",
                                  fontWeight: "normal",
                                  marginLeft: "5px",
                                }}
                              >
                                {diag[1] + ", " + diag[2]}
                              </h3>
                            </div>
                          </div>
                        ))
                      ) : (
                        <p style={{ marginLeft: "40px" }}>No record</p>
                      )}
                    </>
                  )}
                </div>
              </div>
            </>
          ) : (
            ""
          )}

          <div
            className="cont-all"
            style={{
              width: "90%",
              height: "95%",
              padding: "0",
              overflow: "auto",
              scrollbarWidth: "thin",
            }}
          >
            <div
              style={{
                width: "100%",
                backgroundColor: "rgb(240,240,240)",
                margin: "0",
                display: "inline-flex",
                position: "sticky",
                top: 0,
              }}
            >
              <h5
                style={{
                  margin: "0",
                  color: "black",
                  borderColor: "gray",
                  borderRight: "solid",
                  borderWidth: "1px",
                  padding: "10px 20px",
                }}
              >
                የቅድመ ክፍያ መመዝገቢያ ገፅ
              </h5>
            </div>
            <div
              style={{
                minWidth: "400px",
                width: "100%",
                display: "inline-flex",
                flexWrap: "wrap",
              }}
            >
              <div
                style={{
                  width: "50%",
                  minWidth: "300px",
                }}
              >
                <div
                  style={{
                    marginLeft: "20px",
                    width: "80%",
                    minWidth: "250px",
                  }}
                >
                  <p
                    className="reg-err"
                    id="successMsg"
                    style={{
                      color: "green",
                      display: "none",
                      marginTop: "10px",
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    በተሳካ ሁኔታ ተመዝግቧል!
                  </p>
                  <p className="reg-err" id="requestDateErr">
                    *ይህ መረጃ አስፈላጊ ነው!
                  </p>
                  <label style={{ fontSize: "12px" }}>የተጠየቀበት ቀን</label>
                  <br />
                  <input
                    className="txt-input"
                    id="requestDate"
                    type="date"
                    onChange={(e) => {
                      setRequestDate(e.target.value);
                    }}
                    required
                  />
                  <br />
                  <p className="reg-err" id="projectErr">
                    *ይህ መረጃ አስፈላጊ ነው!
                  </p>
                  <input
                    type="button"
                    id="project"
                    value={project != "" ? "ፕሮጀክት ለመቀየር" : "ፕሮጀክት ለመምረጥ"}
                    style={{ fontSize: "12px", margin: "10px 0" }}
                    onClick={OpenDialog}
                  />
                  <br />
                  <p style={{ fontSize: "13px" }}>{project}</p>
                  <p className="reg-err" id="contractAmtErr">
                    *ይህ መረጃ አስፈላጊ ነው!
                  </p>
                  <label style={{ fontSize: "12px" }}>የውል መጠን (ብር)</label>
                  <br />
                  <input
                    className="txt-input"
                    id="contractAmt"
                    type="number"
                    value={contractAmt ? contractAmt : ""}
                    onChange={(e) => {
                      setContractAmt(e.target.value == "" ? 0 : e.target.value);

                      if (e.target.value == "" || requestPercent == "") {
                        document.getElementById("requestAmt").value =
                          "0.00";
                      } else {
                        document.getElementById("requestAmt").value =
                          (
                            (parseFloat(requestPercent) *
                              parseFloat(e.target.value)) /
                            100
                          ).toFixed(2);
                      }
                    }}
                    onBlur={(e) => {
                      if (e.target.value < 0) {
                        e.target.value = "";
                        setContractAmt("");
                      }
                    }}
                    placeholder="0.00"
                    required
                  />
                  <br />
                  <p className="reg-err" id="requestPercentErr">
                    *ይህ መረጃ አስፈላጊ ነው!
                  </p>
                  <label style={{ fontSize: "12px" }}>የተጠየቀ የቅድመ ክፍያ (%)</label>
                  <input
                    className="txt-input"
                    id="requestPercent"
                    type="number"
                    value={requestPercent ? requestPercent : ""}
                    onChange={(e) => {
                      setRequestPercent(
                        e.target.value == "" ? 0 : e.target.value
                      );

                      if (e.target.value == "" || contractAmt == "") {
                        document.getElementById("requestAmt").value =
                          "0.00";
                      } else {
                        document.getElementById("requestAmt").value =
                          (
                            (parseFloat(contractAmt) *
                              parseFloat(e.target.value)) /
                            100
                          ).toFixed(2);
                      }
                    }}
                    onBlur={(e) => {
                      if (e.target.value < 0) {
                        e.target.value = "";
                        setRequestPercent("");
                      }
                    }}
                    placeholder="0.00"
                    required
                  />
                  <br />

                  <label style={{ fontSize: "12px" }}>
                    የተጠየቀ የቅድመ ክፍያ (ብር)
                  </label>
                  <br />
                  <input
                    className="txt-input"
                    id="requestAmt"
                    type="text"
                    placeholder="0.00"
                    onChange={(e) =>
                      e.target.value ? parseFloat(e.target.value) : "0.00"
                    }
                    disabled
                  />
                  <br />
                </div>
              </div>
              <div
                style={{
                  width: "50%",
                  minWidth: "300px",
                }}
              >
                <div
                  style={{
                    marginLeft: "20px",
                    width: "80%",
                    minWidth: "250px",
                  }}
                >
                  <p className="reg-err" id="payDateErr">
                    *ይህ መረጃ አስፈላጊ ነው!
                  </p>
                  <label style={{ fontSize: "12px" }}>የተከፈለበት ቀን</label>
                  <br />
                  <input
                    className="txt-input"
                    id="payDate"
                    type="date"
                    onChange={(e) => {
                      setPayDate(e.target.value);
                    }}
                    required
                  />
                  <br />
                  <p className="reg-err" id="payAmtErr">
                    *ይህ መረጃ አስፈላጊ ነው!
                  </p>
                  <label style={{ fontSize: "12px" }}>
                    የተከፈለ የቅድመ ክፍያ መጠን (ብር)
                  </label>
                  <br />
                  <input
                    className="txt-input"
                    id="payAmt"
                    type="number"
                    value={payAmt ? payAmt : ""}
                    onChange={(e) => {
                      setPayAmt(e.target.value == "" ? 0 : e.target.value);
                    }}
                    onBlur={(e) => {
                      if (e.target.value < 0) {
                        e.target.value = "";
                        setPayAmt("");
                      }
                    }}
                    placeholder="0.00"
                    required
                  />
                </div>
              </div>
              <br />
              <div style={{ width: "100%" }}>
                <input
                  type="button"
                  value={"መዝግብ"}
                  className="btn1"
                  style={{ width: "150px", marginLeft: "20px" }}
                  onClick={RegiBtn}
                />
              </div>

              <div
                style={{
                  width: "100%",
                  minWidth: "400px",
                }}
              >
                <table
                  style={{
                    width: "100%",
                    marginTop: "20px",
                  }}
                >
                  <tr
                    style={{
                      width: "100%",
                      textAlign: "left",
                      color: "gray",
                    }}
                  >
                    <th>ፕሮጀክት</th>
                    <th>የውል መጠን (ብር)</th>
                    <th>የተጠየቀ (%)</th>
                    <th>የተጠየቀ (ብር) </th>
                    <th>የተከፈለ (ብር)</th>
                    <th>የተጠየቀበት ቀን</th>
                    <th>የተከፈለበት ቀን</th>
                  </tr>
                  {isLoading3 ? (
                    <div style={{ width: "100%", textAlign: "center" }}>
                      <img
                        src={loading2}
                        alt="loading..."
                        style={{ width: "50px", opacity: "0.3" }}
                      />
                    </div>
                  ) : (
                    <>
                      {data != [] &&
                        data.map((list) => (
                          <tr key={list[0]}>
                            <td>{list[2]}</td>
                            <td>{parseFloat(list[3]).toLocaleString()}</td>
                            <td>{parseFloat(list[4]).toLocaleString()}</td>
                            <td>
                              {(
                                (parseFloat(list[3]) * parseFloat(list[4])) /
                                100
                              ).toLocaleString()}
                            </td>
                            <td>{parseFloat(list[6]).toLocaleString()}</td>
                            <td>{list[5]}</td>
                            <td>{list[7]}</td>
                          </tr>
                        ))}
                      <tr>
                      <td style={{ textAlign: "right", fontWeight: "bold", borderStyle:"none" }}>
                          
                        </td>
                        <td style={{ textAlign: "right", fontWeight: "bold" }}>
                          ድምር
                        </td>
                        <td style={{ fontWeight: "bold" }}>
                          {" "}
                          {data
                            .reduce((totAmt, item) => {
                              return totAmt + parseFloat(item[4]);
                            }, 0)
                            .toLocaleString()}
                        </td>
                        <td style={{ fontWeight: "bold" }}>
                          {" "}
                          {data
                            .reduce((totAmt, item) => {
                              return totAmt + parseFloat(item[3])*parseFloat(item[4])/100;
                            }, 0)
                            .toLocaleString()}{" "}
                        </td>
                        <td style={{ fontWeight: "bold" }}>
                        {" "}
                          {data
                            .reduce((totAmt, item) => {
                              return totAmt + parseFloat(item[6]);
                            }, 0)
                            .toLocaleString()}
                        </td>
                       
                       
                      </tr>
                    </>
                  )}
                </table>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default PaymentAdvance;
