import axios from "axios";
import { React, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";

function ProjectDetail() {
  const tab = useSelector((state) => state.auth.tab);
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const proId = location.state.id;
  const [editWindow, setEditWindow] = useState(false);
  const [editId, setEditId] = useState("");
  const [editValue, setEditValue] = useState("");
  const [deleteWindow, setDeleteWindow] = useState(false);

  useEffect(() => {
    document.getElementById("windowTitle").textContent =
      "/ፕሮጀክት /የፕሮጀክት መግለጫ";
    axios
      .post("https://www.bdc.et/bdc-api/get_project_id.php", {
        proId: proId,
      })
      .then((res) => {
        setData(res.data[0]);
      });
  }, []);

  const EditValue = () => {
    if (editValue == "") {
      document.getElementById("editValueErr").style.display = "block";
      document.getElementById("editValue").style.borderColor = "red";
    } else {
      document.getElementById("editValueErr").style.display = "none";
      document.getElementById("editValue").style.borderColor = "lightgray";
    }

    if (editValue != "") {
      axios
        .post("https://www.bdc.et/bdc-api/post_edit_project.php", {
          proId: data[0],
          editId: editId,
          editValue: editValue,
        })
        .then((res) => {
          setData(res.data[0]);
          setEditWindow(false);
        });
    }
  };

  const DeleteValue = () => {
    if (data[0] != "") {
      axios
        .post("https://www.bdc.et/bdc-api/post_delete_project.php", {
          proId: data[0],
        })
        .then(navigate("/project_list", {}));
    }
  };

  return (
    <>
      {tab.includes("tab21") && (
        <>
          {deleteWindow ? (
            <>
              <div className="dialog-ext"></div>
              <div style={{ width: "70%", zIndex: 2, position: "absolute" }}>
                <div
                  className="dialog-cont"
                  style={{ marginTop: "60px", textAlign: "center" }}
                >
                  <p
                    id="deleteVerify"
                    style={{
                      margin: "10px ",
                      padding: 0,
                      color: "red",
                      fontSize: "14px",
                      paddingLeft: "20px",
                    }}
                  >
                    *Are you sure you want to delete this project? This action
                    cannot be undo!
                  </p>
                  <div style={{ display: "inline-flex", width: "100%" }}>
                    <input
                      type="button"
                      id="edit"
                      value="Delete"
                      className="btn1"
                      style={{
                        margin: "20px",
                        width: "70px",
                        alignSelf: "right",
                      }}
                      onClick={DeleteValue}
                    />
                    <input
                      type="button"
                      id="register"
                      value="Cancel"
                      className="btn2"
                      style={{
                        margin: "20px",
                        width: "70px",
                        alignSelf: "right",
                      }}
                      onClick={() => setDeleteWindow(false)}
                    />
                  </div>
                </div>
              </div>
            </>
          ) : (
            ""
          )}

          {editWindow ? (
            <>
              <div className="dialog-ext"></div>
              <div style={{ width: "70%", zIndex: 2, position: "absolute" }}>
                <div
                  className="dialog-cont"
                  style={{ marginTop: "60px", textAlign: "center" }}
                >
                  <p
                    id="editValueErr"
                    style={{
                      margin: "10px 0 -10px 0",
                      padding: 0,
                      color: "red",
                      fontSize: "14px",
                      paddingLeft: "20px",
                      display: "none",
                    }}
                  >
                    *Required Information
                  </p>
                  {editId == "Status" && (
                    <select
                      onChange={(e) => setEditValue(e.target.value)}
                      className="txt-input"
                      style={{
                        marginTop: "20px",
                        width: "95%",
                      }}
                      id="editValue"
                    >
                      <option value="Active">Active</option>
                      <option value="Completed">Completed</option>
                      <option value="Terminated">Terminated</option>
                    </select>
                  )}
                  {editId != "Status" && (
                    <input
                      className="txt-input"
                      id="editValue"
                      type={
                        editId == "Sign Date" || editId == "Handover Date"
                          ? "date"
                          : editId == "Duration"
                          ? "number"
                          : "text"
                      }
                      style={{ marginTop: "20px" }}
                      defaultValue={
                        editId == "Contract Amount"
                          ? parseFloat(editValue)
                            ? parseFloat(editValue).toLocaleString()
                            : ""
                          : editValue
                      }
                      onChange={(e) => {
                        if (editId == "Contract Amount") {
                          parseFloat(e.target.value)
                            ? setEditValue(parseFloat(e.target.value))
                            : setEditValue(0);
                        } else {
                          setEditValue(e.target.value);
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.key == "Enter") {
                          document.getElementById("edit").click();
                        }
                      }}
                      onBlur={() => {
                        if (editId == "Contract Amount") {
                          parseFloat(editValue)
                            ? (document.getElementById("editValue").value =
                                parseFloat(editValue).toLocaleString())
                            : (document.getElementById("editValue").value = "");
                        }
                      }}
                      onFocus={() =>
                        editId == "Contract Amount" && editValue
                          ? (document.getElementById("editValue").value =
                              editValue.toString().replace(",", ""))
                          : ""
                      }
                      placeholder={"Enter " + editId}
                      required
                    />
                  )}
                  <br />
                  <div style={{ display: "inline-flex", width: "100%" }}>
                    <input
                      type="button"
                      id="edit"
                      value="Edit"
                      className="btn1"
                      style={{
                        margin: "20px",
                        width: "70px",
                        alignSelf: "right",
                      }}
                      onClick={EditValue}
                    />
                    <input
                      type="button"
                      id="register"
                      value="Cancel"
                      className="btn2"
                      style={{
                        margin: "20px",
                        width: "70px",
                        alignSelf: "right",
                      }}
                      onClick={() => setEditWindow(false)}
                    />
                  </div>
                </div>
              </div>
            </>
          ) : (
            ""
          )}

          <div
            className="cont-all"
            style={{
              width: "60%",
              minWidth: "500px",
              height: "97%",
              padding: "0",
              overflow: "auto",
            }}
          >
            <h3
              style={{
                backgroundColor: "gray",
                color: "white",
                margin: 0,
                padding: "5px 0px 5px 20px",
                position: "sticky",
                top: 0,
              }}
            >
              የፕሮጀክት መረጃ
            </h3>
            {data != [] && (
              <div style={{ marginLeft: "50px" }}>
                <div style={{ display: "inline-flex" }}>
                  {" "}
                  <h2 style={{ color: "gray", marginLeft: "-20px" }}>
                    {data[1]}
                  </h2>{" "}
                  <FontAwesomeIcon
                    className="menu-icon brdr-all"
                    style={{
                      color: "green",
                      padding: "3px",
                      borderColor: "green",
                      cursor: "pointer",
                      fontSize: "10px",
                    }}
                    icon={faPen}
                    onClick={() => {
                      setEditId("Project Name");
                      setEditValue(data[1]);
                      setEditWindow(true);
                    }}
                  />
                </div>
                <br />
                <div
                  className="brdr-btm"
                  style={{
                    width: "100%",
                    display: "inline-flex",
                  }}
                >
                  <p
                    style={{
                      fontWeight: "bold",
                      color: "gray",
                      width: "160px",
                    }}
                  >
                    የፕሮጀክት ቦታ{" "}
                  </p>
                  <p
                    style={{
                      fontWeight: "normal",
                      color: "black",
                    }}
                  >
                    {data[2]}
                  </p>
                  <p
                    style={{
                      color: "green",
                      fontSize: "12px",
                      marginLeft: "10px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setEditId("Location");
                      setEditValue(data[2]);
                      setEditWindow(true);
                    }}
                  >
                    ለመቀየር
                  </p>
                </div>
                <br />
                <div
                  className="brdr-btm"
                  style={{
                    width: "100%",
                    display: "inline-flex",
                  }}
                >
                  <p
                    style={{
                      fontWeight: "bold",
                      color: "gray",
                      width: "160px",
                    }}
                  >
                    ባለቤት{" "}
                  </p>
                  <p
                    style={{
                      fontWeight: "normal",
                      color: "black",
                    }}
                  >
                    {data[3]}
                  </p>
                  <p
                    style={{
                      color: "green",
                      fontSize: "12px",
                      marginLeft: "10px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setEditId("Client");
                      setEditValue(data[3]);
                      setEditWindow(true);
                    }}
                  >
                    ለመቀየር
                  </p>
                </div>
                <br />
                <div
                  className="brdr-btm"
                  style={{
                    width: "100%",
                    display: "inline-flex",
                  }}
                >
                  <p
                    style={{
                      fontWeight: "bold",
                      color: "gray",
                      width: "160px",
                    }}
                  >
                    አማካሪ {" "}
                  </p>
                  <p
                    style={{
                      fontWeight: "normal",
                      color: "black",
                    }}
                  >
                    {data[4] ? data[4] : "-"}
                  </p>
                  <p
                    style={{
                      color: "green",
                      fontSize: "12px",
                      marginLeft: "10px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setEditId("Consultant");
                      setEditValue(data[4]);
                      setEditWindow(true);
                    }}
                  >
                    ለመቀየር
                  </p>
                </div>
                <br />
                <div
                  className="brdr-btm"
                  style={{
                    width: "100%",
                    display: "inline-flex",
                  }}
                >
                  <p
                    style={{
                      fontWeight: "bold",
                      color: "gray",
                      width: "160px",
                    }}
                  >
                    የውል መጠን{" "}
                  </p>
                  <p
                    style={{
                      fontWeight: "normal",
                      color: "black",
                    }}
                  >
                    {parseFloat(data[5]).toLocaleString() + " ብር"}
                  </p>
                  <p
                    style={{
                      color: "green",
                      fontSize: "12px",
                      marginLeft: "10px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setEditId("Contract Amount");
                      setEditValue(data[5]);
                      setEditWindow(true);
                    }}
                  >
                    ለመቀየር
                  </p>
                </div>
                <br />
                <div
                  className="brdr-btm"
                  style={{
                    width: "100%",
                    display: "inline-flex",
                  }}
                >
                  <p
                    style={{
                      fontWeight: "bold",
                      color: "gray",
                      width: "160px",
                    }}
                  >
                    የፕሮጀክት ጊዜ{" "}
                  </p>
                  <p
                    style={{
                      fontWeight: "normal",
                      color: "black",
                    }}
                  >
                    {data[6] ? data[6] + " ቀን" : "-"}
                  </p>
                  <p
                    style={{
                      color: "green",
                      fontSize: "12px",
                      marginLeft: "10px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setEditId("Duration");
                      setEditValue(data[6]);
                      setEditWindow(true);
                    }}
                  >
                    ለመቀየር
                  </p>
                </div>
                <br />
                <div
                  className="brdr-btm"
                  style={{
                    width: "100%",
                    display: "inline-flex",
                  }}
                >
                  <p
                    style={{
                      fontWeight: "bold",
                      color: "gray",
                      width: "160px",
                    }}
                  >
                    ውል የተያዘበት ቀን{" "}
                  </p>
                  <p
                    style={{
                      fontWeight: "normal",
                      color: "black",
                    }}
                  >
                    {data[7] ? data[7] : "-"}
                  </p>
                  <p
                    style={{
                      color: "green",
                      fontSize: "12px",
                      marginLeft: "10px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setEditId("Sign Date");
                      setEditValue(data[7]);
                      setEditWindow(true);
                    }}
                  >
                    ለመቀየር
                  </p>
                </div>
                <br />
                <div
                  className="brdr-btm"
                  style={{
                    width: "100%",
                    display: "inline-flex",
                  }}
                >
                  <p
                    style={{
                      fontWeight: "bold",
                      color: "gray",
                      width: "160px",
                    }}
                  >
                    የግንባታ ቦታ ርክክብ ቀን{" "}
                  </p>
                  <p
                    style={{
                      fontWeight: "normal",
                      color: "black",
                    }}
                  >
                    {data[8] ? data[8] : "-"}
                  </p>
                  <p
                    style={{
                      color: "green",
                      fontSize: "12px",
                      marginLeft: "10px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setEditId("Handover Date");
                      setEditValue(data[8]);
                      setEditWindow(true);
                    }}
                  >
                    ለመቀየር
                  </p>
                </div>
                <br />
                <div
                  className="brdr-btm"
                  style={{
                    width: "100%",
                    display: "inline-flex",
                  }}
                >
                  <p
                    style={{
                      fontWeight: "bold",
                      color: "gray",
                      width: "160px",
                    }}
                  >
                    የሚገኝበት ደረጀ{" "}
                  </p>
                  <p
                    style={{
                      fontWeight: "normal",
                      color: "black",
                    }}
                  >
                    {data[11] ? data[11] : "-"}
                  </p>
                  <p
                    style={{
                      color: "green",
                      fontSize: "12px",
                      marginLeft: "10px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setEditId("Status");
                      setEditValue(data[11] ? data[11] : "Active");
                      setEditWindow(true);
                    }}
                  >
                    ለመቀየር
                  </p>
                </div>
                <br />
                <div style={{ display: "inline-flex" }}>
                  <div
                    className="btn1"
                    style={{
                      width: "150px",
                      textAlign: "center",
                      justifyContent: "center",
                      display: "inline-flex",
                    }}
                    onClick={() => setDeleteWindow(true)}
                  >
                    <FontAwesomeIcon
                      className="menu-icon"
                      id="dlt-icon"
                      style={{
                        padding: "3px",
                        cursor: "pointer",
                        margin: "0",
                        padding: 0,
                        fontSize: "14px",
                      }}
                      icon={faTrashCan}
                      onClick={() => {}}
                    />
                    <p style={{ margin: "0", padding: 0, marginLeft: "10px" }}>
                      Delete
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
}

export default ProjectDetail;
