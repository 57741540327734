import axios from "axios";
import { React, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { expReqAction } from "./state/resourceSlice";
import { useSelector } from "react-redux";
import loading2 from "../img/loading2.gif";
import moment from "moment";

function ResourceRequestList() {
  const tab = useSelector((state) => state.auth.tab);
  const empId = useSelector((state) => state.auth.empId);
  const current_date = moment(Date()).format("DD-MM-YYYY");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);

  const [data, setData] = useState([]);
  const [status, setStatus] = useState(1);
  const [project, setProject] = useState("All");
  const [resource, setResource] = useState("All");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [openDialog, setOpenDialog] = useState(false);
  const [dialog, setDialog] = useState([]);
  const [openDialog2, setOpenDialog2] = useState(false);
  const [dialog2, setDialog2] = useState([]);
  const [projectName, setProjectName] = useState("All");
  const [resourcName, setResourceName] = useState("All");
  const [resType, setResType] = useState("Material");
  const [resCategory, setResCategory] = useState("Civil");

  useEffect(() => {
    document.getElementById("windowTitle").textContent = "/ግብአት /የተጠየቀ ዝርዝር";

    setIsLoading(true);
    axios
      .post("https://www.bdc.et/bdc-api/get_requested_resource_list.php", {
        status: status,
        project: project,
        resource: resource,
      })
      .then((res) => setData(res.data))
      .catch((err) => alert(err))
      .finally(() => setIsLoading(false));
  }, []);

  const OpenDialog = () => {
    setOpenDialog(true);
    setIsLoading2(true);

    axios
      .post("https://www.bdc.et/bdc-api/get_project_list.php", {})
      .then((res) => setDialog(res.data))
      .catch((err) => alert(err))
      .finally(() => setIsLoading2(false));
  };

  const OpenDialog2 = () => {
    setOpenDialog2(true);
    setResType("Material");
    setResCategory("Civil");
    setIsLoading2(true);
    axios
      .post("https://www.bdc.et/bdc-api/get_resource_list.php", {
        resType: "Material",
        resCategory: "Civil",
      })
      .then((res) => setDialog2(res.data))
      .catch((err) => alert(err))
      .finally(() => setIsLoading2(false));
  };

  const CheckReq = (reqId, state) => {
    if (state) {
      axios
        .post("https://www.bdc.et/bdc-api/post_resource_request_check.php", {
          reqId: reqId,
          empId: state,
        })
        .then(() => {
          setIsLoading(true);
          axios
            .post(
              "https://www.bdc.et/bdc-api/get_requested_resource_list.php",
              {
                status: status,
                project: project,
                resource: resource,
              }
            )
            .then((res) => setData(res.data))
            .catch((err) => alert(err))
            .finally(() => setIsLoading(false));
        })
        .catch((err) => alert(err));
    }
  };

  return (
    <>
      {(tab.includes("tab32") ||
        tab.includes("tab33") ||
        tab.includes("tab41")) && (
        <>
          {openDialog ? (
            <>
              <div className="dialog-ext"></div>
              <div style={{ width: "70%", zIndex: 2, position: "absolute" }}>
                <div
                  className="dialog-cont"
                  style={{
                    maxHeight: "600px",
                    overflowY: "auto",
                    width: "550px",
                  }}
                >
                  <div
                    className="brdr-btm"
                    style={{ display: "inline-flex", width: "100%" }}
                  >
                    <h2 style={{ marginLeft: "20px", color: "gray" }}>
                      የፕሮጀክት ዝርዝር
                    </h2>
                    <input
                      type="button"
                      id="register"
                      value="Cancel"
                      className="btn1"
                      style={{
                        margin: "auto",
                        width: "70px",
                        float: "right",
                      }}
                      onClick={() => setOpenDialog(false)}
                    />
                  </div>
                  {isLoading2 ? (
                    <div style={{ width: "100%", textAlign: "center" }}>
                      <img
                        src={loading2}
                        alt="loading..."
                        style={{ width: "50px", opacity: "0.3" }}
                      />
                    </div>
                  ) : (
                    <>
                      {dialog != "" ? (
                        dialog.map((diag) => (
                          <div
                            className="brdr-btm list-hover"
                            key={diag[0]}
                            style={{
                              cursor: "pointer",
                              width: "100%",
                            }}
                          >
                            <div
                              style={{
                                padding: "10px 20px",
                                display: "inline-flex",
                              }}
                            >
                              <h3
                                onClick={() => {
                                  setProjectName(diag[1] + ", " + diag[2]);
                                  setProject(diag[0]);
                                  setOpenDialog(false);

                                  setIsLoading(true);
                                  axios
                                    .post(
                                      "https://www.bdc.et/bdc-api/get_requested_resource_list.php",
                                      {
                                        status: status,
                                        project: diag[0],
                                        resource: resource,
                                      }
                                    )
                                    .then((res) => setData(res.data))
                                    .catch((err) => alert(err))
                                    .finally(() => setIsLoading(false));
                                }}
                                style={{
                                  width: "100%",
                                  margin: 0,
                                  color: "black",
                                  fontWeight: "normal",
                                  marginLeft: "5px",
                                }}
                              >
                                {diag[1] + ", " + diag[2]}
                              </h3>
                            </div>
                          </div>
                        ))
                      ) : (
                        <p style={{ marginLeft: "40px" }}>No record</p>
                      )}
                    </>
                  )}
                </div>
              </div>
            </>
          ) : (
            ""
          )}

          {openDialog2 ? (
            <>
              <div className="dialog-ext"></div>
              <div style={{ width: "70%", zIndex: 2, position: "absolute" }}>
                <div
                  className="dialog-cont"
                  style={{
                    maxHeight: "600px",
                    overflowY: "auto",
                    width: "500px",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "white",
                      position: "sticky",
                      top: 0,
                    }}
                  >
                    <div style={{ display: "inline-flex", width: "100%" }}>
                      <h2 style={{ marginLeft: "20px", color: "gray" }}>
                        የግብአት ዝርዝር
                      </h2>
                      <input
                        type="button"
                        id="register"
                        value="Cancel"
                        className="btn1"
                        style={{
                          width: "70px",
                          alignSelf: "right",
                          float: "right",
                          margin: "auto",
                        }}
                        onClick={() => setOpenDialog2(false)}
                      />
                    </div>
                    <div
                      className="brdr-btm"
                      style={{
                        width: "100%",
                        backgroundColor: "rgb(240,240,240)",
                        marginTop: "0px",
                      }}
                    >
                      <input
                        type="text"
                        className="txt-input"
                        placeholder="ለመፈለግ"
                        style={{ marginLeft: "10px" }}
                        onChange={(e) => {
                          if (e.target.value == "") {
                            axios
                              .post(
                                "https://www.bdc.et/bdc-api/get_resource_list.php",
                                {
                                  resType: resType,
                                  resCategory: resCategory,
                                }
                              )
                              .then((res) => {
                                setDialog2(res.data);
                              });
                          } else {
                            axios
                              .post(
                                "https://www.bdc.et/bdc-api/get_search_resource.php",
                                {
                                  searchTerm: e.target.value,
                                }
                              )
                              .then((res) => {
                                setDialog2(res.data);
                              });
                          }
                        }}
                      />
                      <div style={{ display: "inline-flex" }}>
                        <label
                          style={{
                            marginLeft: "10px",
                            fontSize: "14px",
                            color: "gray",
                          }}
                        >
                          <select
                            name="type"
                            id="type"
                            style={{ width: "200px" }}
                            onChange={(e) => {
                              setResType(e.target.value);
                              axios
                                .post(
                                  "https://www.bdc.et/bdc-api/get_resource_list.php",
                                  {
                                    resType: e.target.value,
                                    resCategory: resCategory,
                                  }
                                )
                                .then((res) => {
                                  setDialog2(res.data);
                                });
                            }}
                            className="txt-input"
                          >
                            <option value="Material">Material</option>
                            <option value="Labor">Labor</option>
                            <option value="Equip & Machine">
                              Equip & Machine
                            </option>
                            <option value="Cash">Cash</option>
                          </select>
                        </label>
                        {resType == "Material" && (
                          <label
                            style={{
                              fontSize: "14px",
                              color: "gray",
                              marginLeft: "10px",
                            }}
                          >
                            <select
                              name="type"
                              id="category"
                              onChange={(e) => {
                                setResCategory(e.target.value);
                                axios
                                  .post(
                                    "https://www.bdc.et/bdc-api/get_resource_list.php",
                                    {
                                      resType: resType,
                                      resCategory: e.target.value,
                                    }
                                  )
                                  .then((res) => {
                                    setDialog2(res.data);
                                  });
                              }}
                              className="txt-input"
                              style={{ width: "200px" }}
                            >
                              <option value="Civil">Civil</option>
                              <option value="Finishing">Finishing</option>
                              <option value="Electrical">Electrical</option>
                              <option value="Sanitary">Sanitary</option>
                              <option value="Stationary">Stationary</option>
                              <option value="Furniture">Furniture</option>
                            </select>
                          </label>
                        )}
                        <br />
                      </div>
                      <p
                        style={{
                          fontSize: "14px",
                          margin: "0",
                          paddingLeft: "20px",
                        }}
                      >
                        {dialog2.length + " "}Records
                      </p>
                    </div>
                  </div>
                  {isLoading2 ? (
                    <div style={{ width: "100%", textAlign: "center" }}>
                      <img
                        src={loading2}
                        alt="loading..."
                        style={{ width: "50px", opacity: "0.3" }}
                      />
                    </div>
                  ) : (
                    <>
                      {dialog2 != "" ? (
                        dialog2.map((diag) => (
                          <div
                            className="brdr-btm list-hover"
                            key={diag[0]}
                            style={{ padding: "10px 20px", cursor: "pointer" }}
                            onClick={() => {
                              setResourceName(diag[1]);
                              setResource(diag[0]);
                              setOpenDialog2(false);

                              setIsLoading(true);
                              axios
                                .post(
                                  "https://www.bdc.et/bdc-api/get_requested_resource_list.php",
                                  {
                                    status: status,
                                    project: project,
                                    resource: diag[0],
                                  }
                                )
                                .then((res) => setData(res.data))
                                .catch((err) => alert(err))
                                .finally(() => setIsLoading(false));
                            }}
                          >
                            <h3 style={{ margin: 0, color: "gray" }}>
                              {diag[1]}
                            </h3>
                            <p style={{ margin: 0 }}>{diag[3]}</p>
                          </div>
                        ))
                      ) : (
                        <p style={{ marginLeft: "40px" }}>No record</p>
                      )}
                    </>
                  )}
                </div>
              </div>
            </>
          ) : (
            ""
          )}

          <div
            className="cont-all"
            style={{
              width: "100%",
              minWidth: "600px",
              height: "97%",
              paddingTop: "0",
              overflow: "auto",
            }}
          >
            <div
              className="brdr-btm"
              style={{
                width: "100%",
                height: "45px",
                backgroundColor: "white",
                margin: "0",
                display: "inline-flex",
                position: "sticky",
                top: 0,
              }}
            >
              <h5
                style={{
                  margin: "0",
                  color: "black",
                  padding: "10px 20px",
                  borderRight: "solid",
                  borderColor: "gray",
                  borderWidth: "1px",
                }}
              >
                የተጠየቁ ግብአቶች ዝርዝር
              </h5>
              <p
                style={{ color: "black", marginLeft: "20px", fontSize: "12px" }}
              >
                ቀን: {current_date}
              </p>
            </div>
            <div
              style={{
                width: "100%",
                backgroundColor: "rgb(230,230,230)",
                display: "inline-flex",
              }}
            >
              <div
                style={{ width: "30%", marginRight: "10px", padding: "10px" }}
              >
                <p style={{ padding: 0, margin: 0,fontSize: "12px" }}>የግዥ ሂደት</p>
                <select
                  className="txt-input"
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    setStatus(e.target.value);
                    setIsLoading(true);
                    axios
                      .post(
                        "https://www.bdc.et/bdc-api/get_requested_resource_list.php",
                        {
                          status: e.target.value,
                          project: project,
                          resource: resource,
                        }
                      )
                      .then((res) => setData(res.data))
                      .catch((err) => alert(err))
                      .finally(() => setIsLoading(false));
                  }}
                >
                  <option value={"1"}>Active</option>
                  <option value={"2"}>Completed</option>
                </select>
              </div>
              <div
                style={{ width: "30%", marginRight: "10px", padding: "10px" }}
              >
                <p style={{ padding: 0, margin: 0,fontSize: "12px" }}>{projectName}</p>
                <input
                  type="button"
                  className="btn1"
                  value={"ፕሮጀክት ለመምረጥ"}
                  onClick={OpenDialog}
                />
              </div>
              <div style={{ width: "30%", padding: "10px" }}>
                <p style={{ padding: 0, margin: 0 ,fontSize: "12px"}}>{resourcName}</p>
                <input
                  type="button"
                  className="btn2"
                  value={"ግብአት ለመምረጥ"}
                  onClick={OpenDialog2}
                />
              </div>
            </div>
            <p style={{ margin: "0 20px" }}>
              {"[ " + data.length + " Records ]"}
            </p>
            <table
              style={{
                width: "100%",
                minWidth: "600px",
              }}
            >
              <tr
                style={{
                  width: "100%",
                  textAlign: "left",
                  color: "gray",
                  position: "sticky",
                  top: "45px",
                }}
              >
                <th>ፕሮጀክት</th>
                <th>ግብአት</th>
                <th>መለክያ</th>
                <th>የተጠየቀ መጠን</th>
                <th>ቀሪ መጠን</th>
                <th>የግ.መ.ቁ</th>
                <th>የተጠየቀበት ቀን</th>
                <th></th>
                <th></th>
              </tr>
              {isLoading ? (
                <div style={{ width: "100%", textAlign: "center" }}>
                  <img
                    src={loading2}
                    alt="loading..."
                    style={{ width: "50px", opacity: "0.3" }}
                  />
                </div>
              ) : (
                <>
                  {data != [] &&
                    data.map((list) => (
                      <tr key={list[0]}>
                        <td> {list[2]}</td>
                        <td> {list[4]}</td>
                        <td> {list[5]}</td>
                        <td> {parseFloat(list[6]).toLocaleString()}</td>
                        <td> {parseFloat(list[7]).toLocaleString()}</td>
                        <td> {list[9]}</td>
                        <td> {moment(list[10]).format("DD-MM-YYYY")}</td>
                        <td>
                          {list[7] == 0 ? (
                            <p
                              style={{
                                fontSize: "12px",
                                color: "blue",
                              }}
                            >
                              Completed
                            </p>
                          ) : (
                            <div style={{ display: "inline-flex" }}>
                              {tab.includes("tab33") && list[15] == 0 && (
                                <>
                                  {list[14] == 0 ? (
                                    <input
                                      type="button"
                                      style={{
                                        cursor: "pointer",
                                        width: "70px",
                                        fontSize: "12px",
                                      }}
                                      value={"አረጋግጥ"}
                                      onClick={() => CheckReq(list[0], empId)}
                                    />
                                  ) : (
                                    <>
                                      {list[14] == empId &&
                                      list[6] == list[7] ? (
                                        <input
                                          type="button"
                                          style={{
                                            cursor: "pointer",
                                            color: "gray",
                                            width: "70px",
                                            fontSize: "12px",
                                          }}
                                          value={"ተረጋግጧል"}
                                          onClick={() => CheckReq(list[0], "0")}
                                        />
                                      ) : (
                                        <p
                                          style={{
                                            fontSize: "12px",
                                            color: "green",
                                            width: "70px",
                                            margin: 0,
                                            textAlign: "center",
                                            fontSize: "12px",
                                          }}
                                        >
                                          ተረጋግጧል
                                        </p>
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                              {tab.includes("tab41") && (
                                <>
                                  {list[14] == 0 ? (
                                    <p
                                      style={{
                                        fontSize: "12px",
                                        color: "red",
                                        margin: "2px 0px 0px 15px",
                                      }}
                                    >
                                      ያልተረጋገጠ
                                    </p>
                                  ) : (
                                    <>
                                      <input
                                        type="button"
                                        style={{
                                          cursor: "pointer",
                                          marginLeft: "10px",
                                          fontSize: "12px",
                                        }}
                                        value={"ምረጥ"}
                                        onClick={() => {
                                          dispatch(
                                            expReqAction.setReqId({
                                              reqId: list[0],
                                            })
                                          );
                                          navigate("/request_for_expense", {});
                                        }}
                                      />
                                    </>
                                  )}
                                </>
                              )}
                            </div>
                          )}
                        </td>
                        <td>
                          <input
                            style={{ cursor: "pointer", fontSize: "12px" }}
                            type="button"
                            value="ክፈት"
                            onClick={() => {
                              navigate("/resource_request_detail", {
                                state: {
                                  id: list[0],
                                },
                              });
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                </>
              )}
            </table>
          </div>
        </>
      )}
    </>
  );
}

export default ResourceRequestList;
