import axios from "axios";
import { React, useState, useEffect } from "react";
import loading2 from "../img/loading2.gif";
import moment from "moment";
import { expSettleAction } from "./state/expenseSlice";
import hrIcon2 from "../img/hr-icon2.png";
import { useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";

function HumanResource() {
  const navigate = useNavigate();

  useEffect(() => {
    document.getElementById("windowTitle").textContent = "/የሰው ሃይል";
  }, []);

  return (
    <>
      <div
        className="cont-all"
        style={{
          width: "40%",
          minWidth: "300px",
          height: "97%",
          paddingTop: "0",
          overflow: "auto",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "45px",
            backgroundColor: "gray",
            margin: "0",
            display: "inline-flex",
            position: "sticky",
            top: 0,
          }}
        >
          <img
            src={hrIcon2}
            style={{ width: "30px", height: "30px", margin: "10px" }}
            alt="hr-icon"
          />
          <h2
            style={{
              margin: "0",
              color: "white",
              padding: "10px",
            }}
          >
            የሰው ሃይል መምርያ
          </h2>
        </div>
        <div className="sub-menu">
          <h5 className="sub-menu-h5">ለኩባንያው ሰራተኞች</h5>
          <div className="sub-menu-p"
          onClick={()=>navigate("/employee_registration")}
          >
            <FontAwesomeIcon
              style={{ margin: "16px 5px 5px 25px", fontSize: "14px" }}
              icon={faAngleRight}
            />
            <p
              style={{
                padding: "5px",
              }}
            >
              ሰራተኛ ለመመዝገብ
            </p>
          </div>
          <div
            className="sub-menu-p"
            onClick={() => {
              navigate("/employee_role", {
                state: {
                  id: "",
                  idNumber: "",
                  department: "",
                  fullName: "",
                },
              });
            }}
          >
            <FontAwesomeIcon
              style={{ margin: "16px 5px 5px 25px", fontSize: "14px" }}
              icon={faAngleRight}
            />
            <p
              style={{
                padding: "5px",
              }}
            >
              የስራ ድርሻ ለመመዝገብ
            </p>
          </div>
          <div
            className="sub-menu-p"
            onClick={() => {
              navigate("/employee_experience", {
                state: {
                  id: "",
                  idNumber: "",
                  department: "",
                  fullName: "",
                },
              });
            }}
          >
            <FontAwesomeIcon
              style={{ margin: "16px 5px 5px 25px", fontSize: "14px" }}
              icon={faAngleRight}
            />
            <p
              style={{
                padding: "5px",
              }}
            >
              የስራ ልምድ
            </p>
          </div>
          <div
            className="sub-menu-p"
            onClick={() => {
              navigate("/employee_payment", {
                state: {
                  id: "",
                  idNumber: "",
                  department: "",
                  fullName: "",
                },
              });
            }}
          >
            <FontAwesomeIcon
              style={{ margin: "16px 5px 5px 25px", fontSize: "14px" }}
              icon={faAngleRight}
            />
            <p
              style={{
                padding: "5px",
              }}
            >
              የክፍያ ሁኔታ
            </p>
          </div>
          <div
            className="sub-menu-p"
            onClick={() =>
              navigate("/employee_list", {
                state: {
                  fromPage: "",
                },
              })
            }
          >
            <FontAwesomeIcon
              style={{ margin: "16px 5px 5px 25px", fontSize: "14px" }}
              icon={faAngleRight}
            />
            <p
              style={{
                padding: "5px",
              }}
            >
              የሰራተኛ ዝርዝር
            </p>
          </div>
        </div>
        <div className="sub-menu">
          <h5 className="sub-menu-h5">ፍቃድ</h5>
          <div
            className="sub-menu-p"
            onClick={() => {
              navigate("/employee_leave", {
                state: {
                  id: "",
                  idNumber: "",
                  department: "",
                  fullName: "",
                },
              });
            }}
          >
            <FontAwesomeIcon
              style={{ margin: "16px 5px 5px 25px", fontSize: "14px" }}
              icon={faAngleRight}
            />
            <p
              style={{
                padding: "5px",
              }}
            >
              ፍቃድ ለመመዝገብ
            </p>
          </div>
          <div
            className="sub-menu-p"
            onClick={() => navigate("/leave_request")}
          >
            <FontAwesomeIcon
              style={{ margin: "16px 5px 5px 25px", fontSize: "14px" }}
              icon={faAngleRight}
            />
            <p
              style={{
                padding: "5px",
              }}
            >
              ፍቃድ ለመጠየቅ
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default HumanResource;
