import axios from "axios";
import { React, useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import loading1 from "../img/loading1.gif";
import loading2 from "../img/loading2.gif";
import moment from "moment";
import { parse } from "@fortawesome/fontawesome-svg-core";

function DailyReportSend() {
  const tab = useSelector((state) => state.auth.tab);
  const empId = useSelector((state) => state.auth.empId);
  const [reportDay, setReportDay] = useState("");
  const [project, setProject] = useState("");
  const [projectId, setProjectId] = useState("");
  const [dailyPlan, setDailyPlan] = useState(0);
  const [dailyExecution, setDailyExecution] = useState(0);
  const [directCost, setDirectCost] = useState(0);
  const [indirectCost, setIndirectCost] = useState(0);
  const [activity, setActivity] = useState("");
  const [problem, setProblem] = useState("");
  let errMsg = 0;

  const [openDialog, setOpenDialog] = useState(false);
  const [dialog, setDialog] = useState([]);
  const [isLoading2, setIsLoading2] = useState(false);
  const [isLoading3, setIsLoading3] = useState(false);
  const [data, setData] = useState([]);
  const [projectName, setProjectName] = useState("");
  const [projectList, setProjectList] = useState([]);

  useEffect(() => {
    document.getElementById("windowTitle").textContent =
      "/የግንባታ ክትትል /የቀን ሪፖርት ምዝገባ";

    reportDay != "" &&
      axios
        .post("https://www.bdc.et/bdc-api/get_daily_report.php", {
          reportDay: reportDay,
        })
        .then((res) => {
          setData(res.data);
        });

    axios
      .post("https://www.bdc.et/bdc-api/get_project_list.php", {})
      .then((res) => {
        setProjectList(res.data);
      })
      .catch((err) => alert(err));
  }, []);

  const OpenDialog = () => {
    setOpenDialog(true);
    setIsLoading2(true);
    axios
      .post("https://www.bdc.et/bdc-api/get_project_list.php", {})
      .then((res) => {
        setDialog(res.data);
      })
      .catch((err) => alert(err))
      .finally(() => setIsLoading2(false));
  };

  const RegiBtn = () => {
    if (reportDay == "") {
      document.getElementById("reportDayErr").style.display = "block";
      document.getElementById("reportDay").style.borderColor = "red";
      errMsg = 1;
    } else {
      document.getElementById("reportDayErr").style.display = "none";
      document.getElementById("reportDay").style.borderColor = "lightgray";
    }

    if (projectId == "") {
      document.getElementById("projectErr").style.display = "block";
      errMsg = 1;
    } else {
      document.getElementById("projectErr").style.display = "none";
    }

    if (errMsg == 0) {
      axios
        .post("https://www.bdc.et/bdc-api/post_daily_report.php", {
          empId: empId,
          reportDay: reportDay,
          projectId: projectId,
          project: project,
          dailyPlan: dailyPlan,
          dailyExecution: dailyExecution,
          directCost: directCost,
          indirectCost: indirectCost,
          activity: activity,
          problem: problem,
        })
        .then((res) => {
          setData(res.data);
          setProjectId("");
          setProject("");
          setDailyPlan(0);
          setDailyExecution(0);
          setDirectCost(0);
          setIndirectCost(0);
          setActivity("");
          setProblem("");

          document.getElementById("performance").value = "";
          document.getElementById("profitLoss").value = "";
          document.getElementById("successMsg").style.display = "block";
        });
    }
  };

  return (
    <>
      {tab.includes("tab11") && (
        <>
          {openDialog ? (
            <>
              <div className="dialog-ext"></div>
              <div style={{ width: "70%", zIndex: 2, position: "absolute" }}>
                <div
                  className="dialog-cont"
                  style={{
                    maxHeight: "600px",
                    overflowY: "auto",
                    scrollbarWidth: "thin",
                    width: "550px",
                  }}
                >
                  <div
                    className="brdr-btm"
                    style={{ display: "inline-flex", width: "100%" }}
                  >
                    <h2 style={{ marginLeft: "20px", color: "gray" }}>
                      የፕሮጀክት ዝርዝር
                    </h2>
                    <input
                      type="button"
                      id="register"
                      value="Cancel"
                      className="btn1"
                      style={{
                        margin: "auto",
                        width: "70px",
                        float: "right",
                      }}
                      onClick={() => setOpenDialog(false)}
                    />
                  </div>
                  {isLoading2 ? (
                    <div style={{ width: "100%", textAlign: "center" }}>
                      <img
                        src={loading2}
                        alt="loading..."
                        style={{ width: "50px", opacity: "0.3" }}
                      />
                    </div>
                  ) : (
                    <>
                      {dialog != "" ? (
                        dialog.map((diag) => (
                          <div
                            className="brdr-btm list-hover"
                            key={diag[0]}
                            style={{
                              cursor: "pointer",
                              width: "100%",
                            }}
                          >
                            <div
                              style={{
                                padding: "10px 20px",
                                display: "inline-flex",
                              }}
                            >
                              <h3
                                onClick={() => {
                                  setProject(diag[1] + ", " + diag[2]);
                                  setProjectId(diag[0]);
                                  setOpenDialog(false);
                                }}
                                style={{
                                  width: "100%",
                                  margin: 0,
                                  color: "black",
                                  fontWeight: "normal",
                                  marginLeft: "5px",
                                }}
                              >
                                {diag[1] + ", " + diag[2]}
                              </h3>
                            </div>
                          </div>
                        ))
                      ) : (
                        <p style={{ marginLeft: "40px" }}>No record</p>
                      )}
                    </>
                  )}
                </div>
              </div>
            </>
          ) : (
            ""
          )}

          <div
            className="cont-all"
            style={{
              width: "90%",
              height: "95%",
              padding: "0",
              overflow: "auto",
              scrollbarWidth: "thin",
            }}
          >
            <div
              style={{
                width: "100%",
                backgroundColor: "rgb(240,240,240)",
                margin: "0",
                display: "inline-flex",
                position: "sticky",
                top: 0,
              }}
            >
              <h5
                style={{
                  margin: "0",
                  color: "black",
                  borderColor: "gray",
                  borderRight: "solid",
                  borderWidth: "1px",
                  padding: "10px 20px",
                }}
              >
                የቀን ሪፖርት መመዝገቢያ ገፅ
              </h5>
            </div>
            <div
              style={{
                minWidth: "400px",
                width: "100%",
                display: "inline-flex",
                flexWrap: "wrap",
              }}
            >
              <div
                style={{
                  width: "50%",
                  minWidth: "300px",
                }}
              >
                <div
                  style={{
                    marginLeft: "20px",
                    width: "80%",
                    minWidth: "250px",
                  }}
                >
                  <p
                    className="reg-err"
                    id="successMsg"
                    style={{
                      color: "green",
                      display: "none",
                      marginTop: "10px",
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    በተሳካ ሁኔታ ተመዝግቧል!
                  </p>
                  <p className="reg-err" id="reportDayErr">
                    *ይህ መረጃ አስፈላጊ ነው!
                  </p>
                  <label style={{ fontSize: "12px" }}>የሪፖርት ቀን</label>
                  <br />
                  <input
                    className="txt-input"
                    id="reportDay"
                    type="date"
                    onChange={(e) => {
                      setReportDay(e.target.value);

                      e.target.value != "" &&
                        axios
                          .post(
                            "https://www.bdc.et/bdc-api/get_daily_report.php",
                            {
                              reportDay: e.target.value,
                            }
                          )
                          .then((res) => {
                            setData(res.data);
                          });
                    }}
                    required
                  />
                  <br />
                  <p className="reg-err" id="projectErr">
                    *ይህ መረጃ አስፈላጊ ነው!
                  </p>
                  <input
                    type="button"
                    id="project"
                    value={project != "" ? "ፕሮጀክት ለመቀየር" : "ፕሮጀክት ለመምረጥ"}
                    style={{ fontSize: "12px", margin: "10px 0" }}
                    onClick={OpenDialog}
                  />
                  <br />
                  <p style={{ fontSize: "13px" }}>{project}</p>
                  <p className="reg-err" id="dailyPlanErr">
                    *ይህ መረጃ አስፈላጊ ነው!
                  </p>
                  <input
                    className="txt-input"
                    id="dailyPlan"
                    type="number"
                    value={dailyPlan ? dailyPlan : ""}
                    onChange={(e) => {
                      setDailyPlan(e.target.value == "" ? 0 : e.target.value);

                      if (e.target.value == "" || dailyExecution == "") {
                        document.getElementById("performance").value = "0.00 %";
                      } else {
                        document.getElementById("performance").value =
                          (
                            (parseFloat(dailyExecution) /
                              parseFloat(e.target.value)) *
                            100
                          ).toFixed(2) + " %";
                      }
                    }}
                    onBlur={(e) => {
                      if (e.target.value < 0) {
                        e.target.value = "";
                        setDailyPlan("");
                      }
                    }}
                    placeholder="እቅድ (ብር)"
                    required
                  />
                  <br />
                  <p className="reg-err" id="dailyExecutionErr">
                    *ይህ መረጃ አስፈላጊ ነው!
                  </p>
                  <input
                    className="txt-input"
                    id="dailyExecution"
                    type="number"
                    value={dailyExecution ? dailyExecution : ""}
                    onChange={(e) => {
                      setDailyExecution(
                        e.target.value == "" ? 0 : e.target.value
                      );
                      if (e.target.value == "" || dailyPlan == "") {
                        document.getElementById("performance").value = "0.00 %";
                      } else {
                        document.getElementById("performance").value =
                          (
                            (parseFloat(e.target.value) /
                              parseFloat(dailyPlan)) *
                            100
                          ).toFixed(2) + " %";
                      }

                      document.getElementById("profitLoss").value =
                        (
                          (e.target.value == ""
                            ? 0
                            : parseFloat(e.target.value)) -
                          parseFloat(directCost) -
                          parseFloat(indirectCost)
                        ).toFixed(2) + " ብር";
                    }}
                    onBlur={(e) => {
                      if (e.target.value < 0) {
                        e.target.value = "";
                        setDailyExecution("");
                      }
                    }}
                    placeholder="ክንውን (ብር)"
                    required
                  />
                  <br />

                  <label style={{ fontSize: "12px" }}>አፈፃፀም</label>
                  <br />
                  <input
                    className="txt-input"
                    id="performance"
                    type="text"
                    placeholder="0 %"
                    onChange={(e) =>
                      e.target.value ? parseFloat(e.target.value) : "0"
                    }
                    disabled
                  />
                  <br />
                  <input
                    className="txt-input"
                    id="directCost"
                    type="number"
                    value={directCost ? directCost : ""}
                    onChange={(e) => {
                      setDirectCost(e.target.value == "" ? 0 : e.target.value);

                      document.getElementById("profitLoss").value =
                        (
                          parseFloat(dailyExecution) -
                          (e.target.value == ""
                            ? 0
                            : parseFloat(e.target.value)) -
                          parseFloat(indirectCost)
                        ).toFixed(2) + " ብር";
                    }}
                    onBlur={(e) => {
                      if (e.target.value < 0) {
                        e.target.value = "";
                        setDirectCost("");
                      }
                    }}
                    placeholder="ቀጥተኛ ወጭዎች (ብር)"
                  />
                  <br />
                  <input
                    className="txt-input"
                    id="indirectCost"
                    type="number"
                    value={indirectCost ? indirectCost : ""}
                    onChange={(e) => {
                      setIndirectCost(
                        e.target.value == "" ? 0 : e.target.value
                      );

                      document.getElementById("profitLoss").value =
                        (
                          parseFloat(dailyExecution) -
                          parseFloat(directCost) -
                          (e.target.value == ""
                            ? 0
                            : parseFloat(e.target.value))
                        ).toFixed(2) + " ብር";
                    }}
                    onBlur={(e) => {
                      if (e.target.value < 0) {
                        e.target.value = "";
                        setIndirectCost("");
                      }
                    }}
                    placeholder="ቀጥተኛ ያልሆኑ ወጭዎች (ብር)"
                    required
                  />
                  <br />

                  <label style={{ fontSize: "12px" }}>ትርፍ/ ኪሳራ</label>
                  <br />
                  <input
                    className="txt-input"
                    id="profitLoss"
                    type="text"
                    placeholder="0.00 ብር"
                    onChange={(e) =>
                      e.target.value ? parseFloat(e.target.value) : "0"
                    }
                    disabled
                  />
                  <br />

                  <div style={{ display: "inline-flex", marginBottom: "5px" }}>
                    <input
                      type="button"
                      value={"መዝግብ"}
                      className="btn1"
                      style={{ width: "150px" }}
                      onClick={RegiBtn}
                    />
                  </div>
                </div>
              </div>
              <div
                style={{
                  width: "50%",
                  minWidth: "400px",
                }}
              >
                <label style={{ fontSize: "12px" }}>እየተሰሩ ያሉ ስራዎች</label>
                <br />
                <textarea
                  className="txt-input"
                  rows={6}
                  cols={50}
                  value={activity}
                  placeholder="እየተሰሩ ያሉ ስራዎችን ያስገቡ"
                  onChange={(e) => setActivity(e.target.value)}
                />
                <br />
                <label style={{ fontSize: "12px" }}>መፍትሔ የሚሹ ጉዳዮች</label>
                <br />
                <textarea
                  className="txt-input"
                  rows={6}
                  cols={50}
                  value={problem}
                  placeholder="መፍትሔ የሚሹ ጉዳዮችን ያስገቡ"
                  onChange={(e) => setProblem(e.target.value)}
                />
                <br />
              </div>
              <div
                style={{
                  width: "100%",
                  minWidth: "400px",
                }}
              >
                <table
                  style={{
                    width: "100%",
                    marginTop: "20px",
                  }}
                >
                  <tr
                    style={{
                      width: "100%",
                      textAlign: "left",
                      color: "gray",
                    }}
                  >
                    <th>ፕሮጀክት</th>
                    <th>እቅድ (ብር) </th>
                    <th>ክንውን (ብር) </th>
                    <th>አፈፃፀም(%)</th>
                    <th>ወጭ(ብር)</th>
                    <th>ትርፍ/ ኪሳራ (ብር)</th>
                  </tr>
                  {isLoading3 ? (
                    <div style={{ width: "100%", textAlign: "center" }}>
                      <img
                        src={loading2}
                        alt="loading..."
                        style={{ width: "50px", opacity: "0.3" }}
                      />
                    </div>
                  ) : (
                    <>
                      {data != [] &&
                        data.map((list) => (
                          <tr key={list[0]}>
                            <td>{list[3]}</td>
                            <td> {parseFloat(list[4]).toLocaleString()}</td>
                            <td> {parseFloat(list[5]).toLocaleString()}</td>
                            <td>
                              {(
                                (parseFloat(list[5]) / parseFloat(list[4])) *
                                100
                              ).toFixed(2)}
                            </td>
                            <td>
                              {(
                                parseFloat(list[10]) + parseFloat(list[11])
                              ).toLocaleString()}
                            </td>
                            <td>
                              {(
                                parseFloat(list[5]) -
                                parseFloat(list[10]) -
                                parseFloat(list[11])
                              ).toLocaleString()}
                            </td>
                          </tr>
                        ))}
                      <tr>
                        <td style={{ textAlign: "right", fontWeight: "bold" }}>
                          ድምር
                        </td>
                        <td style={{ fontWeight: "bold" }}>
                          {" "}
                          {data
                            .reduce((totAmt, item) => {
                              return totAmt + parseFloat(item[4]);
                            }, 0)
                            .toLocaleString()}
                        </td>
                        <td style={{ fontWeight: "bold" }}>
                          {" "}
                          {data
                            .reduce((totAmt, item) => {
                              return totAmt + parseFloat(item[5]);
                            }, 0)
                            .toLocaleString()}{" "}
                        </td>
                        <td style={{ fontWeight: "bold" }}>
                          {" "}
                          {(
                            (data.reduce((totAmt, item) => {
                              return totAmt + parseFloat(item[5]);
                            }, 0) /
                              data.reduce((totAmt, item) => {
                                return totAmt + parseFloat(item[4]);
                              }, 0)) *
                            100
                          ).toFixed(2)}
                        </td>
                        <td style={{ fontWeight: "bold" }}>
                          {" "}
                          {data
                            .reduce((totAmt, item) => {
                              return (
                                totAmt +
                                parseFloat(item[10]) +
                                parseFloat(item[11])
                              );
                            }, 0)
                            .toLocaleString()}
                        </td>
                        <td style={{ fontWeight: "bold" }}>
                          {" "}
                          {(
                            data.reduce((totAmt, item) => {
                              return totAmt + parseFloat(item[5]);
                            }, 0) -
                            data.reduce((totAmt, item) => {
                              return (
                                totAmt +
                                parseFloat(item[10]) +
                                parseFloat(item[11])
                              );
                            }, 0)
                          )
                            
                            .toLocaleString()}
                        </td>
                      </tr>
                    </>
                  )}
                </table>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default DailyReportSend;
