import axios from "axios";
import { React, useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel, faPrint } from "@fortawesome/free-solid-svg-icons";
import loading2 from "../img/loading2.gif";
import logo from "../img/logo.png";
import { useReactToPrint } from "react-to-print";
import * as XLSX from "xlsx";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

function DailyReportShow() {
  const printRef = useRef();
  const [isLoading2, setIsLoading2] = useState(false);
  const [isLoading3, setIsLoading3] = useState(false);

  const tab = useSelector((state) => state.auth.tab);
  const current_date = moment(Date()).format("MM-DD-YYYY");
  const [data, setData] = useState([]);
  const [projectId, setProjectId] = useState("");
  const [project, setProject] = useState("All");
  const [openDialog, setOpenDialog] = useState(false);
  const [dialog, setDialog] = useState([]);
  const [regDate1, setRegDate1] = useState("");
  const [regDate2, setRegDate2] = useState("");

  useEffect(() => {
    document.getElementById("windowTitle").textContent = "/ሪፖርት /ወጭ";

    document.getElementById("regDate1Input").value =
      moment().format("MM-DD-YYYY");

    axios
      .post("https://www.bdc.et/bdc-api/get_daily_report_show.php", {
        projectId: projectId,
        regDate1: regDate1,
        regDate2: regDate2,
      })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => alert(err))
      .finally(() => setIsLoading3(false));
  }, []);

  const OpenDialog = () => {
    setIsLoading2(true);
    setOpenDialog(true);
    axios
      .post("https://www.bdc.et/bdc-api/get_project_list.php", {})
      .then((res) => {
        setDialog(res.data);
      })
      .catch((err) => alert(err))
      .finally(() => setIsLoading2(false));
  };

  const HandlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: "Expense settlement report",
    onPrintError: (err) => alert(err),
  });

  const handleExport = () => {
    const table = document.getElementById("dataTable");
    const worksheet = XLSX.utils.table_to_sheet(table);

    worksheet["!cols"] = [
      { wch: 40 },
      { wch: 15 },
      { wch: 15 },
      { wch: 8 },
      { wch: 15 },
      { wch: 15 },
      { wch: 8 },
      { wch: 15 },
      { wch: 15 },
      { wch: 8 },
    ];

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Daily Report");
    XLSX.writeFile(
      workbook,
      "Daily Report" +
        document.getElementById("reportDay").textContent +
        ".xlsx"
    );
  };

  const HandleFilterReport = (projectId, regDate1, regDate2) => {
    setIsLoading3(true);

    axios
      .post("https://www.bdc.et/bdc-api/get_daily_report_show.php", {
        projectId: projectId,
        regDate1: regDate1,
        regDate2: regDate2,
      })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => alert(err))
      .finally(() => setIsLoading3(false));
  };

  function ReportTable() {
    return (
      <table
        id="dataTable"
        style={{
          width: "80%",
          minWidth: "400px",
          margin: "auto",
          textAlign: "left",
          float: "left",
        }}
      >
        <tr
          style={{
            width: "100%",
            textAlign: "left",
            color: "gray",
          }}
        >
          <th>ፕሮጀክት </th>
          <th>እቅድ (ብር)</th>
          <th>ክንውን (ብር)</th>
          <th>አፈፃፀም</th>
          <th>ድርሻ</th>
        </tr>
        {data != [] &&
          data.map((list) => (
            <tr>
              <td>{list[0]}</td>
              <td>
                {parseFloat(parseFloat(list[1]).toFixed(2)).toLocaleString(
                  "en-US",
                  {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }
                )}
              </td>
              <td>
                {parseFloat(parseFloat(list[2]).toFixed(2)).toLocaleString(
                  "en-US",
                  {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }
                )}
              </td>
              <td>
                {((parseFloat(list[2]) / parseFloat(list[1])) * 100).toFixed(
                  2
                ) + "%"}
              </td>
              <td>
                {(
                  (parseFloat(list[2]) /
                    data.reduce((totAmt, item) => {
                      return totAmt + parseFloat(item[2]);
                    }, 0)) *
                  100
                ).toFixed(2) + "%"}
              </td>
            </tr>
          ))}
        <tr>
          <td style={{ textAlign: "right", fontWeight: "bold" }}>ድምር</td>
          <td style={{ textAlign: "left", fontWeight: "bold" }}>
            {data
              .reduce((totAmt, item) => {
                return totAmt + parseFloat(item[1]);
              }, 0)
              .toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
          </td>
          <td style={{ textAlign: "left", fontWeight: "bold" }}>
            {data
              .reduce((totAmt, item) => {
                return totAmt + parseFloat(item[2]);
              }, 0)
              .toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
          </td>
          <td style={{ textAlign: "left", fontWeight: "bold" }}>
            {data[0] &&
              (
                (data.reduce((totAmt, item) => {
                  return totAmt + parseFloat(item[2]);
                }, 0) /
                  data.reduce((totAmt, item) => {
                    return totAmt + parseFloat(item[1]);
                  }, 0)) *
                100
              ).toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) + "%"}
          </td>
          <td style={{ textAlign: "left", fontWeight: "bold" }}>
            {data[0] && "100.00%"}
          </td>
        </tr>
      </table>
    );
  }

  function BarChartComponent() {
    const formattedData = data.map((item) => ({
      name: item[0],
      Plan: item[1],
      Revenue: item[2],
    }));

    const maxValue = Math.max(
      ...formattedData.map((item) => Math.max(item.Plan, item.Revenue))
    );

    const truncate = (str, length = 10) =>
      str.length > length ? `${str.substring(0, length)}...` : str;

    const barWidth = data.length * 200 < 700 ? data.length * 200 : 700;

    return (
      <div
        style={{
          width: "80%",
          height: "400px",
          minWidth: "400px",
          margin: "20px auto",
          textAlign: "left",
          float: "left",
        }}
      >
        <ResponsiveContainer width={barWidth} height={400}>
          <BarChart
            data={formattedData}
            margin={{ bottom: 50, top: 10, left: 30, right: 20 }}
          >
            <XAxis
              dataKey="name"
              angle={45}
              textAnchor="start"
              tickFormatter={(value) => truncate(value, 10)}
              style={{ fontSize: "12px" }}
            />

            <YAxis
              style={{ fontSize: "12px" }}
              domain={[0, maxValue * 1.1]}
              tickCount={10}
              tickFormatter={(value) => {
                return parseFloat(`${value.toFixed(2)}`).toLocaleString();
              }}
            />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip />
            <Legend verticalAlign="top" align="right" />
            <Bar dataKey="Plan" fill="rgb(22, 51, 105)" />
            <Bar dataKey="Revenue" fill="green" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  }

  return (
    <>
      {tab.includes("tab51") && (
        <>
          {openDialog ? (
            <>
              <div className="dialog-ext"></div>
              <div style={{ width: "95%", zIndex: 2, position: "absolute" }}>
                <div
                  className="dialog-cont"
                  style={{
                    maxHeight: "600px",
                    overflowY: "auto",
                    scrollbarWidth: "thin",
                    width: "550px",
                  }}
                >
                  <div
                    className="brdr-btm"
                    style={{ display: "inline-flex", width: "100%" }}
                  >
                    <h2 style={{ marginLeft: "20px", color: "gray" }}>
                      የፕሮጀክት ዝርዝር
                    </h2>
                    <input
                      type="button"
                      id="register"
                      value="Cancel"
                      className="btn1"
                      style={{
                        margin: "auto",
                        width: "70px",
                        float: "right",
                      }}
                      onClick={() => setOpenDialog(false)}
                    />
                  </div>
                  {isLoading2 ? (
                    <div style={{ width: "100%", textAlign: "center" }}>
                      <img
                        src={loading2}
                        alt="loading..."
                        style={{ width: "50px", opacity: "0.3" }}
                      />
                    </div>
                  ) : (
                    <>
                      <div
                        className="brdr-btm list-hover"
                        style={{
                          cursor: "pointer",
                          width: "100%",
                        }}
                      >
                        <div
                          style={{
                            padding: "10px 20px",
                            display: "inline-flex",
                          }}
                        >
                          <h3
                            onClick={() => {
                              setProject("All");
                              setProjectId("");
                              HandleFilterReport("", regDate1, regDate2);
                              setOpenDialog(false);
                            }}
                            style={{
                              width: "100%",
                              margin: 0,
                              color: "black",
                              fontWeight: "normal",
                              marginLeft: "5px",
                            }}
                          >
                            All
                          </h3>
                        </div>
                      </div>

                      {dialog != "" ? (
                        dialog.map((diag) => (
                          <div
                            className="brdr-btm list-hover"
                            key={diag[0]}
                            style={{
                              cursor: "pointer",
                              width: "100%",
                            }}
                          >
                            <div
                              style={{
                                padding: "10px 20px",
                                display: "inline-flex",
                              }}
                            >
                              <h3
                                onClick={() => {
                                  setProject(diag[1] + ", " + diag[2]);
                                  setProjectId(diag[0]);
                                  HandleFilterReport(
                                    diag[0],
                                    regDate1,
                                    regDate2
                                  );
                                  setOpenDialog(false);
                                }}
                                style={{
                                  width: "100%",
                                  margin: 0,
                                  color: "black",
                                  fontWeight: "normal",
                                  marginLeft: "5px",
                                }}
                              >
                                {diag[1] + ", " + diag[2]}
                              </h3>
                            </div>
                          </div>
                        ))
                      ) : (
                        <p style={{ marginLeft: "40px" }}>No record</p>
                      )}
                    </>
                  )}
                </div>
              </div>
            </>
          ) : (
            ""
          )}

          {/*** Print container ======================================================================================= */}
          <div style={{ display: "none" }}>
            <div ref={printRef} style={{ width: "100%" }}>
              <div
                style={{
                  width: "100%",
                  height: "130px",
                  display: "inline-flex",
                  backgroundColor: "white",
                }}
              >
                <div
                  style={{
                    width: "20%",
                    marginLeft: "20px",
                    textAlign: "center",
                  }}
                >
                  <img src={logo} style={{ width: "100px" }} alt="logo" />
                  <p
                    style={{
                      fontSize: "10px",
                      color: "green",
                      marginTop: "-30px",
                    }}
                  >
                    ቢዲሲ ኮንስትራክሽን
                  </p>
                </div>
                <div>
                  <h3
                    style={{
                      width: "80%",
                      textAlign: "left",
                      paddingLeft: "135px",
                      marginTop: "40px",
                    }}
                  >
                    የፕሮጀክቶች የቀን የገቢ ሪፖርት
                  </h3>
                  <p
                    style={{
                      width: "80%",
                      textAlign: "left",
                      paddingLeft: "160px",
                      marginTop: "-10px",
                    }}
                  >
                    Project Daily Revenue Report
                  </p>
                </div>
              </div>
              <div
                style={{
                  textAlign: "left",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "inline-flex",
                    flexWrap: "wrap",
                    marginLeft: "40px",
                    fontSize: "12px",
                  }}
                >
                  <h4>ፕሮጀክት:</h4>
                  <p style={{ margin: "auto", marginLeft: "10px" }}>
                    {project}
                  </p>
                  <h4
                    style={{
                      margin: "auto",
                      marginLeft: "40px",
                    }}
                  >
                    የሪፖርት ቀን:
                  </h4>
                  <p style={{ margin: "auto", marginLeft: "10px" }}>
                    {" "}
                    {regDate1 == "" && regDate2 == "" && current_date}
                    {regDate1 != "" &&
                      regDate2 == "" &&
                      "> " + moment(regDate1).format("MM-DD-YYYY")}
                    {regDate1 == "" &&
                      regDate2 != "" &&
                      "< " + moment(regDate2).format("MM-DD-YYYY")}
                    {regDate1 != "" &&
                      regDate2 != "" &&
                      moment(regDate1).format("MM-DD-YYYY") +
                        " እስከ " +
                        moment(regDate2).format("MM-DD-YYYY")}
                  </p>
                </div>
                <ReportTable />
                <BarChartComponent />
              </div>
            </div>
          </div>
          {/***       Main container =============================================================== */}

          <div
            className="cont-all"
            style={{
              width: "95%",
              minWidth: "400px",
              height: "97%",
              paddingTop: "0",
              overflow: "auto",
              scrollbarWidth: "thin",
            }}
          >
            <div
              className="brdr-btm"
              style={{
                width: "100%",
                height: "45px",
                backgroundColor: "rgb(240,240,240)",
                margin: "0",
                display: "inline-flex",
                position: "sticky",
                top: 0,
              }}
            >
              <h5
                style={{
                  margin: "0",
                  color: "black",
                  borderColor: "white",
                  borderRight: "solid",
                  borderWidth: "1px",
                  padding: "10px 20px",
                }}
              >
                የፕሮጀክቶች የቀን የገቢ ሪፖርት ማውጫ ገፅ
              </h5>
              <p
                style={{
                  color: "balck",
                  marginLeft: "20px",
                  fontSize: "12px",
                }}
              >
                ቀን: {current_date}
              </p>
            </div>
            <br />
            <div
              id="resourceCont"
              style={{
                width: "100%",
                backgroundColor: "white",
                paddingTop: "10px",
              }}
            >
              <div
                style={{
                  borderStyle: "solid",
                  borderWidth: "1px",
                  borderColor: "lightgray",
                  borderWidth: "1px",
                  margin: "10px 20px 10px 10px",
                  padding: "20px 10px 10px 10px",
                  alignItems: "center",
                }}
              >
                <p
                  style={{
                    marginTop: "-30px",
                    marginLeft: "15px",
                    fontSize: "14px",
                    backgroundColor: "white",
                    padding: "5px",
                    width: "85px",
                    color: "gray",
                  }}
                >
                  Filter Report
                </p>
                <div
                  style={{
                    display: "inline-flex",
                    flexWrap: "wrap",
                    minWidth: "400px",
                  }}
                >
                  <input
                    type="button"
                    id="register"
                    value={project != "All" ? "ፕሮጀክት ለመቀየር" : "ፕሮጀክት ለመምረጥ"}
                    style={{
                      width: "150px",
                      margin: "auto",
                      cursor: "pointer",
                      marginLeft: "20px",
                    }}
                    onClick={OpenDialog}
                  />
                  <div style={{ display: "inline-flex" }}>
                    <p
                      style={{
                        fontSize: "14px",
                        margin: "auto",
                        padding: 0,
                        marginLeft: "40px",
                      }}
                    >
                      ከ
                    </p>

                    <input
                      type="date"
                      id="regDate1Input"
                      value={regDate1}
                      className="txt-input"
                      style={{ width: "200px", marginLeft: "5px" }}
                      onChange={(e) => {
                        setRegDate1(e.target.value);
                        HandleFilterReport(projectId, e.target.value, regDate2);
                      }}
                    />
                  </div>

                  <br />
                  <div style={{ display: "inline-flex" }}>
                    <p
                      style={{
                        fontSize: "14px",
                        margin: "auto",
                        padding: 0,
                        marginLeft: "10px",
                      }}
                    >
                      እስከ
                    </p>

                    <input
                      type="date"
                      id="regDate2Input"
                      className="txt-input"
                      style={{ width: "200px", marginLeft: "5px" }}
                      onChange={(e) => {
                        setRegDate2(e.target.value);
                        HandleFilterReport(projectId, regDate1, e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div
              style={{
                width: "80%",
                display: "inline-flex",
                fontSize: "14px",
              }}
            >
              <div
                style={{
                  display: "inline-flex",
                  flexWrap: "wrap",
                  marginLeft: "40px",
                }}
              >
                <h4>ፕሮጀክት:</h4>
                <p style={{ margin: "auto", marginLeft: "10px" }}>{project}</p>
                <h4
                  style={{
                    margin: "auto",
                    marginLeft: "40px",
                  }}
                >
                  የሪፖርት ቀን:
                </h4>
                <p
                  id="reportDay"
                  style={{ margin: "auto", marginLeft: "10px" }}
                >
                  {" "}
                  {regDate1 == "" && regDate2 == "" && current_date}
                  {regDate1 != "" &&
                    regDate2 == "" &&
                    "> " + moment(regDate1).format("MM-DD-YYYY")}
                  {regDate1 == "" &&
                    regDate2 != "" &&
                    "< " + moment(regDate2).format("MM-DD-YYYY")}
                  {regDate1 != "" &&
                    regDate2 != "" &&
                    moment(regDate1).format("MM-DD-YYYY") +
                      " እስከ " +
                      moment(regDate2).format("MM-DD-YYYY")}
                </p>
              </div>
              <div
                style={{
                  margin: "auto",
                  marginRight: "10px",
                  fontSize: "20px",
                  color: "green",
                }}
              >
                <FontAwesomeIcon
                  style={{
                    cursor: "pointer",
                  }}
                  icon={faPrint}
                  onClick={HandlePrint}
                />
                <FontAwesomeIcon
                  style={{
                    marginLeft: "20px",
                    cursor: "pointer",
                  }}
                  icon={faFileExcel}
                  onClick={handleExport}
                />
              </div>
            </div>
            <br />
            <ReportTable />
            <BarChartComponent />
          </div>
        </>
      )}
    </>
  );
}
export default DailyReportShow;
