import { createSlice } from "@reduxjs/toolkit";
import { act } from "react-dom/test-utils";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    isLogged: false,
    fullName: "",
    empId: "",
    tab: [],
    dep: "",
    position: "",
  },
  reducers: {
    login(state) {
      state.isLogged = true;
    },
    logout(state) {
      state.isLogged = false;
    },
    setUser(state, action) {
      state.fullName = action.payload.fullName;
      state.empId = action.payload.id;
      state.idNumber = action.payload.idNumber;
      state.dep = action.payload.dep;
      state.phoneNumber = action.payload.phoneNumber;
      state.position = action.payload.position;
    },
    setRole(state, action) {
      state.tab = action.payload.tab;
    },
  },
});

export const authAction = authSlice.actions;
export default authSlice;
