import axios from "axios";
import { React, useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import loading1 from "../img/loading1.gif";
import ppDefault from "../../src/img/profile-default.jpg";

import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

function EmpRegistration() {
  const [isLoading, setIsLoading] = useState(false);
  const tab = useSelector((state) => state.auth.tab);
  const loggedId = useSelector((state) => state.auth.empId);
  const [fName, setFName] = useState("");
  const [mName, setMName] = useState("");
  const [lName, setLName] = useState("");
  const [sex, setSex] = useState("Male");
  const [birthDate, setBirthDate] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [emailAdd, setEmailAdd] = useState("");
  const [idNumber, setIdNumber] = useState("");
  const [empStatus, setEmpStatus] = useState("");
  const [departmentData, setDepartmentData] = useState([]);
  const [department, setDepartment] = useState("");
  const [section, setSection] = useState("");
  const [position, setPosition] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const [openDialog, setOpenDialog] = useState(false);
  const [imageSrc, setImageSrc] = useState(""); // State for image source
  const [crop, setCrop] = useState();
  const imgRef = useRef(null);
  const [cropImageSrc, setCropImageSrc] = useState(); // Initial crop aspect ratio
  const MIN_DIM = 150;
  const ASPECT_RATIO = 1;

  useEffect(() => {
    document.getElementById("windowTitle").textContent =
      "/የሰው ሃይል /ሰራተኛ ለመመዝገብ";

    const chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < 5; i++) {
      result += chars.charAt(Math.floor(Math.random() * chars.length));
    }

    setPassword(result);

    axios
      .post("https://www.bdc.et/bdc-api/get_department_list.php", {})
      .then((res) => setDepartmentData(res.data))
      .catch((err) => alert(err));
  }, []);

  const RegiBtn = () => {
    if (fName == "") {
      document.getElementById("fNameErr").style.display = "block";
      document.getElementById("fName").style.borderColor = "red";
    } else {
      document.getElementById("fNameErr").style.display = "none";
      document.getElementById("fName").style.borderColor = "lightgray";
    }
    if (mName == "") {
      document.getElementById("mNameErr").style.display = "block";
      document.getElementById("mName").style.borderColor = "red";
    } else {
      document.getElementById("mNameErr").style.display = "none";
      document.getElementById("mName").style.borderColor = "lightgray";
    }
    if (lName == "") {
      document.getElementById("lNameErr").style.display = "block";
      document.getElementById("lName").style.borderColor = "red";
    } else {
      document.getElementById("lNameErr").style.display = "none";
      document.getElementById("lName").style.borderColor = "lightgray";
    }
    if (birthDate == "") {
      document.getElementById("birthDateErr").style.display = "block";
      document.getElementById("birthDate").style.borderColor = "red";
    } else {
      document.getElementById("birthDateErr").style.display = "none";
      document.getElementById("birthDate").style.borderColor = "lightgray";
    }
    if (phoneNumber == "") {
      document.getElementById("phoneNumberErr").style.display = "block";
      document.getElementById("phoneNumber").style.borderColor = "red";
    } else {
      if (document.getElementById("phoneNumber").value.length != 10) {
        document.getElementById("phoneNumberErr").textContent =
          "Wrong phone number digits. Please use the format 09...";
        document.getElementById("phoneNumberErr").style.display = "block";
        document.getElementById("phoneNumber").style.borderColor = "red";
      } else {
        document.getElementById("phoneNumberErr").style.display = "none";
        document.getElementById("phoneNumber").style.borderColor = "lightgray";
      }
    }
    if (idNumber == "") {
      document.getElementById("idNumberErr").style.display = "block";
      document.getElementById("idNumber").style.borderColor = "red";
    } else {
      document.getElementById("idNumberErr").style.display = "none";
      document.getElementById("idNumber").style.borderColor = "lightgray";
    }
    if (empStatus == "") {
      document.getElementById("empStatusErr").style.display = "block";
      document.getElementById("empStatus").style.borderColor = "red";
    } else {
      document.getElementById("empStatusErr").style.display = "none";
      document.getElementById("empStatus").style.borderColor = "lightgray";
    }
    if (department == "") {
      document.getElementById("departmentErr").style.display = "block";
      document.getElementById("department").style.borderColor = "red";
    } else {
      document.getElementById("departmentErr").style.display = "none";
      document.getElementById("department").style.borderColor = "lightgray";
    }
    if (position == "") {
      document.getElementById("positionErr").style.display = "block";
      document.getElementById("position").style.borderColor = "red";
    } else {
      document.getElementById("positionErr").style.display = "none";
      document.getElementById("position").style.borderColor = "lightgray";
    }

    if (password == "") {
      document.getElementById("passwordErr").style.display = "block";
      document.getElementById("password").style.borderColor = "red";
    } else {
      document.getElementById("passwordErr").style.display = "none";
      document.getElementById("password").style.borderColor = "lightgray";
    }

    if (
      fName != "" &&
      mName != "" &&
      lName != "" &&
      birthDate != "" &&
      phoneNumber != "" &&
      phoneNumber.length == 10 &&
      idNumber != "" &&
      empStatus != "" &&
      department != "" &&
      position != "" &&
      password != ""
    ) {
      setIsLoading(true);
      axios
        .post("https://www.bdc.et/bdc-api/emp_registration.php", {
          first_name: fName,
          middle_name: mName,
          last_name: lName,
          sex: sex,
          birth_date: birthDate,
          phone_number: phoneNumber,
          email_add: emailAdd,
          id_number: idNumber,
          emp_status: empStatus,
          department: department,
          section: section,
          position: position,
          password: password,
          regBy: loggedId,
          pImage: cropImageSrc,
        })
        .then((res) => {
          if (res.data == 1) {
            document.getElementById("idNumberErr2").style.display = "block";
          } else {
            document.getElementById("idNumberErr2").style.display = "none";
            navigate("/employee_confirmation", {
              state: {
                fullName: fName + " " + mName + " " + lName,
                id: res.data[0][0],
                idNumber: idNumber,
                department: department,
              },
            });
          }
        })
        .catch((err) => alert(err))
        .finally(() => setIsLoading(false));
    }
  };

  /** Image upload======================================================================================= */
  const OnSelect = (e) => {
    const file = e.target.files?.[0];
    if (!file) return;

    const reader = new FileReader();
    reader.addEventListener("load", () => {
      const imageUrl = reader.result?.toString() || "";
      setImageSrc(imageUrl);
      setOpenDialog(true);
    });
    reader.readAsDataURL(file);
  };

  const OnImageLoad = (e) => {
    const { width, height } = e.currentTarget;

    const crop = makeAspectCrop(
      {
        unit: "px",
        width: 300,
      },
      1,
      width,
      height
    );
    const centeredCrop = centerCrop(crop, width, height);
    setCrop(centeredCrop);
  };

  const makeCroppedImage = () => {
    const img = new Image();
    img.src = imageSrc;

    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    const pixelRatio = window.devicePixelRatio;
    const scaleX = img.naturalWidth / imgRef.current.width;
    const scaleY = img.naturalHeight / imgRef.current.height;

    canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
    canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

    ctx.scale(pixelRatio, pixelRatio);
    ctx.imageSmoothingQuality = "high";
    ctx.save();

    const cropX = crop.x * scaleX;
    const cropY = crop.y * scaleY;

    ctx.translate(-cropX, -cropY);
    ctx.drawImage(
      img,
      0,
      0,
      img.naturalWidth,
      img.naturalHeight,
      0,
      0,
      img.naturalWidth,
      img.naturalHeight
    );
    console.log(cropX);
    console.log(cropY);

    return canvas;
  };

  const SubmitCrop = () => {
    const pixelCrop = makeCroppedImage();

    setCropImageSrc(pixelCrop.toDataURL("image/jpeg"));

    setOpenDialog(false);
  };

  return (
    <>
      {tab.includes("tab11") && (
        <>
          {openDialog ? (
            <>
              <div className="dialog-ext"></div>
              <div
                style={{
                  width: "90%",
                  height: "100%",
                  zIndex: 2,
                  position: "absolute",
                  textAlign: "center",
                  marginLeft: "-150px",
                }}
              >
                <div
                  className="dialog-cont"
                  style={{
                    width: "50%",
                    height: "90%",
                    maxWidth: "90%",
                    maxHeight: "90%",
                    textAlign: "center",
                    overflow: "auto",
                  }}
                >
                  <h2
                    style={{
                      paddingLeft: "20px",
                      color: "gray",
                      textAlign: "left",
                    }}
                  >
                    Crop image
                  </h2>
                  {imageSrc && (
                    <>
                      <ReactCrop
                        crop={crop}
                        onChange={(pixelCrop) => {
                          setCrop(pixelCrop);
                        }}
                        circularCrop
                        keepSelection
                        aspect={ASPECT_RATIO}
                        minWidth={MIN_DIM}
                      >
                        <img
                          ref={imgRef}
                          style={{
                            maxWidth: "100%",
                            maxHeight: "60vh",
                          }}
                          src={imageSrc}
                          alt="profile image"
                          onLoad={OnImageLoad}
                        />
                      </ReactCrop>
                      <br />
                      <div style={{ display: "inline-flex" }}>
                        <button
                          onClick={SubmitCrop}
                          className="btn1"
                          style={{ width: "100px", marginBottom: "10px" }}
                        >
                          Crop Image
                        </button>
                        <button
                          onClick={() => {
                            setOpenDialog(false);
                          }}
                          className="btn2"
                          style={{
                            width: "100px",
                            marginBottom: "10px",
                            marginLeft: "20px",
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </>
          ) : (
            ""
          )}

          <div
            className="cont-all"
            style={{
              width: "70%",
              minWidth: "600px",
              height: "97%",
              overflowY: "auto",
            }}
          >
            <h5
              className="pa-h"
              style={{
                backgroundColor: "rgb(240,240,240)",
                margin: 0,
                padding: "5px 0px",
                width: "100%",
                color: "black",
              }}
            >
              የሰራተኛ መረጃ
            </h5>
            <div style={{ display: "inline-flex", width: "100%" }}>
              <div style={{ width: "50%" }}>
                <div className="cont2">
                  <div
                    className="brdr-all"
                    style={{
                      width: "150px",
                      height: "150px",
                      borderRadius: "60%",
                      margin: "20px auto",
                      float: "center",
                    }}
                  >
                    <img
                      src={cropImageSrc ? cropImageSrc : ppDefault}
                      alt="profile-picture"
                      style={{
                        width: "140px",
                        height: "140px",
                        borderRadius: "60%",
                        margin: "5px",
                        float: "center",
                      }}
                    />
                  </div>
                  <label htmlFor="file-input" style={{ width: "100%" }}>
                    <p
                      style={{
                        margin: 0,
                        padding: 0,
                        marginTop: "-15px",
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: "green",
                        textAlign: "center",
                        cursor: "pointer",
                      }}
                    >
                      {cropImageSrc ? "የሰራተኛ ፎቶ ቀይር" : "የሰራተኛ ፎቶ ምረጥ"}
                    </p>
                    <input
                      id="file-input"
                      type="file"
                      accept="image/*"
                      onChange={OnSelect}
                      style={{ display: "none" }}
                    />
                  </label>
                  <br />
                  <p className="reg-err" id="fNameErr">
                    *ይህ መረጃ አስፈላጊ ነው!
                  </p>
                  <input
                    className="txt-input"
                    id="fName"
                    type="text"
                    onChange={(e) => {
                      const word = document.getElementById("fName").value;
                      const firstLetter = word.charAt(0);
                      const firstLetterCap = firstLetter.toUpperCase();
                      const remainingLetters = word.slice(1);
                      const remainingLettersLow =
                        remainingLetters.toLowerCase();
                      const capitalizedWord =
                        firstLetterCap + remainingLettersLow;
                      document.getElementById("fName").value = capitalizedWord;
                      setFName(e.target.value);
                    }}
                    placeholder="የሰራተኛ ስም"
                    required
                  />
                  <br />
                  <p className="reg-err" id="mNameErr">
                    *ይህ መረጃ አስፈላጊ ነው!
                  </p>
                  <input
                    className="txt-input"
                    id="mName"
                    type="text"
                    onChange={(e) => {
                      const word = document.getElementById("mName").value;
                      const firstLetter = word.charAt(0);
                      const firstLetterCap = firstLetter.toUpperCase();
                      const remainingLetters = word.slice(1);
                      const remainingLettersLow =
                        remainingLetters.toLowerCase();
                      const capitalizedWord =
                        firstLetterCap + remainingLettersLow;
                      document.getElementById("mName").value = capitalizedWord;
                      setMName(e.target.value);
                    }}
                    placeholder="የሰራተኛ የአባት ስም"
                    required
                  />
                  <br />
                  <p className="reg-err" id="lNameErr">
                    *ይህ መረጃ አስፈላጊ ነው!
                  </p>
                  <input
                    className="txt-input"
                    id="lName"
                    type="text"
                    onChange={(e) => {
                      const word = document.getElementById("lName").value;
                      const firstLetter = word.charAt(0);
                      const firstLetterCap = firstLetter.toUpperCase();
                      const remainingLetters = word.slice(1);
                      const remainingLettersLow =
                        remainingLetters.toLowerCase();
                      const capitalizedWord =
                        firstLetterCap + remainingLettersLow;
                      document.getElementById("lName").value = capitalizedWord;
                      setLName(e.target.value);
                    }}
                    placeholder="የሰራተኛ የአያት ስም"
                    required
                  />
                  <br />
                  <label>ፆታ</label>
                  <br />
                  <select
                    name="sex"
                    onChange={(e) => setSex(e.target.value)}
                    className="txt-input"
                    style={{ width: "200px" }}
                  >
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </select>
                  <br />
                  <p className="reg-err" id="birthDateErr">
                    *ይህ መረጃ አስፈላጊ ነው!
                  </p>
                  <label>የትውልድ ቀን</label>
                  <br />
                  <input
                    className="txt-input"
                    id="birthDate"
                    type="date"
                    onChange={(e) => setBirthDate(e.target.value)}
                    required
                  />
                  <br />
                </div>
              </div>
              <div className="cont-left" style={{ width: "50%" }}>
                <p className="reg-err" id="phoneNumberErr">
                  *ይህ መረጃ አስፈላጊ ነው!
                </p>
                <input
                  className="txt-input"
                  id="phoneNumber"
                  type="number"
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  placeholder="ስልክ ቁጥር (09...)"
                  required
                />
                <br />
                <input
                  className="txt-input"
                  id="emailAdd"
                  type="email"
                  onChange={(e) => setEmailAdd(e.target.value)}
                  placeholder="ኢሜል (አማራጭ)"
                />
                <br />

                <hr
                  style={{
                    borderStyle: "none",
                    borderTopStyle: "solid",
                    borderWidth: "1px",
                    color: "lightgray",
                  }}
                />
                <p className="reg-err" id="idNumberErr">
                  *ይህ መረጃ አስፈላጊ ነው!
                </p>
                <p className="reg-err" id="idNumberErr2">
                  *በዚህ የመታወቅያ ቁጥር ሌላ ሰራተኛ ተመዝግቧል እባክዎን ሌላ ይጠቀሙ!
                </p>
                <input
                  className="txt-input"
                  id="idNumber"
                  type="text"
                  onChange={(e) => setIdNumber(e.target.value)}
                  placeholder="የኩባንያ መታወቅያ ቁጥር"
                  required
                />

                <br />
                <p className="reg-err" id="empStatusErr">
                  *ይህ መረጃ አስፈላጊ ነው!
                </p>
                <select
                  name="empStatus"
                  id="empStatus"
                  onChange={(e) => setEmpStatus(e.target.value)}
                  className="txt-input"
                  style={{ width: "98%" }}
                >
                  <option style={{ color: "gray" }} value="">
                    የቅጥር ሁኔታ
                  </option>
                  <option value="Permanent">Permanent</option>
                  <option value="Contract">Contract</option>
                  <option value="Part Time">Part Time</option>
                  <option value="Remote">Remote</option>
                </select>
                <br />
                <p className="reg-err" id="departmentErr">
                  *ይህ መረጃ አስፈላጊ ነው!
                </p>
                <select
                  name="department"
                  id="department"
                  onChange={(e) => {
                    setDepartment(e.target.value);
                  }}
                  className="txt-input"
                  style={{ width: "98%" }}
                >
                  <option style={{ color: "gray" }} value="">
                    የተመደቡበት መምርያ
                  </option>
                  {departmentData &&
                    departmentData.map((dep) => (
                      <option value={dep[1]}>{dep[1]}</option>
                    ))}
                </select>
                <br />
                <input
                  className="txt-input"
                  id="section"
                  type="text"
                  onChange={(e) => setSection(e.target.value)}
                  placeholder="የተመደቡበት ክፍል (አማራጭ)"
                  required
                />
                <br />
                <p className="reg-err" id="positionErr">
                  *ይህ መረጃ አስፈላጊ ነው!
                </p>
                <input
                  className="txt-input"
                  id="position"
                  type="text"
                  onChange={(e) => setPosition(e.target.value)}
                  placeholder="የተመደቡበት የስራ መደብ"
                  required
                />
                <br />
                <br />
                <br />
                <label style={{ marginTop: "20px" }}>የሚስጥር ቁጥር</label>
                <p className="reg-err" id="passwordErr">
                  *ይህ መረጃ አስፈላጊ ነው!
                </p>
                <input
                  className="txt-input"
                  id="password"
                  type="text"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Enter Employee Login Password"
                  required
                />
                <br />
                {isLoading ? (
                  <div style={{ width: "100%", textAlign: "center" }}>
                    <img
                      src={loading1}
                      alt="login..."
                      style={{ width: "100px" }}
                    />
                  </div>
                ) : (
                  <input
                    type="button"
                    id="register"
                    value="መዝግብ"
                    className="btn1"
                    style={{ margin: "20px 0" }}
                    onClick={RegiBtn}
                  />
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default EmpRegistration;
