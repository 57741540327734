import axios from "axios";
import { React, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import loading1 from "../img/loading1.gif";
import loading2 from "../img/loading2.gif";

function NewProject() {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const tab = useSelector((state) => state.auth.tab);
  const loggedId = useSelector((state) => state.auth.empId);
  const [data, setData] = useState([]);
  const [name, setName] = useState("");
  const [location, setLocation] = useState("");
  const [client, setClient] = useState("");
  const [consultant, setConsultant] = useState("");
  const [contractAmount, setContractAmount] = useState(0);
  const [contractDuration, setContractDuration] = useState(0);
  const [signDate, setSignDate] = useState("");
  const [handoverDate, setHandoverDate] = useState("");
  const [status, setStatus] = useState("Active");
  const [displayBox, setDisplayBox] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    document.getElementById("windowTitle").textContent = "/ፕሮጀክት /ፕሮጀክት ለመመዝገብ";
  }, []);

  const RegiBtn = () => {
    if (name == "") {
      document.getElementById("nameErr").style.display = "block";
      document.getElementById("name").style.borderColor = "red";
    } else {
      document.getElementById("nameErr").style.display = "none";
      document.getElementById("name").style.borderColor = "lightgray";
    }
    if (location == "") {
      document.getElementById("locationErr").style.display = "block";
      document.getElementById("location").style.borderColor = "red";
    } else {
      document.getElementById("locationErr").style.display = "none";
      document.getElementById("location").style.borderColor = "lightgray";
    }
    if (client == "") {
      document.getElementById("clientErr").style.display = "block";
      document.getElementById("client").style.borderColor = "red";
    } else {
      document.getElementById("clientErr").style.display = "none";
      document.getElementById("client").style.borderColor = "lightgray";
    }
    if (contractAmount == "0") {
      document.getElementById("contractAmountErr").style.display = "block";
      document.getElementById("contractAmount").style.borderColor = "red";
    } else {
      document.getElementById("contractAmountErr").style.display = "none";
      document.getElementById("contractAmount").style.borderColor = "lightgray";
    }

    if (name != "" && location != "" && client != "" && contractAmount != 0) {
      setIsLoading(true);
      setIsLoading2(true);
      axios
        .post("https://www.bdc.et/bdc-api/post_new_project.php", {
          name: name,
          location: location,
          client: client,
          consultant: consultant,
          contractAmount: contractAmount,
          contractDuration: contractDuration,
          signDate: signDate,
          handoverDate: handoverDate,
          regBy: loggedId,
          status: status,
        })
        .then((res) => {
          setData(res.data);
          setDisplayBox(1);
          setName("");
          setLocation("");
          setClient("");
          setConsultant("");
          setContractAmount(0);
          setContractDuration(0);
          setSignDate("");
          setHandoverDate("");
          document.getElementById("name").value = "";
          document.getElementById("location").value = "";
          document.getElementById("client").value = "";
          document.getElementById("consultant").value = "";
          document.getElementById("contractAmount").value = "";
          document.getElementById("contractDuration").value = "";
          document.getElementById("signDate").value = "";
          document.getElementById("handoverDate").value = "";
          document.getElementById("successMsg").style.display = "block";
        })
        .catch((err) => alert(err))
        .finally(() => {
          setIsLoading(false);
          setIsLoading2(false);
        });
    } else {
      document.getElementById("successMsg").style.display = "none";
    }
  };

  return (
    <>
      {tab.includes("tab21") && (
        <>
          <div
            style={{
              display: "inline-flex",
              width: "80%",
              minWidth: "600px",
              height: "97%",
            }}
          >
            <div
              className="cont-all"
              style={{ width: "50%", height: "97%", overflowY: "auto" }}
            >
              <h5
                className="pa-h"
                style={{
                  backgroundColor: "rgb(240,240,240)",
                  color: "black",
                  margin: 0,
                  padding: "5px 0px",
                  width: "100%",
                  position: "sticky",
                  top: 0,
                }}
              >
                የፕሮጀክት መረጃ
              </h5>
              <p
                id="successMsg"
                style={{
                  display: "none",
                  color: "green",
                  marginTop: "10px",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                ምዝገባው ተሳክቷል!{" "}
              </p>
              <div style={{ width: "100%" }} id="regCont">
                <div className="cont2">
                  <p className="reg-err" id="nameErr">
                    *ይህ መረጃ አስፈላጊ ነው!
                  </p>
                  <input
                    className="txt-input"
                    id="name"
                    type="text"
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    onKeyDown={(e) => {
                      if (e.key == "Enter") {
                        document.getElementById("location").focus();
                      }
                    }}
                    placeholder="የፕሮጀክት ስም"
                    required
                  />
                  <br />
                  <p className="reg-err" id="locationErr">
                    *ይህ መረጃ አስፈላጊ ነው!
                  </p>
                  <input
                    className="txt-input"
                    id="location"
                    type="text"
                    onChange={(e) => {
                      setLocation(e.target.value);
                    }}
                    onKeyDown={(e) => {
                      if (e.key == "Enter") {
                        document.getElementById("client").focus();
                      }
                    }}
                    placeholder="የፕሮጀክት ቦታ"
                    required
                  />
                  <br />
                  <p className="reg-err" id="clientErr">
                    *ይህ መረጃ አስፈላጊ ነው!
                  </p>
                  <input
                    className="txt-input"
                    id="client"
                    type="text"
                    onChange={(e) => {
                      setClient(e.target.value);
                    }}
                    onKeyDown={(e) => {
                      if (e.key == "Enter") {
                        document.getElementById("consultant").focus();
                      }
                    }}
                    placeholder="ባለቤት"
                    required
                  />
                  <br />
                  <input
                    className="txt-input"
                    id="consultant"
                    type="text"
                    onChange={(e) => {
                      setConsultant(e.target.value);
                    }}
                    onKeyDown={(e) => {
                      if (e.key == "Enter") {
                        document.getElementById("contractAmount").focus();
                      }
                    }}
                    placeholder="አማካሪ (አማራጭ)"
                  />
                  <br />
                  <p className="reg-err" id="contractAmountErr">
                    *ይህ መረጃ አስፈላጊ ነው!
                  </p>
                  <input
                    className="txt-input"
                    id="contractAmount"
                    type="number"
                    onChange={(e) => {
                      parseFloat(e.target.value)
                        ? setContractAmount(parseFloat(e.target.value))
                        : setContractAmount(0);
                    }}
                    onKeyDown={(e) => {
                      if (e.key == "Enter") {
                        document.getElementById("contractDuration").focus();
                      }
                    }}
                    placeholder="የውል መጠን (ብር)"
                    required
                  />
                  <br />
                  <input
                    className="txt-input"
                    id="contractDuration"
                    type="number"
                    onChange={(e) => {
                      setContractDuration(e.target.value);
                    }}
                    onKeyDown={(e) => {
                      if (e.key == "Enter") {
                        document.getElementById("signDate").focus();
                      }
                    }}
                    placeholder="የፕሮጀክት ጊዜ"
                    required
                  />
                  <br />
                  <label>
                    ውል የተያዘበት ቀን
                    <br />
                    <input
                      className="txt-input"
                      style={{ width: "200px" }}
                      id="signDate"
                      type="date"
                      onChange={(e) => {
                        setSignDate(e.target.value);
                      }}
                      onKeyDown={(e) => {
                        if (e.key == "Enter") {
                          document.getElementById("handoverDate").focus();
                        }
                      }}
                    />
                  </label>
                  <br />
                  <label>
                    የግንባታ ቦታ ርክክብ ቀን
                    <br />
                    <input
                      className="txt-input"
                      style={{ width: "200px" }}
                      id="handoverDate"
                      type="date"
                      onChange={(e) => {
                        setHandoverDate(e.target.value);
                      }}
                      onKeyDown={(e) => {
                        if (e.key == "Enter") {
                          document.getElementById("register").click();
                        }
                      }}
                    />
                  </label>
                  <br />
                  <label>
                    የሚገኝበት ደረጃ
                    <br />
                    <select
                      name="status"
                      onChange={(e) => setStatus(e.target.value)}
                      className="txt-input"
                      style={{ width: "231px" }}
                    >
                      <option value="Active">Active</option>
                      <option value="Completed">Completed</option>
                      <option value="Pending">Pending</option>
                      <option value="Terminated">Terminated</option>
                    </select>
                  </label>
                  <br />
                  {isLoading ? (
                    <div style={{ width: "100%", textAlign: "center" }}>
                      <img
                        src={loading1}
                        alt="login..."
                        style={{ width: "100px" }}
                      />
                    </div>
                  ) : (
                    <input
                      type="button"
                      id="register"
                      value="መዝግብ"
                      className="btn1"
                      style={{ margin: "20px 0", width: "150px" }}
                      onClick={RegiBtn}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default NewProject;
