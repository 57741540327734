import axios from "axios";
import { React, useState, useEffect } from "react";
import loading2 from "../img/loading2.gif";
import moment from "moment";
import { expSettleAction } from "./state/expenseSlice";
import meIcon2 from "../img/me-icon2.png";
import { useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";

function MachineryEquipment() {
  const navigate = useNavigate();

  useEffect(() => {
    document.getElementById("windowTitle").textContent = "/ኮንትራት አስተዳድር";
  }, []);

  return (
    <>
      <div
        className="cont-all"
        style={{
          width: "40%",
          minWidth: "300px",
          height: "97%",
          paddingTop: "0",
          overflow: "auto",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "45px",
            backgroundColor: "gray",
            margin: "0",
            display: "inline-flex",
            position: "sticky",
            top: 0,
          }}
        >
          <img
            src={meIcon2}
            style={{ width: "30px", height: "30px", margin: "10px" }}
            alt="hr-icon"
          />
          <h2
            style={{
              margin: "0",
              color: "white",
              padding: "10px",
            }}
          >
            የማሽነሪና ተሸከርካሪ መምሪያ
          </h2>
        </div>
        <div className="sub-menu">
          <h5 className="sub-menu-h5">ግብአት</h5>
          <div
            className="sub-menu-p"
            onClick={() => {
              navigate("/resource_request");
            }}
          >
            <FontAwesomeIcon
              style={{ margin: "16px 5px 5px 25px", fontSize: "14px" }}
              icon={faAngleRight}
            />
            <p
              style={{
                padding: "5px",
              }}
            >
              ግብአት ለመጠየቅ
            </p>
          </div>
        </div>
        <div
            className="sub-menu-p"
            onClick={() => {
              navigate("/requested_resource_list");
            }}
          >
            <FontAwesomeIcon
              style={{ margin: "16px 5px 5px 25px", fontSize: "14px" }}
              icon={faAngleRight}
            />
            <p
              style={{
                padding: "5px",
              }}
            >
              የተጠየቁ ግብአቶች ዝርዝር
            </p>
          </div>
      </div>
    </>
  );
}

export default MachineryEquipment;
